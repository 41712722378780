/* eslint-disable no-case-declarations */

import {
  UPDATE_PERSONAL_INFO,
  UPDATE_ACADEMIC_INFO,
  CLEAR_PROSPECTIVE_TUTOR,
  PRIMARY_INFO_PAGE,
} from '../types';

const INITIAL_STATE = {
  existingStudentFlow: false,

  // Fields that are related to the
  // tutor's personal info
  firstName: '',
  lastName: '',
  dob: '',
  email: '',
  phoneNumber: '',
  password: '',

  // Tutoring core subject preferences
  subjects: [],

  // Tutor's education level, aka if they
  // are in high school or college
  isHighSchoolTutor: true,

  // Class level preferences
  courses: [],

  // Zipcode { id: '90095', city: 'Los Angeles', state: 'CA' }
  zipcode: { id: '', city: '', state: '' },

  // Tutor's educational information { name: 'University of California, Los Angeles', id: 'AGnvuhLKeemBldzKp' }
  school: { name: '', id: '', city: '', state: '' },
  tutorGradeLevel: '',

  gpa: '',

  // The high school courses and subjects the tutor has taken
  highSchoolCourses: [],

  // The college courses and subjects the tutor has taken
  collegeCourses: [],

  // Tutoring preferences
  gradeLevels: [],
  onlinePreference: true,
  paidPreference: true,
  prefPaymentMethod: 'venmo',
  nonProfitName: '',

  // Availability of the tutor
  dailyAvaliability: [],
  avaliabilityDesc: '',

  // The bio of the tutor
  aboutYourself: '',

  // The state of the form flow
  activeField: PRIMARY_INFO_PAGE,

  // Form field progress indicator
  percentComplete: 0,

  // Profile ID of tutor who is referring
  tutorReferrerProfileId: '',
};

export default (state = INITIAL_STATE, action) => {
  const field = action.payload ? action.payload.field : null;
  switch (action.type) {
    case UPDATE_PERSONAL_INFO:
      const { value } = action.payload;
      return {
        ...state,
        [field]: value,
      };

    case UPDATE_ACADEMIC_INFO:
      // When handling array modification. Must be careful to not mutate current state
      const newAcademicInfo = state[field].slice();
      const { item, checked } = action.payload;
      if (checked) {
        newAcademicInfo.push(item);
      } else {
        const i = newAcademicInfo.indexOf(item);
        if (i !== -1) {
          newAcademicInfo.splice(i, 1);
        }
      }
      return {
        ...state,
        [field]: newAcademicInfo,
      };

    case CLEAR_PROSPECTIVE_TUTOR:
      return INITIAL_STATE;

    default:
      return state;
  }
};
