import {
  RESET_SEARCH,
  SET_COURSE,
  SET_TUTORS,
  UPDATE_SEARCH_PAGE_STATE,
  SET_LOCATION_FILTER,
  SET_STUDENT_GRADE_FILTER,
  SET_LOCATION_DESCRIPTION,
  SET_IS_LOCAL_SEARCH,
  SEARCH_LOADING,
  SEARCH_NEXT_PAGE_LOADING,
  SET_TUTOR_BY_COURSE_ERROR,
} from '../types';

const INITIAL_STATE = {
  courseSelected: '',
  zip: '',
  locationDescription: '',
  studentGradeLevel: '',
  localTutors: [],
  onlineTutors: [],
  localPageNumber: 1,
  onlinePageNumber: 1,
  exhaustedLocalTutors: false,
  exhaustedOnlineTutors: false,
  isLocalSearch: false,
  isLoading: false,
  nextPageLoading: false,
  fetchError: false,
};

/* eslint-disable no-case-declarations */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_SEARCH:
      return {
        ...state,
        localTutors: [],
        onlineTutors: [],
        localPageNumber: 1,
        onlinePageNumber: 1,
        exhaustedLocalTutors: false,
        exhaustedOnlineTutors: false,
      };

    case UPDATE_SEARCH_PAGE_STATE:
      return {
        ...state,
        localPageNumber: action.payload.localPageNumber,
        onlinePageNumber: action.payload.onlinePageNumber,
        exhaustedLocalTutors: action.payload.exhaustedLocalTutors,
        exhaustedOnlineTutors: action.payload.exhaustedOnlineTutors,
      };

    case SET_TUTORS:
      return {
        ...state,
        localTutors: action.payload.localTutors,
        onlineTutors: action.payload.onlineTutors,
      };

    case SET_COURSE:
      return {
        ...state,
        courseSelected: action.payload.courseSelected,
      };

    case SET_LOCATION_FILTER:
      return {
        ...state,
        zip: action.payload.zip,
      };

    case SET_LOCATION_DESCRIPTION:
      return {
        ...state,
        locationDescription: action.payload.locationDescription,
      };

    case SET_STUDENT_GRADE_FILTER:
      return {
        ...state,
        studentGradeLevel: action.payload.studentGradeLevel,
      };

    case SET_IS_LOCAL_SEARCH:
      return {
        ...state,
        isLocalSearch: action.payload.isLocalSearch,
      };

    case SEARCH_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case SEARCH_NEXT_PAGE_LOADING:
      return {
        ...state,
        nextPageLoading: action.payload.nextPageLoading,
      };

    case SET_TUTOR_BY_COURSE_ERROR:
      return {
        ...state,
        fetchError: action.payload.error,
      };

    default:
      return state;
  }
};
