import { ApolloClient } from 'apollo-client';
import { ApolloLink, from } from 'apollo-link';
import { onError } from 'apollo-link-error';

import { RetryLink } from 'apollo-link-retry';
import apolloLogger from 'apollo-link-logger';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from 'apollo-upload-client';
import createCache from './createCache';

const cache = createCache();

export default function createApolloClient(uri, subscriptionsURI, apiToken) {
  const logout = async () => {
    await fetch('/logout', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });
    window.location.href = '/';
  };

  const httpLink = createUploadLink({
    uri,
    credentials: 'same-origin',
  });

  const wsLink = new WebSocketLink({
    uri: subscriptionsURI,
    options: {
      reconnect: false,
      connectionParams: async () => ({
        authorization: apiToken ? `Bearer: ${apiToken}` : null,
      }),
      inactivityTimeout: 1000,
      timeout: 5000,
    },
  });

  const link = new RetryLink({
    delay: {
      initial: 700,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 1,
      retryIf: error => !!error,
    },
  }).split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  // link that catches errors
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ extensions, message, locations, path }) => {
        if (extensions && extensions.code === 'UNAUTHENTICATED') {
          logout();
        }

        return console.warn(
          // eslint-disable-line no-console
          `%c [GraphQL error]: Message ${message}, Location: ${locations}, Path: ${path}`,
          'color: red',
        );
      });
    }

    if (networkError) {
      console.warn(
        // eslint-disable-line no-console
        `%c [NetworkError]: ${networkError}`,
        'color: red',
      );
    }
  });

  const authLink = new ApolloLink((operation, forward) => {
    const authorization = apiToken ? `Bearer: ${apiToken}` : null;
    operation.setContext({
      headers: {
        authorization,
      },
    });
    return forward(operation);
  });

  return new ApolloClient({
    // http link MUST BE THE LAST ONE
    link: from([
      authLink,
      errorLink,
      ...(__DEV__ ? [apolloLogger] : []),
      link,
      // batchLink,
    ]),
    cache: cache.restore(window.App.apolloState),
    queryDeduplication: true,
    connectToDevTools: true,
  });
}
