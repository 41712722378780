import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'email-address': 'Email address',
      'enter-your-password': 'Enter your password',
      'forgot-your-password': 'Forgot your password?',
      'reset-your-password': 'Reset your password',
      'password-and-security': 'Password & Security',
      'account-settings': 'Account Settings',
      'dont-have-account': `Don't have an account yet?`,
      'request-tutoring': 'Request {{tutorTerm}}ing as a new student!',
      'apply-tutor': 'Apply to become a {{tutorTerm}}!',
      'become-a-tutor': 'Become a tutor',
      'create-account': 'Create an account',
      'get-a-tutor': 'Get a tutor',
      'get-started': 'Get Started',
      'need-peer-tutor': 'Need a Peer Tutor?',
      'log-in-header': 'Log in as a returning {{tuteeTerm}} or {{tutorTerm}}!',
      'log-in': 'Log In',
      'sign-up': 'Sign Up',

      // Not logged in V2 page
      'login-input-title': 'Enter Login Information',
      'email-input-text': 'Email',
      'email-input-placeholder': 'Enter your Email',
      'password-input-text': 'Password',
      'password-input-placeholder': 'Enter your Password',
      'login-additional-options-text': 'Or',
      'login-need-help-text': 'Need Help?',
      'login-contact-help-text': 'Contact',
      'continue-with-login': 'Continue with {{sso_option}}',

      'powered-by-tutorfly': 'Powered by Tutorfly',
      'go-to-dashboard': 'Go to dashboard',
      'go-to-admin-panel': 'Go to Admin Panel',
      'pusd-create-drop-in-title':
        'Create an account to access drop-in tutoring sessions!',
      'pusd-welcome-blurb':
        'Welcome to Pomona Public Library Foundation’s Homework Club’s free drop-in virtual tutoring. The Library Foundation’s Homework Club vets tutors and ensures that they are qualified to help your student with their classwork.',
      'pusd-before-signing-sentence':
        'Before signing up for an account here, please download and sign the Homework Club waiver.',
      'pusd-click-waiver-link': 'Click here for the Homework Club Waiver.',
      'pusd-completed-waiver-sentence':
        'Once you have downloaded and signed the waiver, please email the completed waiver to pomonalibraryhomeworkclub@gmail.com and pre-register below.',
      'invite-create-password':
        'Once you create a password below to activate your account, you can start logging in using your email {{email}}.',
      'please-select-password': 'Please set a password for your account.',
      'please-confirm-password': 'Please retype your password to confirm.',
      password: 'Password',
      'invite-link-invalid-title': 'This Invite Link Is Invalid',
      'invite-link-invalid-page-subtitle1':
        'It may have already been used, or the link may have been entered incorrectly.',
      'invite-link-invalid-page-subtitle2':
        'Please reach out to schools@tutorfly.org if you have questions or concerns.',
      save: 'Save',

      // Account Settings
      confirm_password: 'Confirm Password',
      new_password: 'New Password',
      current_password: 'Current Password',
      update_your_password: 'Update your account password.',
      send_email_address: 'Send email updates',
      send_text_updates: 'Send text message updates',
      set_notification_preference:
        'Set your preference for how you would like to be notified of {{tutorTerm}}ing requests, new messages, and other useful updates.',
      notification_preferences: 'Notification Preferences',
      phone_number: 'Phone number',
      email_address: 'Email address',
      contact_information: 'Contact Information',

      // Contact Footer
      who_should_i_contact: 'Who should I contact with questions?',
      tech_questions: 'Technical questions or help',

      // Footer
      footer_message_main:
        'Tutorfly.org has been empowering student success since 2017! We are proud to support schools and nonprofit organizations with our Tutorfly-for-Schools offering.',
      my_dashboard: 'My Dashboard',
      about_us: 'About Us',
      team: 'Team',
      privacy_policy: 'Privacy Policy',
      terms_of_use: 'Terms of Use',
      for_institutions: 'For Institutions',
      icons_from_icons8: 'Icons from Icons8',
      contact_us: 'Contact Us',
      facebook_page: 'Facebook Page',
      check_out_tutoring_website:
        'Check out Tutorfly, an innovative peer-to-peer tutoring website!',
      share_tutorfly_freinds: 'Share Tutorfly with your friends!',
      navigate: 'Navigate',
      contact: 'Contact',
      share: 'Share',

      // Header
      sessions: 'Sessions',
      inbox: 'Inbox',
      me: 'Me',
      on: 'on',
      edit: 'Edit',
      profile: 'Profile',
      mode: 'Mode',
      analytics: 'Analytics',
      admin_panel: 'Admin Panel',
      requests: 'Requests',
      request_a_tutor: 'Request a {{tutorTerm}}',
      find_a_tutor: 'Find a {{tutorTerm}}',
      'find-a-tutor': 'Find a {{tutor}}',
      switch_to_mode: 'Switch to {{term}} mode',
      logout: 'Logout',
      how_it_works: 'How It Works',
      log_in: 'Log In',

      // Sessions
      error_fetching_upcoming_sessions: 'Error Fetching Upcoming Sessions',
      join_session: 'Join Session',
      upcoming: 'Upcoming',
      completed: 'Completed',
      sessions_dashboard_zero_state_message:
        'You do not have any upcoming sessions.',
      how_to_schedule_sessions: 'How to Schedule Sessions!',
      message_your_tutor_suggest_time:
        'Message your {{term}} to suggest a time and date for your session.',
      no_upcoming_sessions: 'No upcoming sessions',
      recently_completed_sessions: 'Recently Completed Sessions',
      if_session_completed:
        'If you complete a session with a {{term}}, it will appear here.',

      // Upcoming Session List
      try_again: 'Try Again',
      you_dont_have_connections_yet:
        "You don't have any {{oppUserTitle}} connections yet",
      book_session: 'Book Session',
      view_request: 'View Requests',
      upcoming_sessions: 'Upcoming Sessions',
      completed_sessions: 'Completed Sessions',
      upcoming_sessions_by_person: 'Upcoming Sessions by Person',
      book_session_with_other:
        'Book a session with a {{otherTerm}} you are connected with.',
      tutor_not_connected:
        'You are not connected with a {{tutorTerm}} yet. Click below to get connected with a {{tutorTerm}}.',

      // Sessions sidebar
      sessions_sidebar_title: 'Sessions',

      // Completed Sessions dashboard
      completed_sessions_title: 'Completed Sessions',
      completed_sessions_subtitle: 'View your past sessions.',
      completed_sessions_zero_state: 'You do not have any past sessions.',
      completed_sessions_subject_header: 'Subject',
      completed_sessions_participants_header: 'Participants',
      completed_sessions_date_header: 'Date',
      completed_sessions_see_more_button: 'See More',
      completed_sessions_payment_header: 'Payment',

      // Leave session feedback on sessions dashboard
      feedback_sessions_title: 'Session Feedback',
      feedback_sessions_zero_state:
        'There are no feedback forms for you to fill out.',
      feedback_sessions_subject_header: 'Subject',
      feedback_sessions_lesson_room: 'Lesson Room',
      feedback_sessions_date_header: 'Date',
      feedback_sessions_leave_feedback_button: 'Leave Feedback',

      // Fill assessments section on feedback page
      feedback_assessments_title: 'Check-In Surveys',
      feedback_assessments_name_header: 'Name',
      feedback_assessments_date_header: 'Date',
      feedback_assessments_fill_button: 'Start Survey',

      // Completed Sessions List
      details: 'Details',
      view_monthly_completed_sessions: 'View {{monthName}} Completed Sessions',
      there_was_an_issue:
        'There was an issue fetching your completed sessions!',
      no_completed_sessions_in_month: 'No completed sessions in {{monthName}}',
      leave_feedback: 'Leave Feedback',
      hours: 'Hours',
      date: 'Date',
      subject: 'Subject',
      duration: 'Duration',

      // Post Session Feedback Form
      how_was_your_session: 'How was your session?',
      submit: 'Submit',

      click_to_join_session: 'Click to Join Session',
      online_lesson: 'Online Lesson',
      series_recurs_weeks: 'Series recurs for {{weeksRecurring}} weeks',

      back: 'Back',
      confirm_cancellation: 'Confirm Cancellation',
      confirm_deletion: 'Confirm Deletion',
      why_cancel_session:
        '(Optional) Please share why you would like to cancel this session.',
      why_delete_session:
        '(Optional) Please share why you would like to delete this session.',
      why_cancel_sessions:
        '(Optional) Please share why you would like to cancel these sessions.',
      all_sessions: 'All sessions',
      only_this_session: 'Only this session',
      cancel_this_or_all:
        'Would you like to cancel this session or all sessions?',
      cancel_this_session: "Are you sure you'd like to cancel this session?",
      delete_this_session: "Are you sure you'd like to delete this session?",

      // Edit
      edit_session: 'Edit Session',
      edit_single: 'Edit Single',
      edit_multiple: 'Edit Multiple',
      what_subject_will_be_covered:
        'What subject or class will be covered this session?',
      what_subject_will_be_covered_multi:
        'Which subject or class will be covered during these sessions?',
      when_take_place_single: 'When will this session take place?',
      when_take_place_multiple:
        'Please specify the start and end times of the tutoring sessions.',
      date_of_session: 'Date of Session',
      start_time: 'Start Time',
      end_time: 'End Time',
      no_time_zone_set: 'No time zone set',
      how_session_hosted: 'How will this session be hosted?',
      how_session_hosted_multiple:
        'Will these tutoring sessions be online or in-person?',
      in_person: 'In-person',
      online: 'Online',
      where_session_take_place: 'Where will this session take place?',
      address_or_location: 'Address or Location',
      use_tutorfly_lessonspace:
        'Would you rather use Tutorfly’s Lessonspace or your own custom video link?',
      select: 'Select',
      lessonspace_will_be_shared:
        'A Lessonspace link will be shared with your tutoring client as an orange button on their upcoming sessions dashboard.',
      zoom_will_be_shared:
        'Your Zoom or Google Meet link will be shared with your tutoring client in the form of a blue button on their upcoming sessions dashboard.',
      enter_zoom_below:
        'Please enter your personal Zoom or Google Meet join link below.',
      reason_for_editing_single:
        'For what reason are you editing this session?',
      reason_for_editing_multi:
        'For what reason are you editing these sessions?',
      reason_for_edit_label: 'Reason for Editing',
      submit_edits: 'Submit Edits',
      we_noticed_you_cancelling:
        'We noticed that you are cancelling this tutoring session within 24 hours of its scheduled start time.',
      please_share_cancellation_multi:
        'Please share why you would like to cancel these sessions and whether you would like to cancel a single session or all recurring sessions in this series.',
      please_share_cancellation_single:
        "Please share why you would like to cancel this session and then click 'Confirm Cancellation'.",
      reason_for_cancelling: 'Reason for Cancelling',
      cancel: 'Cancel',

      // Sessions Sidebar
      message_your_other_to_suggest:
        'Message your {{otherTerm}} to suggest a time and date for your session.',
      how_schedule_sessions: 'How to Schedule Sessions!',
      how_join_drop_in: 'How to Join Drop-In Sessions!',
      find_topic_help_with:
        'Find the subject and grade level you need help with.',
      find_grade_level_teaching:
        'Find the grade level and subject you are teaching.',
      click_join_session_drop_in:
        'Click the "Join Session" button at the time the drop-in session is available.',
      with: 'with',
      time_to: 'to',

      why_drop_this_or_all_sessions:
        'Would you like to drop this session or all sessions?',
      sure_drop_this_session: "Are you sure you'd like to drop this session?",
      drop_single_or_multiple:
        'Please specify whether you’d like to drop this singular tutoring session or multiple sessions.',
      confirm_drop_single:
        'Please confirm that you’d like to drop this tutoring session by clicking the confirmation button below.',
      // only_this_session: 'Only this session',
      all_session: 'All sessions',
      confirm_drop: 'Confirm Drop',
      drop: 'Drop',

      specify_cancel_this_or_multi:
        'Please specify whether you’d like to cancel this singular tutoring session or multiple sessions.',
      specify_cancel_this_session:
        'Please confirm that you’d like to cancel this tutoring session by clicking the confirmation button below.',

      upcoming_sessions_with: 'Upcoming Sessions with',
      view_or_edit_upcoming: 'View or edit your upcoming sessions with',
      sessions_with: 'Sessions with',
      show: 'Show',
      hide: 'Hide',

      communicate_with_other_lessons:
        'Communicate with your {{otherTerm}} here to help prepare for lessons.',
      send_messages_to_other:
        'Send messages and share files with your {{otherTerm}} to help prepare for lessons.',
      active: 'Active',
      archive: 'Archived',
      // inbox: 'Inbox',
      conversations_once_archived:
        'Conversations will appear here once you have archived them.',
      conversations_appear_here_other_request:
        'Conversations will appear here once a {{tuteeTerm}} has sent you a {{tutorTerm}}ing request.',
      conversation_appear_here_once_you_send:
        'Conversations will appear here once you have sent a {{tutorTerm}}ing request to a {{tutorTerm}}.',
      load_more_conversations: 'See more',
      no_messages: 'No Messages',
      to_archive: 'Archive',
      to_un_archive: 'Unarchive',

      drop_in_sessions: 'Drop-In Sessions',
      error_fetching_drop_in_sessions:
        'Error Fetching Upcoming Drop-In Sessions',
      no_upcoming_drop_in_sessions: 'No upcoming drop-in sessions',
      tutors: 'Tutors',
      drop_session: 'Drop Session',

      set_time_zone: 'Set a time zone',
      enter_breakout_room_details: 'Enter breakout room details.',
      create_breakout_room:
        'Create a breakout room for a {{tutorTerm}} and a {{tuteeTerm}} in {{name}}.',
      create_breakout_rooms: 'Create Breakout Rooms',
      what_grade_level: 'What grade level will these sessions be for?',
      single: 'Single',
      recurring: 'Recurring',
      enter_session_details: 'Enter session details.',
      select_tutors: 'Select tutors.',
      create_drop_in_open_to_all:
        'Create a drop-in session open to all {{tuteeTerm}}s in {{name}}.',
      schedule_drop_in_sessions: 'Schedule Drop-In Sessions',
      what_days_of_week_sessions_occur:
        'What days of the week would you like sessions to occur?',
      how_many_weeks_sessions_recur:
        'For how many weeks would you like sessions to recur?',
      what_day_sessions_begin:
        'On what day would you like this series of sessions to begin?',
      date_of_first_session: 'Date of First Session',
      what_time_sessions_series_take_place:
        'What time would you like sessions in this series to take place?',
      schedule_sessions: 'Schedule Sessions',
      create_session_for_other_user:
        'Create a session for a {{tutorTerm}} and a {{tuteeTerm}} that are paired in {{name}}.',
      who_will_participate: 'Who will participate in this session?',
      hold_online_lesson_lp_or_zoom:
        "Would you like to hold the online lesson with Tutorfly's Lessonspace or your custom video link?",
      hold_online_lesson_lp_or_zoom_multi:
        "Would you like to hold these online lessons with Tutorfly's Lessonspace or your custom video link?",
      button_will_open_lp:
        'This session’s "Join Session" button will open Tutorfly’s Online Lessonspace.',
      button_will_open_zoom:
        'This session’s "Join Session" button will open your custom Zoom or Google Meet room.',
      enter_zoom_link_below:
        'Please enter the Zoom or Google Meet join link below.',
      what_subjects_covered_multi:
        'What subject or class will be covered during this series?',
      how_session_hosted_multi: 'How will these sessions be hosted?',
      where_will_session_take_place_multi:
        'Where will the sessions take place?',
      button_will_open_lp_multi:
        'These sessions’ "Join Session" button will open Tutorfly’s Online Lessonspace.',
      button_will_open_zoom_multi:
        'These sessions’ "Join Session" button will open the Zoom or Google Meet Meeting room.',
      what_days_of_week_session_occur:
        'What days of the week would you like sessions to occur?',
      how_many_weeks_session_recur:
        'For how many weeks would you like sessions to recur?',
      when_sessions_take_place: 'When will these sessions take place?',
      book_a_session: 'Book a Session',
      select_your_tutor: 'Select your {{tutorTerm}}.',
      select_your_tutee: 'Select your {{tuteeTerm}}.',
      select_your_tutees: 'Select your {{tuteeTerm}}(s).',
      you_are_not_yet_connected:
        'You are not yet connected with a {{opposingUserType}} in {{name}}.',
      select_one_day_sessions_recur:
        'Please select at least one day of the week for sessions to recur.',

      home: 'Home',
      breakout_rooms: 'Breakout Rooms',

      what_issue_did_you_experience: 'What issue did you experience?',
      share_brief_description_of_issue:
        'Share a brief description of the issue you experienced using the Online Lesson Space so that we can address this issue for future sessions.',

      edit_profile: 'Edit Profile',
      profile_picture: 'Profile Picture',
      personal_info: 'Personal Info',
      first_name: 'First name',
      last_name: 'Last name',
      birthday: 'Birthday',
      dob: 'Date of Birth',
      location_and_time_zone: 'Location and Time zone',
      close: 'Close',
      about: 'About',
      add_quick_bio_about_yourself:
        'Add a quick bio about yourself to let a {{tutorTerm}} know what you are looking for. What grade do you currently attend? What subjects do you need help with?',
      let_potential_know_about_you:
        'Let potential {{tuteeTerm}}s know about why you are a great {{tutorTerm}} for subjects you can teach. Feel free to mention any past {{tutorTerm}}ing or teaching experiences here.',
      i_am: 'I am...',
      availability: 'Availability',
      what_days_are_you_available:
        'What days of the week are you available for {{tutorTerm}}ing sessions?',
      description_of_availability: 'Description of availability',
      available_after_3: 'I am generally available weekdays after 3 pm...',

      series_repeats: 'Series repeats every',
      series_recurs_for: 'Series recurs for {{numWeeks}} weeks',
      repeats: 'Repeats',

      calendar_view: 'Calendar View',
      session_feedback: 'Feedback',
      scheduled_sessions: 'One on One and Group Sessions',
      no_drop_in_sessions: 'No drop-in sessions',
    },
  },
  sp: {
    translation: {
      'email-address': 'Email',
      'enter-your-password': 'Ingresa su contraseña',
      'forgot-your-password': '¿Olvidaste su contraseña?',
      'password-and-security': 'Contraseña y seguridad',
      'account-settings': 'Configuraciones de la Cuenta',
      'reset-your-password': '¿Restablecer su contraseña?',
      'dont-have-account': `¿Aún no tienes una cuenta?`,
      'request-tutoring': '¡Solicite tutoría como estudiante nuevo!',
      'apply-tutor': '¡Aplicar para convertirse en tutor!',
      'become-a-tutor': 'Conviértete en tutor',
      'create-account': 'Crear una cuenta',
      'get-a-tutor': 'Conseguir un tutor',
      'get-started': 'Empezar',
      'need-peer-tutor': '¿Necesitas un tutor?',
      'log-in-header': 'Inicie sesión como cliente o tutor que regresa!',
      'log-in': 'Iniciar sesión',
      'sign-up': 'Regístrate',

      'login-input-title': 'Ingrese su Login Información',
      'email-input-text': 'Email',
      'email-input-placeholder': 'Ingresa su Email',
      'password-input-text': 'Contraseña',
      'password-input-placeholder': 'Ingresa su contraseña',
      'login-additional-options-text': 'O',
      'login-need-help-text': '¿Necesitas Ayuda?',
      'login-contact-help-text': 'Contactar',
      'continue-with-login': 'Continua con {{sso_option}}',

      'powered-by-tutorfly': 'Creado con Tutorfly',
      'go-to-dashboard': 'Ir a sesiones',
      'go-to-admin-panel': 'Panel de Administración',
      'pusd-create-drop-in-title':
        '¡Cree una cuenta para acceder a las sesiones de tutoría sin cita previa!',
      'pusd-welcome-blurb':
        'Bienvenido a la tutoría virtual gratuita del Club de Tareas de la Fundación de la Biblioteca Pública de Pomona. El Club de Tareas de The Library Foundation examina a los tutores y se asegura de que estén calificados para ayudar a su estudiante con su trabajo en clase.',
      'pusd-before-signing-sentence':
        'Antes de registrarse para obtener una cuenta aquí, descargue y firme la exención del Homework Club.',
      'pusd-click-waiver-link':
        'Haga clic aquí para obtener la exención del club de tareas.',
      'pusd-completed-waiver-sentence':
        'Una vez que haya descargado y firmado la exención, envíe un correo electrónico con la exención completa a pomonalibraryhomeworkclub@gmail.com y preinscríbase a continuación.',
      'invite-create-password':
        'Una vez que cree una contraseña a continuación para activar su cuenta, puede comenzar a iniciar sesión con su correo electrónico {{email}}.',
      'please-select-password': 'Establezca una contraseña para su cuenta.',
      'please-confirm-password':
        'Vuelva a escribir su contraseña para confirmar.',
      password: 'Contraseña',
      'invite-link-invalid-title': 'Este Enlace de Invitación no es Válido',
      'invite-link-invalid-page-subtitle1':
        'Es posible que ya se haya utilizado o que el enlace se haya ingresado incorrectamente.',
      'invite-link-invalid-page-subtitle2':
        'Comuníquese con schools@tutorfly.org si tiene preguntas o inquietudes.',
      save: 'Guardar',

      // Account Setting
      confirm_password: 'Confirmar contraseña',
      new_password: 'Nueva contraseña',
      current_password: 'Contraseña actual',
      update_your_password: 'Actualiza la contraseña de tu cuenta.',
      send_email_address: 'Enviar actualizaciones por correo electrónico',
      send_text_updates: 'Enviar actualizaciones de mensajes de texto',
      set_notification_preference:
        'Establezca su preferencia sobre cómo le gustaría ser notificado de {{tutorTerm}} solicitudes, mensajes nuevos y otras actualizaciones útiles.',
      notification_preferences: 'Preferencias de notificación',
      phone_number: 'Número de teléfono',
      email_address: 'Dirección de correo electrónico',
      contact_information: 'Información de contacto',

      // Contact Footer
      who_should_i_contact: '¿A quién debo contactar si tengo preguntas?',
      tech_questions: 'Preguntas técnicas o ayuda',

      // Footer
      footer_message_main:
        '¡Tutorfly.org ha estado potenciando el éxito de los estudiantes desde 2017! Estamos orgullosos de apoyar a las escuelas y organizaciones sin fines de lucro con nuestra oferta Tutorfly-for-Schools.',
      my_dashboard: 'Mi panel',
      about_us: 'Acerca de nosotros',
      team: 'Equipo',
      privacy_policy: 'Política de privacidad',
      terms_of_use: 'Condiciones de uso',
      for_institutions: 'Para instituciones',
      icons_from_icons8: 'Iconos de Icons8',
      contact_us: 'Contáctenos',
      facebook_page: 'Página de Facebook',
      check_out_tutoring_website:
        '¡Echa un vistazo a Tutorfly, un innovador sitio web de tutoría entre pares!',
      share_tutorfly_freinds: '¡Comparte Tutorfly con tus amigos!',
      navigate: 'Navegar',
      contact: 'Contacto',
      share: 'Comparte',

      // Header
      sessions: 'Sesiones',
      inbox: 'Mensajes',
      me: 'Yo',
      on: 'en',
      edit: 'Editar',
      profile: 'Perfil',
      mode: 'Modo',
      analytics: 'Analítica',
      admin_panel: 'Panel de Admin',
      requests: 'Peticiones',
      request_a_tutor: 'Solicite un {{tutorTerm}}',
      find_a_tutor: 'Encuentra un {{tutorTerm}}',
      'find-a-tutor': 'Encuentra un {{tutor}}',
      switch_to_mode: 'Cambiar al modo {{term}}',
      logout: 'Cerrar sesión',
      how_it_works: 'Cómo funciona',
      log_in: 'Iniciar sesión',

      // Sessions
      upcoming: 'Próximo',
      error_fetching_upcoming_sessions:
        'Error al recuperar las próximas sesiones',
      join_session: 'Unirse',
      completed: 'Completado',
      how_to_schedule_sessions: '¡Cómo programar sesiones!',
      message_your_tutor_suggest_time:
        'Envía un mensaje a tu {{term}} para sugerir una fecha y hora para tu sesión.',
      no_upcoming_sessions: 'No hay próximas sesiones',
      recently_completed_sessions: 'Sesiones completadas recientemente',
      if_session_completed:
        'Si completa una sesión con un {{term}}, aparecerá aquí.',

      // Upcoming Session List
      try_again: 'Inténtalo de nuevo',
      you_dont_have_connections_yet:
        'Aún no tienes ninguna conexión de {{oppUserTitle}}',
      book_session: 'Programar Sesión',
      view_request: 'Ver solicitudes',
      upcoming_sessions: 'Próximas Sesiones',
      completed_sessions: 'Sesiones Completadas',
      upcoming_sessions_by_person: 'Próximas sesiones por persona',
      book_session_with_other:
        'Reserve una sesión con un {{otherTerm}} con el que esté conectado.',
      tutor_not_connected:
        'No estás conectado con un {{tutorTerm}} todavía. Haga clic a continuación para conectarse con un {{tutorTerm}}.',

      // Sessions
      sessions_sidebar_title: 'Sesiones',
      sessions_dashboard_zero_state_message: 'No tienes sessiones proximas.',

      // Completed Sessions dashboard
      completed_sessions_title: 'Sesiones Completadas',
      completed_sessions_subtitle: 'Vea sus sesiones pasadas.',
      completed_sessions_zero_state: 'No tienes sesiones pasadas.',
      completed_sessions_subject_header: 'Sujeto',
      completed_sessions_participants_header: 'Participantes',
      completed_sessions_date_header: 'Fecha',
      completed_sessions_see_more_button: 'Ver Más',
      completed_sessions_payment_header: 'Pago',

      // Leave session feedback on sessions dashboard
      feedback_sessions_title: 'Comentarios de la sesión',
      feedback_sessions_zero_state:
        'No hay formularios de comentarios que deba completar.',
      feedback_sessions_subject_header: 'Sujeto',
      feedback_sessions_lesson_room: 'Cuarto de Sesión',
      feedback_sessions_date_header: 'Fecha',
      feedback_sessions_leave_feedback_button: 'Deja un Comentario',

      // Fill assessments section on feedback page
      feedback_assessments_title: 'Encuesta de Registro',
      feedback_assessments_name_header: 'Nombre',
      feedback_assessments_date_header: 'Fecha',
      feedback_assessments_fill_button: 'Empezar Encuesta',

      // Lista de sesiones completadas
      details: 'Detalles',
      view_monthly_completed_sessions: 'Ver {{monthName}} sesiones completadas',
      there_was_an_issue:
        '¡Hubo un problema al recuperar sus sesiones completadas!',
      no_completed_sessions_in_month:
        'No hay sesiones completadas en {{monthName}}',
      leave_feedback: 'Dejar comentarios',
      hours: 'Horas',
      date: 'Fecha',
      subject: 'Sujeto',
      duration: 'Duración',

      // Formulario de comentarios posterior a la sesión
      how_was_your_session: '¿Cómo estuvo tu sesión?',
      submit: 'Enviar',

      click_to_join_session: 'Unirse a la sesión',
      online_lesson: 'Lección online',
      series_recurs_weeks:
        'La serie se repite durante {{weeksRecurring}} semanas',

      back: 'Atras',
      confirm_cancellation: 'Confirmar cancelación',
      confirm_deletion: 'Confirmar borradura',
      why_cancel_session:
        '(Opcional) Por favor comparta por qué le gustaría cancelar esta sesión.',
      why_delete_session:
        '(Opcional) Por favor comparta por qué le gustaría borrar esta sesión.',
      why_cancel_sessions:
        '(Opcional) Comparta por qué le gustaría cancelar estas sesiones.',
      all_sessions: 'Todas las sesiones',
      only_this_session: 'Solo esta sesión',
      cancel_this_or_all: '¿Desea cancelar esta sesión o todas las sesiones?',
      cancel_this_session: '¿Está seguro de que desea cancelar esta sesión?',
      delete_this_session: '¿Está seguro de que desea cancelar esta sesión?',

      // Editar
      edit_session: 'Editar sesión',
      edit_single: 'Editar single',
      edit_multiple: 'Editar varios',
      what_subject_will_be_covered:
        '¿Qué asignatura o clase se tratará en esta sesión?',
      what_subject_will_be_covered_multi:
        '¿Qué tema o clase se tratará durante estas sesiones?',
      when_take_place_single: '¿Cuándo tendrá lugar esta sesión?',
      when_take_place_multiple:
        'Por favor, especifique la hora de inicio y finalización de las sesiones de tutoría.',
      date_of_session: 'Fecha de la sesión',
      start_time: 'Hora de inicio',
      end_time: 'Hora de finalización',
      no_time_zone_set: 'Sin zona horaria establecida',
      how_session_hosted: '¿Cómo se alojará esta sesión?',
      how_session_hosted_multiple:
        '¿Estas sesiones de tutoría serán en línea o en persona?',
      in_person: 'En persona',
      online: 'Online',
      where_session_take_place: '¿Dónde se llevará a cabo esta sesión?',
      address_or_location: 'Dirección o ubicación',
      use_tutorfly_lessonspace:
        '¿Prefieres usar el espacio de lecciones de Tutorfly o tu propio enlace Zoom?',
      select: 'Seleccionar',
      lessonspace_will_be_shared:
        'El enlace de Lessonspace en línea se compartirá con su cliente de tutoría como un botón naranja en el panel de sus próximas sesiones.',
      zoom_will_be_shared:
        'Su enlace de Zoom se compartirá con su cliente de tutoría en forma de un botón azul en el panel de sus próximas sesiones.',
      enter_zoom_below:
        'Por favor, ingrese el enlace para unirse a su Zoom personal a continuación.',
      reason_for_editing_single: '¿Por qué estás editando esta sesión?',
      reason_for_editing_multi: '¿Por qué estás editando estas sesiones?',
      reason_for_edit_label: 'Razón de la edición',
      submit_edits: 'Enviar modificaciones',
      we_noticed_you_cancelling:
        'Notamos que está cancelando esta sesión de tutoría dentro de las 24 horas posteriores a la hora de inicio programada.',
      please_share_cancellation_multi:
        'Por favor, comparta por qué desea cancelar estas sesiones y si desea cancelar una sola sesión o todas las sesiones recurrentes de esta serie.',
      please_share_cancellation_single:
        "Por favor, comparta por qué le gustaría cancelar esta sesión y luego haga clic en 'Confirmar cancelación'.",
      reason_for_cancelling: 'Razón de la cancelación',
      cancel: 'Cancelar',

      // Barra lateral de sesiones
      message_your_other_to_suggest:
        'Envía un mensaje a tu {{otherTerm}} para sugerir una fecha y hora para tu sesión.',

      how_schedule_sessions: '¡Cómo programar sesiones!',
      how_join_drop_in: '¡Cómo unirse a sesiones directas!',
      find_topic_help_with:
        'Encuentra la materia y el nivel de grado con el que necesitas ayuda.',
      find_grade_level_teaching:
        'Encuentra el nivel de grado y la materia que estás enseñando.',
      click_join_session_drop_in:
        'Haga clic en el botón "Unirse a la sesión" en el momento en que la sesión directa esté disponible.',
      with: 'con',
      time_to: 'a',

      why_drop_this_or_all_sessions:
        '¿Le gustaría cancelar esta sesión o todas las sesiones?',
      sure_drop_this_session:
        '¿Estás seguro de que te gustaría abandonar esta sesión?',
      drop_single_or_multiple:
        'Por favor, especifique si desea abandonar esta sesión de tutoría en particular o varias sesiones',
      confirm_drop_single:
        'Por favor, confirme que desea cancelar esta sesión de tutoría haciendo clic en el botón de confirmación a continuación',
      // only_this_session: 'Solo esta sesión',
      all_session: 'Todas las sesiones',
      confirm_drop: 'Confirmar Drop',
      drop: 'Soltar',

      specify_cancel_this_or_multi:
        'Especifique si desea cancelar esta sesión de tutoría en particular o varias sesiones',
      specify_cancel_this_session:
        'Confirme que desea cancelar esta sesión de tutoría haciendo clic en el botón de confirmación a continuación.',

      next_sessions_with: 'Próximas sesiones con',
      view_or_edit_upcoming: 'Ver o editar sus próximas sesiones con',
      sessions_with: 'Sesiones con',
      show: 'Mostar',
      hide: 'Ocultar',

      communicate_with_other_lessons:
        'Comuníquese con su {{otherTerm}} aquí para ayudarlo a prepararse para las lecciones.',
      send_messages_to_other:
        'Envía mensajes y comparte archivos con tu {{otherTerm}} para ayudarte a prepararte para las lecciones.',
      active: 'Activo',
      archive: 'Archivado',
      // bandeja de entrada: 'Bandeja de entrada',
      conversations_once_archived:
        'Las conversaciones aparecerán aquí una vez que las haya archivado.',
      conversations_appear_here_other_request:
        'Las conversaciones aparecerán aquí una vez que un {{tuteeTerm}} le haya enviado una solicitud de {{tutorTerm}} ing.',
      conversation_appear_here_once_you_send:
        'Las conversaciones aparecerán aquí una vez que haya enviado una solicitud de {{tutorTerm}} ing a un {{tutorTerm}}.',
      load_more_conversations: 'Cargar más conversaciones',
      no_messages: 'No Mensajes',
      to_archive: 'Archivar',
      to_un_archive: 'Desarchivar',

      drop_in_sessions: 'Sesiónes sin cita previa',
      error_fetching_drop_in_sessions:
        'Error al obtener las próximas sesiones directas',
      no_upcoming_drop_in_sessions: 'No hay próximas sesiones directas',
      tutors: 'Tutores',
      drop_session: 'Descartar sesión',

      set_time_zone: 'Establecer una zona horaria',
      enter_breakout_room_details: 'Ingrese los detalles de la sala de grupo.',
      create_breakout_room:
        'Crea una sala de reuniones para un {{tutorTerm}} y un {{tuteeTerm}} en {{name}}.',
      create_breakout_rooms: 'Crear salas de grupo',
      what_grade_level: '¿Para qué nivel de grado serán estas sesiones?',
      single: 'Singular',
      recurring: 'Recurrente',
      enter_session_details: 'Ingrese los detalles de la sesión.',
      select_tutors: 'Seleccionar tutores.',
      create_drop_in_open_to_all:
        'Cree una sesión abierta para todos los {{tuteeTerm}} en {{name}}.',
      schedule_drop_in_sessions: 'Programar sesiones directas',
      what_days_of_week_sessions_occur:
        '¿Qué días de la semana te gustaría que se realizaran las sesiones?',
      how_many_weeks_sessions_recur:
        '¿Durante cuántas semanas te gustaría que se repitieran las sesiones?',
      what_day_sessions_begin:
        '¿En qué día te gustaría que comenzara esta serie de sesiones?',
      date_of_first_session: 'Fecha de la primera sesión',
      what_time_sessions_series_take_place:
        '¿A qué hora le gustaría que se llevaran a cabo las sesiones de esta serie?',
      schedule_sessions: 'Programar sesiones',
      create_session_for_other_user:
        'Crea una sesión para un {{tutorTerm}} y un {{tuteeTerm}} que están emparejados en {{name}}.',
      who_will_participate: '¿Quién participará en esta sesión?',
      hold_online_lesson_lp_or_zoom:
        '¿Le gustaría realizar la lección en línea con el espacio de lecciones de Tutorfly o Zoom?',
      hold_online_lesson_lp_or_zoom_multi:
        '¿Le gustaría tener estas lecciones en línea con el espacio de lecciones de Tutorfly o Zoom?',
      button_will_open_lp:
        'El botón "Unirse a la sesión" de esta sesión abrirá el espacio de lecciones en línea de Tutorfly.',
      button_will_open_zoom:
        'El botón "Unirse a la sesión" de esta sesión abrirá su sala de reuniones de Zoom.',
      enter_zoom_link_below:
        'Por favor, ingrese el vínculo de unión de Zoom a continuación.',
      what_subjects_covered_multi:
        '¿Qué asignatura o clase se tratará durante esta serie?',
      how_session_hosted_multi: '¿Cómo se alojarán estas sesiones?',
      where_will_session_take_place_multi: '¿Dónde tendrán lugar las sesiones?',
      button_will_open_lp_multi:
        'El botón "Unirse a la sesión" de estas sesiones abrirá el espacio de lecciones en línea de Tutorfly.',
      button_will_open_zoom_multi:
        'Estas sesiones El botón "Unirse a la sesión" abrirá la sala de reuniones de Zoom.',
      what_days_of_week_session_occur:
        '¿Qué días de la semana te gustaría que se realizaran las sesiones?',
      how_many_weeks_session_recur:
        '¿Durante cuántas semanas te gustaría que se repitieran las sesiones?',
      when_sessions_take_place: '¿Cuándo tendrán lugar estas sesiones?',
      book_a_session: 'Programa una sesión',
      select_your_tutor: 'Seleccione su {{tutorTerm}}.',
      select_your_tutee: 'Seleccione su {{tuteeTerm}}.',
      select_your_tutees: 'Seleccione su {{tuteeTerm}}(s).',
      you_are_not_yet_connected:
        'Aún no estás conectado con un {{opposingUserType}} en {{name}}.',
      select_one_day_sessions_recur:
        'Seleccione al menos un día de la semana para que las sesiones se repitan.',

      home: 'Inicio',
      breakout_rooms: 'Cuartos Breakout',

      what_issue_did_you_experience: '¿Qué problema experimentó?',
      share_brief_description_of_issue:
        'Comparta una breve descripción del problema que experimentó al usar el Espacio de lecciones en línea para que podamos abordar este problema en sesiones futuras.',

      edit_profile: 'Editar Perfil',
      profile_picture: 'Imagen de perfil',
      personal_info: 'Información personal',
      first_name: 'Primer nombre',
      last_name: 'Apellido',
      birthday: 'Cumpleaños',
      dob: 'Fecha de nacimiento',
      location_and_time_zone: 'Ubicación y zona horaria',
      close: 'Cerrar',
      about: 'Sobre',
      add_quick_bio_about_yourself:
        'Agrega una breve biografía sobre ti para que un {{tutorTerm}} sepa lo que estás buscando. ¿A qué grado asiste actualmente? ¿En qué temas necesitas ayuda? ',
      let_potential_know_about_you:
        'Deje que los {{tuteeTerm}} potenciales sepan por qué usted es un gran {{tutorTerm}} para las materias que puede enseñar. No dudes en mencionar aquí cualquier {{tutorTerm}} experiencia o experiencia docente anterior. ',
      i_am: 'Yo soy...',
      availability: 'Disponibilidad',
      what_days_are_you_available:
        '¿Qué días de la semana estás disponible para sesiones de {{tutorTerm}}?',
      description_of_availability: 'Descripción de disponibilidad',
      available_after_3:
        'Generalmente estoy disponible de lunes a viernes después de las 3 p.m. ...',

      series_repeats: 'La serie se repite cada',
      series_recurs_for: 'La serie se repite durante {{numWeeks}} semanas',
      repeats: 'Repite',

      calendar_view: 'Vista de Calendario',
      scheduled_sessions: 'Sesiones Individuales y Grupales',
      session_feedback: 'Comentarios',
      no_drop_in_sessions: 'No hay sesiones',
    },
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources,
  interpolation: { escapeValue: false },
});

export default i18n;
