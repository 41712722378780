import { combineReducers } from 'redux';
import user from './user';
import runtime from './runtime';
import tutorSearch from './tutorSearch';
import tutorSearchV2 from './tutorSearchV2';
import dashboard from './dashboard';
import messages from './messages';
import sessions from './sessions';
import subscriptions from './subscriptions';
import tutorProfilePage from './tutorProfilePage';
import applyToTutor from './applyToTutor';
import clientSignUp from './clientSignUp';

export default combineReducers({
  user,
  runtime,
  tutorSearch,
  tutorSearchV2,
  dashboard,
  messages,
  sessions,
  subscriptions,
  tutorProfilePage,
  applyToTutor,
  clientSignUp,
});
