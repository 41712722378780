export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';

export const PAIR_TYPE_D2S = 'directToSchools';

// GOOGLE ANALYTICS CONSTANTS

export const CATEGORIES = {
  TUTOR_SEARCH: 'Tutor Search Page',
  SESSION: 'Session Dashboard',
  GENERAL: 'General Site Operations',
  ONBOARDING: 'Onboarding Process',
  MESSAGES: 'Message Dashboard',
};

export const DIMENSION_VALUES = {
  TRUE: 'TRUE',
  FALSE: 'FALSE',
  NO_BIO: 'NO BIO',
};

export const REFERRAL_TYPES_MAP = new Map([
  ['CODE', 'Referral Code'],
  ['FRIEND', 'Friend Referral'],
  ['OTHER', 'Other'],
]);

export const tutorflyGradeLevels = [
  'Elementary',
  'Middle School',
  'High School',
  'College',
];

export const tutorflySubjects = [
  'Art',
  'Business',
  'College Essay',
  'Engineering/Tech',
  'English',
  'Foreign Language',
  'Math',
  'Music',
  'Test Prep',
  'Science',
  'Social Studies',
  'Other',
];

export const highSchoolYearOptions = [
  '9th grade',
  '10th grade',
  '11th grade',
  '12th grade',
];

export const collegeYearOptions = [
  'Freshman',
  'Sophomore',
  'Junior',
  'Senior',
  'Graduate Student',
  'Recently Graduated',
];

export const allGradeLevels = highSchoolYearOptions.concat(collegeYearOptions);

export const clientProfileTypes = [
  {
    value: 'PARENT',
    label: 'Parent',
  },
  {
    value: 'STUDENT',
    label: 'Student',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];

export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

// Avatar Constants

export const AVATAR_STYLE_XS = 'XS';
export const AVATAR_STYLE_SM = 'SM';
export const AVATAR_STYLE_MD = 'MD';
export const AVATAR_STYLE_LG = 'LG';
export const AVATAR_STYLE_VARIABLE = 'AVATAR_STYLE_VARIABLE';
export const AVATAR_STYLE_VARIABLE_V2 = 'AVATAR_STYLE_VARIABLE_V2';
export const AVATAR_STYLE_MESSAGE_PROFILE_VARIABLE =
  'AVATAR_STYLE_MESSAGE_PROFILE_VARIABLE';
export const AVATAR_STYLE_REFERRAL = 'AVATAR_STYLE_REFERRAL';
export const AVATAR_STYLE_MOBILE_MESSENGER = 'AVATAR_STYLE_MOBILE_MESSENGER';
export const AVATAR_SESSION_CARD_STYLE_VARIABLE =
  'AVATAR_SESSION_CARD_STYLE_VARIABLE';
export const AVATAR_SESSION_CARD_STYLE_V2 = 'AVATAR_SESSION_CARD_STYLE_V2';
export const AVATAR_STYLE_REQUEST = 'AVATAR_STYLE_REQUEST';
export const AVATAR_STYLE_HEADER_BAR = 'AVATAR_STYLE_HEADER_BAR';
export const AVATAR_STYLE_EDIT_PROFILE = 'AVATAR_STYLE_EDIT_PROFILE';

// Location constants

export const NULL_COORDINATE = 0;

// PROFILE TYPE CONSTANTS

export class ProfileType {
  static get TUTOR() {
    return 'Tutor';
  }

  static get STUDENT_OR_PARENT() {
    return 'StudentOrParent';
  }

  static get ADMIN() {
    return 'Admin';
  }
}

// General constants file
export class DevelopmentEnvironment {
  static get DEV() {
    return 'development';
  }

  static get E2E() {
    return 'e2e';
  }

  static get PROD() {
    return 'production';
  }
}

// eslint-disable-next-line arrow-body-style
const GET_URL_PREFIX = () => {
  return __DEV__ ? 'http://' : 'https://';
  // const devEnv = process.env.DEVELOPMENT_ENVIRONMENT;

  // if (devEnv === DevelopmentEnvironment.DEV) {
  //   return 'http://';
  // } else if (
  //   devEnv === DevelopmentEnvironment.E2E ||
  //   devEnv === DevelopmentEnvironment.PROD
  // ) {
  //   return 'https://';
  // }

  // return 'https://';
};
export const URL_PREFIX = GET_URL_PREFIX();

const getDomainName = () => {
  if (__DEV__) {
    return 'localhost';
  }

  const devEnv = process.env.DEVELOPMENT_ENVIRONMENT;
  if (devEnv === DevelopmentEnvironment.E2E) {
    return 'tutorfly-mrktplc-client-e2e.herokuapp.com';
  }

  return 'tutorfly.com';
};
export const DOMAIN_NAME = getDomainName();

export const CLIENT_HOSTNAME = __DEV__ ? `${DOMAIN_NAME}:3005` : DOMAIN_NAME;
export const WEB_CLIENT_URL = `${URL_PREFIX}${CLIENT_HOSTNAME}`;
export const TUTORFLY_MARKETPLACE_PROD_URL = `https://tutorfly.com`;

export const reviewTuteeURL =
  'https://docs.google.com/forms/d/1sIWDimEFx6LbVpHvajW6mKlVBE1blb4_g8aE-R4sLQA/viewform';
export const dashboardInboxLink = '/dashboard/messages/home';
export const findATutorLink = '/find-a-tutor';
export const editProfileLink = '/dashboard/edit-profile/home';
export const addHighSchoolClassesLink =
  '/dashboard/edit-profile/add-high-school-classes';
export const addCollegeClassesLink =
  '/dashboard/edit-profile/add-college-classes';
export const mySessionsLink = '/dashboard/lessons/upcoming';
export const privacyPolicy =
  'https://drive.google.com/file/d/1gWLRKK0oRn2uqDM0hyqhgVaHJBNK-NUu/view';
export const termsOfUse =
  'https://drive.google.com/file/d/1ECBsUJIvVAnyF1w-9Glktczh2fYo-Op4/view';
export const myRequestsLink = '/dashboard/connections/tutoring-requests';
export const myConnectionsLink = '/dashboard/connections/tutors';
export const tutorflyLogoBB = '/assets/TutorflyLogoBB.png';

export const STRIPE_PUBLISHABLE_KEY =
  process.env.NODE_ENV === 'production'
    ? 'pk_live_bP6PNt2Dvfpk2J5bKDYzppGx' // prod key
    : 'pk_test_wXtDcyh9nkHMb2WzU61fgT2O'; // dev key

export const STRIPE_CONNECT_OAUTH_CLIENT_ID =
  process.env.STRIPE_CLIENT_ID || 'ca_GNEIoECUl2emFaTpM8jdZ9XgRfqehODd';

export const AVAILABILITY_PH =
  'Mondays and Wednesdays after 4 pm;' +
  '\nSaturdays and Sundays between 11 am to 2 pm;';

// set to true to let existing users access the request a tutor form
export const SHOW_REQUEST_A_TUTOR_FORM = false;

export const MILLISECONDS_PER_SECOND = 1000;

export const SECONDS_PER_MINUTE = 60;

export const MINUTES_PER_HOUR = 60;

export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR;

export const MILLISECONDS_PER_HOUR = MILLISECONDS_PER_SECOND * SECONDS_PER_HOUR;

export const MILLISECONDS_PER_MINUTE =
  MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE;

export const STRIPE_CONNECT_OAUTH_ERROR_CODE = 'stripeOauthError';

export const referralOptions = [
  {
    value: 'NO_SELECTION',
    label: '',
  },
  {
    value: 'FROM_A_FRIEND',
    label: 'From a friend',
  },
  {
    value: 'FROM_MY_SCHOOL',
    label: 'From my school',
  },
  {
    value: 'NEXTDOOR',
    label: 'Nextdoor',
  },
  {
    value: 'TUTORS_DOT_COM',
    label: 'Tutors.com',
  },
  {
    value: 'GOOGLE_SEARCH',
    label: 'Google Search',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];

export const reportFrequencies = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
};

// Active Tutor List Section Constants
export const MathSections = {
  LOWER_LEVEL: 'Lower Level Math Tutors',
  GEOMETRY: 'Geometry Tutors',
  CALCULUS: 'Calculus Tutors',
  STATISTICS: 'Statistics Tutors',
  OTHER_MATH: '"Other" Math Tutors',
};

export const ScienceSections = {
  BIOLOGY: 'Biology Tutors',
  CHEMISTRY: 'Chemistry Tutors',
  PHYSICS: 'Physics Tutors',
  PHYSIOLOGY_ANATOMY: 'Physiology/Anatomy',
  OTHER_SCIENCE: '"Other" Science Tutors',
};

export const ComputerScienceSections = {
  PYTHON: 'Python',
  JAVA: 'Java Tutors (AP CS)',
  JAVASCRIPT: 'JavaScript | HTML | CSS',
  C: 'C++ | C | C#',
  OTHER: '"Other" CS Tutors',
};

export const HumanitiesSections = {
  ENGLISH: 'English',
  READING_WRITING: 'Reading/Writing',
  HISTORY: 'History',
  ESL: 'ESL',
};

export const ForeignLangSections = {
  SPANISH: 'Spanish',
  FRENCH: 'French',
  CHINESE: 'Chinese',
  KOREAN: 'Korean',
  RUSSIAN_UKRAINIAN: 'Russian/Ukrainian',
  GERMAN: 'German',
};

export const SpecialNeedsSections = {
  YOUNG_KIDS: 'Young Kids (K-5)',
  SPECIAL_NEEDS_GENERAL: 'Special Needs (General)',
  ADHD_MILD: 'ADHD (Mild)',
  DYSLEXIA_MILD: 'Dyslexia (Mild)',
};

export const StandardizedTestingSections = {
  SAT: 'SAT',
  ACT: 'ACT',
  ASVAB_MILITARY: 'ASVAB (Military Apt.)',
  GED: 'GED',
  NCLEX_NURSING: 'NCLEX (Nursing)',
  DAT: 'DAT (Dental Admissions)',
};

export const BusinessSections = {
  ACCOUNTING: 'Accounting',
  ECONOMICS: 'Economics',
  GOVERNMENT_AP: 'Government (AP)',
};

export const SessionParticipantTypes = {
  TUTOR: 'Tutor',
  STUDENT: 'Student',
  ADMIN: 'Admin',
};

export const CUSTOM_SIGN_UP_LINK_CREDIT_ENABLED = false;

export const MARKETPLACE_POST_SESSION_DIALOG_ENABLED = false;

export const SessionCompletionStatus = {
  HOURS_GRANTED_MANUALLY: 'HOURS_GRANTED_MANUALLY',
  HOURS_DENIED_MANUALLY: 'HOURS_DENIED_MANUALLY',
};

export const SessionType = {
  ONE_ON_ONE: 'ONE_ON_ONE',
  DROP_IN: 'DROP_IN',
  GROUP: 'GROUP',
  ONE_ON_ONE_BREAKOUT: 'ONE_ON_ONE_BREAKOUT',
};

export const SessionCancelType = {
  SINGLE: 'SINGLE',
  ALL: 'ALL',
};

export const SessionStatus = {
  UPCOMING: 'UPCOMING',
  RECENTLY_COMPLETED: 'RECENTLY_COMPLETED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
};

export const SessionParticipantType = {
  STUDENT: 'STUDENT',
  TUTOR: 'TUTOR',
  ADMIN: 'ADMIN',
};

export const SessionMeetingSolutionType = {
  LESSONSPACE: 'LESSONSPACE',
  ZOOM: 'ZOOM',
  IN_PERSON: 'IN_PERSON',
  CUSTOM_URL: 'CUSTOM_URL',
};

export const ProductContext = {
  MARKETPLACE: 'MARKETPLACE',
};

export const SessionPaymentStatus = {
  ERROR: 'error',
  MANUAL: 'manual',
  STRIPE_CONNECT: 'stripeConnect',
  PARTIAL: 'partial',
};

export const SessionCapabilities = {
  ONE_ON_ONE: 'ONE_ON_ONE',
  DROP_IN: 'DROP_IN',
  GROUP: 'GROUP',
  ONE_ON_ONE_BREAKOUT: 'ONE_ON_ONE_BREAKOUT',
  SESSION_TUTORING_REQUESTS: 'SESSION_TUTORING_REQUESTS',
};

export const NotificationType = {
  EMAIL: 'Email',
  TEXT: 'Text',
};

export const LanguageLocaleConstants = {
  SPANISH: 'sp',
  ENGLISH: 'en',
};

export const LanguageTranslationTypes = {
  en: 'English',
  sp: 'Español',
};

export const SupportedOauthProviders = {
  CLEVER: 'clever',
  SCHOOLOGY: 'schoology',
};

export const ProfileActivityTypes = {
  NOTE: 'NOTE',
  FEEDBACK: 'FEEDBACK',
  RECORDING: 'RECORDING',
};

export const FormResponseTypes = {
  TUTOR_POST_SESSION_QUESTION: 'TUTOR_POST_SESSION_QUESTION',
  STUDENT_POST_SESSION_QUESTION: 'STUDENT_POST_SESSION_QUESTION',
  TUTOR_TO_STUDENT_ASSESSMENT: 'TUTOR_TO_STUDENT_ASSESSMENT',
  STUDENT_TO_TUTOR_ASSESSMENT: 'STUDENT_TO_TUTOR_ASSESSMENT',
};

// Assessment form types
export const AssessmentFormTypes = {
  SOCIAL_EMOTIONAL_LEARNING: 'SEL',
};

// Assessment question categories
export const AssessmentQuestionCategories = {
  SELF_AWARENESS: 'Self-Awareness',
  SELF_MANAGEMENT: 'Self-Management',
  WELL_BEING: 'Well-Being',
  RELATIONSHIP_SKILLS: 'Relationship Skills',
  RESPONSIBLE_DECISION_MAKING: 'Responsible Decision-Making',
};

export const AssessmentQuestionTypes = {
  SCORE: 'Score',
  TEXT: 'Text',
  NUMBER: 'Number',
  GRADE: 'Grade',
  YES_NO: 'Yes/No',
  AGREE_DISAGREE_5: 'AGREE_DISAGREE_5',
};

export const AssessmentQuestionResponseType = {
  STANDARD_SCORE: 'STANDARD',
  FLIPPED_SCORE: 'FLIPPED',
};

// The new platform rate we want all new tutors set at for each new pair they are connected to.
export const NEW_TUTOR_PLATFORM_FEE_PERCENTAGE_RATE = 9.7;

// The new platform rate we want all new tutees set at for each new pair they are connected to.
export const NEW_TUTEE_PLATFORM_FEE_PERCENTAGE_RATE = 9.7;

// The legacy platform rate we want all new tutors set at for each new pair they are connected to.
export const LEGACY_TUTOR_PLATFORM_FEE_PERCENTAGE_RATE = 0;

// The legacy platform rate we want all new tutees set at for each new pair they are connected to.
export const LEGACY_TUTEE_PLATFORM_FEE_PERCENTAGE_RATE = 7.7;
