import { ProfileType } from 'shared/constants/index';
import {
  ADD_SESSION,
  SET_SESSIONS,
  REMOVE_SESSION,
  CLEAR_SESSIONS,
  UPDATE_SESSION,
  SWITCH_TO_CLIENT,
  SWITCH_TO_TUTOR,
  ADD_TUTORING_REQUESTS,
  REMOVE_TUTORING_REQUEST,
  EDIT_CONTACT_REQUEST_MESSAGE,
} from 'shared/types/index';

const INITIAL_STATE = {
  // array for sessions gotten from GraphQL
  sessions: [],
  tutoringRequests: [],
  userType: ProfileType.STUDENT_OR_PARENT,
};

// immutable array updater for redux
function updateSessionInArray(sessionArray, updatedSession) {
  return sessionArray.map(session => {
    // essentially, it just returns a new array with the
    // session replaced by the new one
    if (session.id === updatedSession.id) {
      return updatedSession;
    }

    return session;
  });
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // #region Session actions
    case ADD_SESSION:
      return {
        ...state,
        sessions: [action.payload.session].concat(state.sessions),
      };

    case SET_SESSIONS:
      return {
        ...state,
        sessions: action.payload.sessions,
      };

    case REMOVE_SESSION:
      return {
        ...state,
        sessions: state.sessions.filter(
          session => session.id !== action.payload.sessionId,
        ),
      };

    case CLEAR_SESSIONS:
      return {
        ...state,
        sessions: [],
      };

    case UPDATE_SESSION:
      return {
        ...state,
        sessions: updateSessionInArray(
          state.sessions,
          action.payload.updatedSession,
        ),
      };
    // #endregion

    // #region current user's type
    case SWITCH_TO_CLIENT:
      return {
        ...state,
        userType: ProfileType.STUDENT_OR_PARENT,
      };

    case SWITCH_TO_TUTOR:
      return {
        ...state,
        userType: ProfileType.TUTOR,
      };
    // #endregion

    // #region Tutoring requests
    case ADD_TUTORING_REQUESTS:
      return {
        ...state,
        tutoringRequests: action.payload.tutoringRequests,
      };

    case REMOVE_TUTORING_REQUEST:
      return {
        ...state,
        tutoringRequests: state.tutoringRequests.filter(
          request => request.id !== action.payload.requestId,
        ),
      };

    case EDIT_CONTACT_REQUEST_MESSAGE:
      return {
        ...state,
        tutoringRequests: action.payload.tutoringRequests,
      };
    // #endregion

    default:
      return state;
  }
};
