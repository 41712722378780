import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function SnackBarProviderWrapper({ children }) {
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      ref={notistackRef}
      action={key => (
        <IconButton aria-label="close" onClick={onClickDismiss(key)}>
          <CloseIcon style={{ color: '#FFF' }} />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
}
SnackBarProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SnackBarProviderWrapper;
