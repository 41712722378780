import merge from 'lodash/merge';
import {
  UPDATE_SUBSCRIPTION,
  UPDATE_CURRENT_USER,
  UPDATE_PARTIAL_USER,
  REMOVE_PAYMENT_METHOD,
  UPDATE_PAIRED_TUTORS,
  UPDATE_PAIRED_TUTEES,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  SET_BADGE_NOTIFICATION_AMOUNT,
  // User connection info events for displaying
  // content and notifications.
  UPDATE_USER_CONNECTION_INFO,
  UPDATE_USER_CONNECTION_INFO_LOADING,
  UPDATE_USER_CONNECTION_ERROR,
  SET_URL_PATH,
} from '../types';

const INITIAL_STATE = {
  currentUser: null,
  notifications: [],
  messageBadgeAmount: 0,
  pairedTutors: [],
  pairedTutees: [],
  tutors: [],
  hasUserConnectionsLoading: false,
  hasUserConnections: false,
  connectionSummaryError: false,
};

/* eslint-disable no-case-declarations */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Used to update any any meteor subscription. tutors, sessions, tutoringRequests...
    case UPDATE_SUBSCRIPTION:
      const { subscription, data } = action.payload;
      return {
        ...state,
        [subscription]: data,
      };

    case UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload.currentUser,
      };

    case UPDATE_PARTIAL_USER:
      return {
        ...state,
        currentUser: merge({}, state.currentUser, action.payload.partialUser),
      };

    case REMOVE_PAYMENT_METHOD:
      return {
        ...state,
        currentUser: merge(
          {},
          action.payload.currentUser,
          action.payload.partialUser,
        ),
      };

    case UPDATE_PAIRED_TUTORS:
      return {
        ...state,
        pairedTutors: action.payload.pairedTutors,
      };

    case UPDATE_PAIRED_TUTEES:
      return {
        ...state,
        pairedTutees: action.payload.pairedTutees,
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.concat(action.payload.notification),
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== action.payload.notificationId,
        ),
      };

    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    case SET_BADGE_NOTIFICATION_AMOUNT:
      return {
        ...state,
        messageBadgeAmount: action.payload.badgeCount,
      };

    // User connection info events for displaying
    // content and notifications.
    case UPDATE_USER_CONNECTION_INFO:
      return {
        ...state,
        hasUserConnections: action.payload.hasUserConnections,
        connectionSummaryError: false,
      };

    case UPDATE_USER_CONNECTION_INFO_LOADING:
      return {
        ...state,
        hasUserConnectionsLoading: action.payload.hasUserConnectionsLoading,
        connectionSummaryError: false,
      };

    case UPDATE_USER_CONNECTION_ERROR:
      return {
        ...state,
        connectionSummaryError: action.payload.connectionSummaryError,
      };

    case SET_URL_PATH:
      return {
        ...state,
        urlPath: action.payload.urlPath,
      };

    default:
      return state;
  }
};
