import {
  ADD_SESSION,
  SET_SESSIONS,
  REMOVE_SESSION,
  CLEAR_SESSIONS,
  UPDATE_SESSION,
  SWITCH_TO_CLIENT,
  SWITCH_TO_TUTOR,
  ADD_TUTORING_REQUESTS,
  REMOVE_TUTORING_REQUEST,
  EDIT_CONTACT_REQUEST_MESSAGE,
} from 'shared/types/index';
import {
  GET_TUTORING_REQUESTS_OF_TUTOR_QUERY,
  GET_TUTORING_REQUESTS_OF_TUTEE_QUERY,
} from 'shared/constants/gql-constants';

// #region regular redux actions
export const addSession = session => ({
  type: ADD_SESSION,
  payload: { session },
});

export const addSessions = sessions => ({
  type: SET_SESSIONS,
  payload: { sessions },
});

export const removeSession = sessionId => ({
  type: REMOVE_SESSION,
  payload: { sessionId },
});

export const clearSessions = () => ({
  type: CLEAR_SESSIONS,
});

export const updateSession = updatedSession => ({
  type: UPDATE_SESSION,
  payload: { updatedSession },
});

export const switchToClient = () => ({
  type: SWITCH_TO_CLIENT,
});

export const switchToTutor = () => ({
  type: SWITCH_TO_TUTOR,
});

export const addTutoringRequests = tutoringRequests => ({
  type: ADD_TUTORING_REQUESTS,
  payload: { tutoringRequests },
});

export const removeTutoringRequest = requestId => ({
  type: REMOVE_TUTORING_REQUEST,
  payload: { requestId },
});

export const editContactRequestMessage = requestId => (dispatch, getState) => {
  const { dashboard: { tutoringRequests } } = getState();
  const updatedRequests = [];
  for (let x = 0; x < tutoringRequests.length; x += 1) {
    if (tutoringRequests[x].id === requestId) {
      updatedRequests[x] = tutoringRequests[x];
    }
  }

  dispatch({
    type: EDIT_CONTACT_REQUEST_MESSAGE,
    payload: {
      tutoringRequests: updatedRequests,
    },
  });
};
// #endregion

// #region redux thunk actions: https://medium.com/@stowball/a-dummys-guide-to-redux-and-thunk-in-react-d8904a7005d3

export const loadRequestsOfTutor = client => dispatch => {
  client
    .query({
      fetchPolicy: 'network-only',
      query: GET_TUTORING_REQUESTS_OF_TUTOR_QUERY,
    })
    .then(({ data }) =>
      dispatch(addTutoringRequests(data.getRequestsOfTutorQuery)),
    );
};

export const loadRequestsOfTutee = client => dispatch => {
  client
    .query({
      fetchPolicy: 'network-only',
      query: GET_TUTORING_REQUESTS_OF_TUTEE_QUERY,
    })
    .then(({ data }) =>
      dispatch(addTutoringRequests(data.getRequestsOfTuteeQuery)),
    );
};
// #endregion
