/* eslint-disable no-case-declarations */

import { SET_SIGN_UP_INFO, UPDATE_CLIENT_SIGN_UP_STATE } from '../types';

const INITIAL_STATE = {
  flowState: 'ACCOUNT', // { ACCOUNT, PROFILE_TYPE, CONTACT_INFO }
  accountType: '', // { EMAIL, FACEBOOK, GOOGLE }

  // Fields required to create account
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  clientProfileType: 'PARENT', // { PARENT, STUDENT, OTHER }
  phoneNumber: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SIGN_UP_INFO:
      const { updatedSignupInfo } = action.payload;
      return {
        ...state,
        ...updatedSignupInfo,
      };

    case UPDATE_CLIENT_SIGN_UP_STATE:
      const { flowState } = action.payload;
      return {
        ...state,
        flowState,
      };

    default:
      return state;
  }
};
