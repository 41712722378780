/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
// everything not exported is not meant to be used outside
// this file
import { isEmpty } from 'shared/utilities/utils';
import {
  MILLISECONDS_PER_MINUTE,
  MINUTES_PER_HOUR,
} from 'shared/constants/index';

const _hasSuffix = (str, suffix) => {
  const trimmedString = str.trim().toLowerCase();
  return (
    trimmedString.lastIndexOf(suffix.toLowerCase()) ===
    trimmedString.length - suffix.length
  );
};

const _hasAMSuffix = timePart =>
  _hasSuffix(timePart, 'AM') || _hasSuffix(timePart, 'A.M.');

const _hasPMSuffix = timePart =>
  _hasSuffix(timePart, 'PM') || _hasSuffix(timePart, 'P.M.');

const _hasZSuffix = timePart => _hasSuffix(timePart, 'Z');

class TimeFormatTypes {
  static get HOUR_24_TYPE() {
    return 'HOUR_24_TYPE';
  }

  static get HOUR_12_TYPE() {
    // 2017-07-30 11:20 PM
    return 'HOUR_12_TYPE';
  }

  static get HOUR_Z_TYPE() {
    // 2017-07-30T09:25:44.820Z
    return 'HOUR_Z_TYPE';
  }
}

const _checkTimeFormat = timePart => {
  if (_hasAMSuffix(timePart) || _hasPMSuffix(timePart)) {
    return TimeFormatTypes.HOUR_12_TYPE;
  } else if (_hasZSuffix(timePart)) {
    return TimeFormatTypes.HOUR_Z_TYPE;
  }
  return TimeFormatTypes.HOUR_24_TYPE;
};

const daysInMonth = (month, year) => new Date(year, month, 0).getDate();

const _formatDateList = origDateList => {
  const dateList = origDateList.map(date => date.replace(/[^0-9]/g, ''));
  if (dateList.length >= 1) {
    dateList[0] = Math.max(0, Math.min(+dateList[0] || 1970, 9999)).toString();
  }
  if (dateList.length >= 2) {
    dateList[1] = Math.max(1, Math.min(+dateList[1] || 1, 12)).toString();
  }
  if (dateList.length >= 3) {
    dateList[2] = Math.max(
      1,
      Math.min(+dateList[2] || 1, daysInMonth(+dateList[1], +dateList[0])),
    ).toString();
  }

  if (dateList.length <= 0) {
    dateList.push('1970');
  }
  if (dateList.length <= 1) {
    dateList.push('01');
  }
  if (dateList.length <= 2) {
    dateList.push('01');
  }

  dateList[0] = `0020${dateList[0]}`; // Changes under request of Ethan
  dateList[0] = dateList[0].substring(
    dateList[0].length - 4,
    dateList[0].length,
  );
  dateList[1] = `00${dateList[1]}`;
  dateList[1] = dateList[1].substring(
    dateList[1].length - 2,
    dateList[1].length,
  );
  dateList[2] = `00${dateList[2]}`;
  dateList[2] = dateList[2].substring(
    dateList[2].length - 2,
    dateList[2].length,
  );

  return dateList.slice(0, 3);
};

const _formatTimeList = origTimeList => {
  const timeList = origTimeList;
  if (timeList.length >= 1) {
    timeList[0] = Math.min(+timeList[0] || 12, 24).toString();
  }
  for (let i = 1; i < 3; i++) {
    if (timeList.length > i) {
      timeList[i] = Math.min(+timeList[i] || 0, 60).toString();
      if (+timeList[0] >= 24) {
        timeList[i] = '00';
      }
    }
  }
  if (timeList.length <= 0) {
    timeList.push('00');
  }
  if (timeList.length <= 1) {
    timeList.push('00');
  }
  if (timeList.length <= 2) {
    timeList.push('00');
  }
  for (let i = 0; i < Math.min(timeList.length, 3); i++) {
    timeList[i] = timeList[i].replace(/[^0-9]/g, '');
    if (timeList[i].length === 0) {
      timeList[i] = '00';
    } else if (timeList[i].length === 1) {
      timeList[i] = `0${timeList[i]}`;
    } else {
      timeList[i] = timeList[i].slice(0, 2);
    }
  }
  return timeList.slice(0, 3);
};

const _convertTimeFrom12HoursTo24HoursFormat = timePart => {
  const trimmedTimePart = timePart.replace(/[^0-9:APMapm]/g, '').toLowerCase();
  let timeToBeModified = trimmedTimePart;
  let timeToBeModifiedList = [];
  if (_hasPMSuffix(trimmedTimePart)) {
    timeToBeModified = trimmedTimePart.slice(
      0,
      trimmedTimePart.lastIndexOf('pm'),
    );
    timeToBeModifiedList = timeToBeModified.split(':');
    if (timeToBeModifiedList.length > 0) {
      timeToBeModifiedList[0] = Math.min(
        +timeToBeModifiedList[0] + 12 || 12,
        24,
      ).toString();
    }
  } else if (_hasAMSuffix(trimmedTimePart)) {
    timeToBeModified = trimmedTimePart.slice(
      0,
      trimmedTimePart.lastIndexOf('am'),
    );
    timeToBeModifiedList = timeToBeModified.split(':');
  }
  timeToBeModifiedList = _formatTimeList(timeToBeModifiedList);
  return timeToBeModifiedList.join(':');
};

const _justifyTime24HoursFormat = timePart => {
  const trimmedTimePart = timePart.replace(/[^0-9:]/g, '').toLowerCase();
  let timeToBeModifiedList = trimmedTimePart.split(':');
  timeToBeModifiedList = _formatTimeList(timeToBeModifiedList);
  return timeToBeModifiedList.join(':');
};

const _justifyDateFormat = datePart => {
  const leanDatePart = datePart.replace(/[^0-9/-]/g, '');
  let dateList = [];
  if (leanDatePart.indexOf('/') > 0) {
    dateList = leanDatePart.split('/');
    if (dateList.length > 0) {
      const year = dateList.pop();
      dateList.unshift(year);
    }
  } else {
    dateList = leanDatePart.split('-');
  }
  // prefer 01/01/2017 format since it works for both chrome and safari
  const formattedDateList = _formatDateList(dateList).slice(0, 3);
  formattedDateList.push(formattedDateList.shift());
  return formattedDateList.join('/');
  // return _formatDateList(dateList).slice(0, 3).join('-')
};

const _constructDateWithTime = (datePart, timePart) => {
  const improvedDatePart = _justifyDateFormat(datePart);
  let improvedTimePart = timePart;
  switch (_checkTimeFormat(timePart)) {
    case TimeFormatTypes.HOUR_12_TYPE:
      improvedTimePart = _convertTimeFrom12HoursTo24HoursFormat(timePart);
      break;
    case TimeFormatTypes.HOUR_24_TYPE:
      improvedTimePart = _justifyTime24HoursFormat(timePart);
      break;
    case TimeFormatTypes.HOUR_Z_TYPE:
      // I would assume no Z formatted date format should have problems...
      // (Since these should be code generated)
      improvedTimePart = timePart;
      break;
    default:
      improvedTimePart = '00:00:00';
  }

  // The following are Number (OR NaN)
  // Two formats, previous works for Chrome, latter for Safari
  const baseDateTimeParse = Date.parse(`${datePart} ${timePart}`); // || Date.parse(`${datePart}T${timePart}`)
  // T version yields wrong time on safari (syntactically correct, unexpected time value)
  const baseTimeParse = Date.parse(timePart);
  if (!Number.isNaN(baseDateTimeParse)) {
    // preferred, normal parsing
    return new Date(baseDateTimeParse);
  }
  if (!Number.isNaN(baseTimeParse)) {
    return new Date(baseTimeParse);
  }

  const improvedDateTimeParse = Date.parse(
    `${improvedDatePart} ${improvedTimePart}`,
  ); // || Date.parse(`${improvedDatePart}T${improvedTimePart}`)
  // T version yields wrong time on safari (syntactically correct, unexpected time value)
  const improvedDateParse = Date.parse(improvedDatePart);
  const improvedTimeParse = Date.parse(improvedTimePart);

  if (!Number.isNaN(improvedDateTimeParse)) {
    return new Date(improvedDateTimeParse);
  }
  if (!Number.isNaN(improvedTimeParse)) {
    return new Date(improvedTimeParse);
  }
  if (!Number.isNaN(improvedDateParse)) {
    return new Date(improvedDateParse);
  }

  return null;
};

const _constructDateWithoutTime = datePart => {
  const baseDateParse = Date.parse(datePart);
  if (!Number.isNaN(baseDateParse)) {
    return new Date(baseDateParse);
  }
  return null; // return null if not valid
};

export const constructTime = timePart => {
  let improvedTimePart = timePart;
  switch (_checkTimeFormat(timePart)) {
    case TimeFormatTypes.HOUR_12_TYPE:
      improvedTimePart = _convertTimeFrom12HoursTo24HoursFormat(timePart);
      break;
    case TimeFormatTypes.HOUR_24_TYPE:
      improvedTimePart = _justifyTime24HoursFormat(timePart);
      break;
    case TimeFormatTypes.HOUR_Z_TYPE:
      // I would assume no Z formatted date format should have problems...
      // (Since these should be code generated)
      improvedTimePart = timePart;
      break;
    default:
      improvedTimePart = '00:00:00';
  }
  return improvedTimePart;
};

export const getDateFromTime = time => {
  if (isEmpty(time)) {
    return null;
  }

  const date = new Date();
  const timeParts = time.split(':');
  let hour = Number(timeParts[0]);
  const minutes = Number(timeParts[1].split(' ')[0]);
  const amPm = timeParts[1].split(' ')[1];

  if (amPm === 'pm' && hour < 12) {
    hour = Number(hour) + 12;
  } else if (amPm === 'am' && hour === 12) {
    hour = Number(hour) - 12;
  }

  date.setHours(hour);
  date.setMinutes(minutes);
  return date;
};

export const constructDate = (datePart, timePart) => {
  let cascadedResult = null;

  if (timePart) {
    cascadedResult =
      cascadedResult || _constructDateWithTime(datePart, timePart);
  }

  const [splittedDatePart, ...splittedTimeParts] = datePart.split(' ');
  cascadedResult =
    cascadedResult ||
    _constructDateWithTime(splittedDatePart, splittedTimeParts.join(' '));

  cascadedResult =
    cascadedResult || _constructDateWithoutTime(splittedDatePart);

  if (cascadedResult) {
    return new Date(cascadedResult);
  }
  return null;
};

export const universalDateString = date => {
  // Returns a string that can be used in date constructors across all browsers
  // e.g. 03/20/2018
  if (!(date instanceof Date)) {
    return '';
  }
  const month = `0${date.getMonth()}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const validateDateString = dateString => {
  if (typeof dateString !== 'string') {
    return false;
  }

  const slashCheckRegex = /^(0\d|1[0-2]|\d)\/([0-2]\d|[3][0-1]|\d)\/(20\d\d|\d\d)$/;
  const dashCheckRegex = /^(20\d\d|\d\d)-(0\d|1[0-2]|\d)-([0-2]\d|3[0-1]|\d)$/;

  if (slashCheckRegex.test(dateString)) {
    return true;
  } else if (dashCheckRegex.test(dateString)) {
    return true;
  }

  return false;
};

// formats date into a string like "5 minutes ago"
export function timeSince(date) {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return interval === 1 ? `${interval} year ago` : `${interval} years ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval === 1 ? `${interval} month ago` : `${interval} months ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval === 1 ? `${interval} day ago` : `${interval} days ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval === 1 ? `${interval} hour ago` : `${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval === 1
      ? `${interval} minute ago`
      : `${interval} minutes ago`;
  }
  return '1 minute ago';
}

export const daysOfTheWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const timeSlots = [
  '12:00 pm',
  '12:15 pm',
  '12:30 pm',
  '12:45 pm',
  '1:00 pm',
  '1:15 pm',
  '1:30 pm',
  '1:45 pm',
  '2:00 pm',
  '2:15 pm',
  '2:30 pm',
  '2:45 pm',
  '3:00 pm',
  '3:15 pm',
  '3:30 pm',
  '3:45 pm',
  '4:00 pm',
  '4:15 pm',
  '4:30 pm',
  '4:45 pm',
  '5:00 pm',
  '5:15 pm',
  '5:30 pm',
  '5:45 pm',
  '6:00 pm',
  '6:15 pm',
  '6:30 pm',
  '6:45 pm',
  '7:00 pm',
  '7:15 pm',
  '7:30 pm',
  '7:45 pm',
  '8:00 pm',
  '8:15 pm',
  '8:30 pm',
  '8:45 pm',
  '9:00 pm',
  '9:15 pm',
  '9:30 pm',
  '9:45 pm',
  '10:00 pm',
  '10:15 pm',
  '10:30 pm',
  '10:45 pm',
  '11:00 pm',
  '11:15 pm',
  '11:30 pm',
  '11:45 pm',
  '12:00 am',
  '12:15 am',
  '12:30 am',
  '12:45 am',
  '1:00 am',
  '1:15 am',
  '1:30 am',
  '1:45 am',
  '2:00 am',
  '2:15 am',
  '2:30 am',
  '2:45 am',
  '3:00 am',
  '3:15 am',
  '3:30 am',
  '3:45 am',
  '4:00 am',
  '4:15 am',
  '4:30 am',
  '4:45 am',
  '5:00 am',
  '5:15 am',
  '5:30 am',
  '5:45 am',
  '6:00 am',
  '6:15 am',
  '6:30 am',
  '6:45 am',
  '7:00 am',
  '7:15 am',
  '7:30 am',
  '7:45 am',
  '8:00 am',
  '8:15 am',
  '8:30 am',
  '8:45 am',
  '9:00 am',
  '9:15 am',
  '9:30 am',
  '9:45 am',
  '10:00 am',
  '10:15 am',
  '10:30 am',
  '10:45 am',
  '11:00 am',
  '11:15 am',
  '11:30 am',
  '11:45 am',
];

export const getMonthName = date => months[date.getMonth()];

function formatOrdinal(num) {
  if (num > 3 && num < 21) return `${num}th`;
  switch (num % 10) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
}

export function formatSessionDateTime(date, fallbackName) {
  if (Number.isNaN(date.getTime())) {
    return `Contact ${fallbackName} to confirm this session's the date and time.`;
  }

  const dayOfTheWeek = daysOfTheWeek[date.getDay()];
  const dayOfTheMonth = date.getDate();
  const month = months[date.getMonth()];
  const minutes =
    date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;

  const militaryTime = date.getHours();
  let time;
  if (militaryTime === 12) {
    time = `${militaryTime}:${minutes} PM`;
  } else {
    time =
      militaryTime > 12
        ? `${militaryTime - 12}:${minutes} PM`
        : `${militaryTime}:${minutes} AM`;
  }

  return `${dayOfTheWeek}, ${month} ${dayOfTheMonth} at ${time}`;
}

export function formatSessionDate(date) {
  if (!date) {
    return '';
  }

  if (Number.isNaN(date.getTime())) {
    return '';
  }

  const dayOfTheWeek = daysOfTheWeek[date.getDay()];
  const dayOfTheMonth = date.getDate();
  const year = date.getFullYear();
  const month = months[date.getMonth()];

  return `${dayOfTheWeek}, ${month} ${formatOrdinal(dayOfTheMonth)}, ${year}`;
}

export function formatBirthDate(date) {
  if (!date) {
    return '';
  }

  if (Number.isNaN(date.getTime())) {
    return '';
  }

  const dayOfTheMonth = date.getDate();
  const year = date.getFullYear();
  const month = months[date.getMonth()];

  return `${month} ${formatOrdinal(dayOfTheMonth)}, ${year}`;
}

export function formatSessionTime(date) {
  if (!date) {
    return '';
  }

  if (Number.isNaN(date.getTime())) {
    return '';
  }

  const options = {
    hour: 'numeric',
    minute: '2-digit',
  };
  return date.toLocaleTimeString('en-US', options);
}

export function getMilitaryTimeOfDate(date) {
  if (!date) {
    return '';
  }

  if (Number.isNaN(date.getTime())) {
    return '';
  }

  const options = {
    hc: 'h24',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  return date.toLocaleTimeString('en-US', options);
}

export function getDateFormattedForServer(date) {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function getDateFormattedForServerV2(date) {
  const numMonth = date.getMonth() + 1;
  let monthFormatted = `${numMonth}`;
  if (numMonth < 10) {
    monthFormatted = `0${monthFormatted}`;
  }

  const numDay = date.getDate();
  let dayFormatted = `${numDay}`;
  if (numDay < 10) {
    dayFormatted = `0${dayFormatted}`;
  }

  const year = date.getFullYear();
  return `${year}-${monthFormatted}-${dayFormatted}`;
}

export function getDateComponents(date) {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return { year, month, day };
}

export function getTimeComponents(date) {
  if (!date) {
    return { hour: 0, minute: 0 };
  }

  if (Number.isNaN(date.getTime())) {
    return { hour: 0, minute: 0 };
  }

  const militaryTime = getMilitaryTimeOfDate(date);
  const timeComps = militaryTime.split(':');

  return {
    hour: Number(timeComps[0]),
    minute: Number(timeComps[1]),
  };
}

export function formatSessionDateTimeV2(date, abbreviated) {
  if (!date) {
    return '';
  }

  if (Number.isNaN(date.getTime())) {
    return '';
  }

  const dayOfTheWeek = daysOfTheWeek[date.getDay()];
  const dayOfTheMonth = date.getDate();
  const year = date.getFullYear();
  const month = months[date.getMonth()];

  if (abbreviated) {
    return `${dayOfTheWeek.substring(0, 3)}, ${month.substring(
      0,
      3,
    )} ${formatOrdinal(dayOfTheMonth)}, ${year} at ${formatSessionTime(date)}`;
  }
  return `${dayOfTheWeek}, ${month} ${formatOrdinal(
    dayOfTheMonth,
  )}, ${year} at ${formatSessionTime(date)}`;
}

export function formatSessionDateTimeV3(date, date2, abbreviated) {
  if (!date) {
    return '';
  }
  if (Number.isNaN(date.getTime())) {
    return '';
  }
  const dayOfTheWeek = daysOfTheWeek[date.getDay()];
  const dayOfTheMonth = date.getDate();
  const year = date.getFullYear();
  const month = months[date.getMonth()];

  if (abbreviated) {
    return `${dayOfTheWeek.substring(0, 3)}, ${month.substring(
      0,
      3,
    )} ${formatOrdinal(dayOfTheMonth)}, ${year} at ${formatSessionTime(
      date,
    ).substring(0, 8)} - ${formatSessionTime(date2)}`;
  }

  return `${dayOfTheWeek}, ${month} ${formatOrdinal(
    dayOfTheMonth,
  )}, ${year} at ${formatSessionTime(date)} - ${formatSessionTime(date2)}`;
}

export function formatSessionSummaryDateHeader(subject, date) {
  if (!date) {
    return '';
  }

  if (Number.isNaN(date.getTime())) {
    return '';
  }

  const dayOfTheWeek = daysOfTheWeek[date.getDay()];
  const dayOfTheMonth = date.getDate();
  const month = months[date.getMonth()];

  return `Summary of ${subject} lesson on ${dayOfTheWeek}, ${month} ${formatOrdinal(
    dayOfTheMonth,
  )}`;
}

/**
 * Gets the description for an unprocessed card in the completed sessions list.
 *
 * @param {Date} processingDate The date object representing the date and
 * time to process the session.
 * @param {string} fallbackName The name of the opposing user used in the
 * description.
 */
export function getStagedSessionCardDesc(processingDate, fallbackName) {
  if (!processingDate || Number.isNaN(processingDate.getTime())) {
    return `Please consult with ${fallbackName} before making edits to this session.`;
  }

  const minutes =
    processingDate.getMinutes() >= 10
      ? processingDate.getMinutes()
      : `0${processingDate.getMinutes()}`;
  const militaryHour = processingDate.getHours();

  let time;
  if (militaryHour === 12) {
    time = `${militaryHour}:${minutes} PM`;
  } else {
    time =
      militaryHour > 12
        ? `${militaryHour - 12}:${minutes} PM`
        : militaryHour === 0
          ? `${militaryHour + 12}:${minutes} AM`
          : `${militaryHour}:${minutes} AM`;
  }

  const currentTime = new Date();
  if (processingDate.getTime() < currentTime.getTime()) {
    return (
      'Session will be processed shortly, please email info@tutorfly.org if ' +
      'you need to edit or delete this session.'
    );
  }

  return (
    `This recently completed session can be edited or deleted until ${time}. Please ` +
    `consult with ${fallbackName} before making edits to this session.`
  );
}

/**
 * Gets the description for an in progress session that is displayed at the top of
 * the edit session dialog.
 */
export function getInProgressSessionDesc() {
  return (
    'This session is currently in progress. If this session ended early ' +
    'or did not take place at all, then you can proceed to edit the ' +
    '"end time" or to cancel the session altogether. To adjust the ' +
    '"start time," you need to wait until the end time of the session ' +
    'has been reached.'
  );
}

/**
 * Gets the description for an unprocessed session that is displayed at the top of
 * the edit session dialog.
 *
 * @param {Date} date The date object representing the date and
 * time to process the session.
 * @param {string} fallbackName The name of the opposing user used in the
 * description.
 */
export function getStagedSessionEditDesc(date, fallbackName) {
  if (!date || Number.isNaN(date.getTime())) {
    return `Please consult with ${fallbackName} before editing or deleting this completed session.`;
  }

  const minutes =
    date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;
  const militaryHour = date.getHours();

  let time;
  if (militaryHour === 12) {
    time = `${militaryHour}:${minutes} PM`;
  } else {
    time =
      militaryHour > 12
        ? `${militaryHour - 12}:${minutes} PM`
        : militaryHour === 0
          ? `${militaryHour + 12}:${minutes} AM`
          : `${militaryHour}:${minutes} AM`;
  }

  return (
    `The start and end times of this recently completed session can be edited until ${time}. ` +
    `Please consult with ${fallbackName} before making edits to this session, or before cancelling ` +
    'it retroactively.'
  );
}

export function formatSessionStarted(endDate, fallbackName) {
  if (Number.isNaN(endDate.getTime())) {
    return `Contact ${fallbackName} to confirm this session's the date and time.`;
  }

  const minutes =
    endDate.getMinutes() >= 10
      ? endDate.getMinutes()
      : `0${endDate.getMinutes()}`;

  const militaryTime = endDate.getHours();
  let time;
  if (militaryTime === 12) {
    time = `${militaryTime}:${minutes} PM`;
  } else {
    time =
      militaryTime > 12
        ? `${militaryTime - 12}:${minutes} PM`
        : `${militaryTime}:${minutes} AM`;
  }

  const currentTime = new Date();

  // If the end time is already past the end time for session processing, display
  // the following edge case statement
  if (currentTime.getTime() >= endDate.getTime()) {
    return 'Session is complete, and will be moved to the completed tab shortly.';
  }

  return (
    'Session is currently in progress, and can have all fields other than start time ' +
    `edited until it is completed at ${time}.`
  );
}

export function generateDateFromTime(date, plannedTime) {
  const safeDateFormat = date.replace(new RegExp('-', 'g'), '/');
  const finalDate = new Date(`${safeDateFormat} ${plannedTime}`);
  return finalDate;
}

export function getTimestampFromDateTime(date, time) {
  return generateDateFromTime(date, time).getTime();
}

// timeString is usually in the format of "11:00" or "16:00"
export function militaryTimeToStandard(timeString) {
  const timeArray = timeString.split(':');
  const hours = +timeArray[0];
  const minutes = +timeArray[1];

  let finalHours = hours;
  let suffix = 'AM';
  if (hours - 12 === 0) {
    // 12:00 PM
    suffix = 'PM';
  } else if (hours - 12 > 0) {
    // PM
    finalHours = hours - 12;
    suffix = 'PM';
  } else if (hours - 12 === -12) {
    // 12:00 AM
    finalHours = 12;
  }

  let finalMinutes = minutes;
  if (finalMinutes < 10) {
    finalMinutes = `0${minutes}`;
  }

  return `${finalHours}:${finalMinutes} ${suffix}`;
}

export function getCurrentFormattedTime() {
  const currentDate = new Date();
  return militaryTimeToStandard(
    currentDate
      .getHours()
      .toString()
      .concat(':')
      .concat(currentDate.getMinutes().toString()),
  );
}

const timeRegex = /(\d{1,2}):(\d{2}) *([ap]m)?/i;

export const formatTime24Hours = time => {
  const match = time.match(timeRegex);
  if (match) {
    const hour = match[1];
    const minute = match[2];
    const isAM = match[3] ? /am/i.test(match[3]) : false;
    const isPM = match[3] ? /pm/i.test(match[3]) : false;
    if (hour === '12') {
      return `${isAM ? '00' : '12'}:${minute}`;
    } else if (hour > 12) {
      return `${hour}:${minute}`;
    }
    return `${isPM ? parseInt(hour, 10) + 12 : hour}:${minute}`;
  }
  return time;
};
/* eslint-disable */

function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

function sameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

function getFormattedDate(date) {
  let year = date.getFullYear();
  year = year.toString().substr(-2);
  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}

/* eslint-disable */

export function formatMessageDate(dateArg) {
  const date = new Date(dateArg);
  const currentDate = new Date();
  if (sameDay(date, currentDate)) {
    return formatAMPM(date);
  }
  return getFormattedDate(date);
}

export function addMonths(date, numMonths) {
  return new Date(date.setMonth(date.getMonth() + numMonths));
}

/* Prints date in format January 1, 1970 */
export const formatHumanReadableDate = date => {
  if (date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  } else {
    return 'No date provided';
  }
};

export const getDateWithHourDiff = (date, hourDiff = 0) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + hourDiff);
  return newDate;
};

export const getDateWithMillisecondDiff = (date, millisecondDiff = 0) => {
  const newDate = new Date(date);
  newDate.setMilliseconds(newDate.getMilliseconds() + millisecondDiff);
  return newDate;
};

export const isDateAfterThreeMonthsV2 = date => {
  const currDate = new Date();
  const maxDateSelection = currDate.setMonth(currDate.getMonth() + 3);
  return date > maxDateSelection;
};

export const isStartTimeBeforeCurrentTime = (selectedDate, startTime) => {
  const dateToCheck = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate(),
    startTime.getHours(),
    startTime.getMinutes(),
    0,
    0,
  );
  const now = new Date();
  return dateToCheck < now;
};

export const isStartTimeBeforeEndTime = (plannedStartTime, plannedEndTime) => {
  const timeDiff = plannedEndTime - plannedStartTime;
  return timeDiff < 0;
};

export const validateSessionLengthV2 = (plannedStartTime, plannedEndTime) => {
  const diffInMinutes =
    (plannedEndTime - plannedStartTime) / MILLISECONDS_PER_MINUTE;
  const doubleCheckMessage =
    'Please double check your selected start and end times.';
  if (diffInMinutes > 7 * MINUTES_PER_HOUR) {
    return `${doubleCheckMessage} Sessions cannot be longer than 7 hours.`;
  } else if (diffInMinutes < 15) {
    return `${doubleCheckMessage} Sessions cannot be shorter than 15 minutes.`;
  }

  return false;
};

export const validateSessionLengthV3 = (sessionStartMoment, sessionEndMoment) => {
  const doubleCheckMessage =
    'Please double check your selected start and end times.';
  if (sessionEndMoment.diff(sessionStartMoment, 'hours') > 7) {
    return `${doubleCheckMessage} Sessions cannot be longer than 7 hours.`;
  } else if (sessionEndMoment.diff(sessionStartMoment, 'minutes') < 15) {
    return `${doubleCheckMessage} Sessions cannot be shorter than 15 minutes.`;
  }
  return false;
};

export const getYearMonthDayFormattedDate = date => {
  if (!(date instanceof Date)) {
    return '';
  }
  const month = `0${date.getMonth()+1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`
}

export const getNextWeekDates = start => {
  if (!(start instanceof Date)) {
    return [];
  }
  let day1 = new Date(), day2 = new Date(), day3 = new Date(), day4 = new Date(), day5 = new Date(), day6 = new Date();
  day1.setDate(start.getDate()+1)
  day2.setDate(start.getDate()+2)
  day3.setDate(start.getDate()+3)
  day4.setDate(start.getDate()+4)
  day5.setDate(start.getDate()+5)
  day6.setDate(start.getDate()+6)
  const dates = [
    getYearMonthDayFormattedDate(start),
    getYearMonthDayFormattedDate(day1),
    getYearMonthDayFormattedDate(day2),
    getYearMonthDayFormattedDate(day3),
    getYearMonthDayFormattedDate(day4),
    getYearMonthDayFormattedDate(day5),
    getYearMonthDayFormattedDate(day6)
  ]
  return dates
}
