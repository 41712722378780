/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';

const abbrs = {
  EST: 'Eastern Standard Time',
  EDT: 'Eastern Daylight Time',
  CST: 'Central Standard Time',
  CDT: 'Central Daylight Time',
  MST: 'Mountain Standard Time',
  MDT: 'Mountain Daylight Time',
  PST: 'Pacific Standard Time',
  PDT: 'Pacific Daylight Time',
  AKST: 'Alaska Standard Time',
  AKDT: 'Alaska Daylight Time',
  HST: 'Honolulu Standard Time',
  HDT: 'Honolulu Daylight Time',
  PHST: 'Phoenix Standard Time',
  PHDT: 'Phoenix Daylight Time',
};

moment.fn.zoneName = function() {
  const abbr = this.zoneAbbr();
  return abbrs[abbr] || abbr;
};

let formattedTimeZoneOptions = [];

export const getFormattedTimeInputWithId = zoneId => {
  const now = new Date().getTime();
  let zoneAbbreviation = moment.tz(now, zoneId).format('zz');
  const re = /_/gi;
  const re2 = /\//gi;
  const formattedName = zoneId.replace(re, ' ').replace(re2, '-');
  if (formattedName === 'America-Phoenix') {
    zoneAbbreviation = 'Phoenix Standard Time';
  }
  return `${formattedName} — ${zoneAbbreviation}`;
};

export const getFormattedTimeInput = option => {
  if (option) {
    return `${option.name} — ${option.zoneAbbrev}`;
  }
  return '';
};

export const getFormattedTimeZoneOptions = () => {
  if (formattedTimeZoneOptions.length === 0) {
    formattedTimeZoneOptions = moment.tz.zonesForCountry('US').map(zoneId => {
      const now = new Date().getTime();
      let zoneAbbreviation = moment.tz(now, zoneId).format('zz');
      const re = /_/gi;
      const re2 = /\//gi;
      const formattedName = zoneId.replace(re, ' ').replace(re2, '-');
      if (formattedName === 'America-Phoenix') {
        zoneAbbreviation = 'Phoenix Standard Time';
      }
      return {
        name: formattedName,
        id: zoneId,
        zoneAbbrev: zoneAbbreviation,
      };
    });
  }
  return formattedTimeZoneOptions;
};

export const getCurrTimeZone = () => moment.tz.guess(true);

export const isValidTimeZone = timeZoneId =>
  moment.tz.zonesForCountry('US').indexOf(timeZoneId) > -1;

export const getSimplifiedTimeZone = timeZoneId => {
  const now = new Date().getTime();
  if (!isValidTimeZone(timeZoneId)) {
    const timeZoneIdGuess = moment.tz.guess();
    return moment.tz(now, timeZoneIdGuess).format('zz');
  }
  let zoneAbbreviation = moment.tz(now, timeZoneId).format('zz');
  if (timeZoneId === 'America/Phoenix') {
    zoneAbbreviation = 'Phoenix Standard Time';
  }

  if (
    zoneAbbreviation === 'Eastern Standard Time' ||
    zoneAbbreviation === 'Eastern Daylight Time'
  ) {
    return 'Eastern Time';
  } else if (
    zoneAbbreviation === 'Central Standard Time' ||
    zoneAbbreviation === 'Central Daylight Time'
  ) {
    return 'Central Time';
  } else if (
    zoneAbbreviation === 'Mountain Standard Time' ||
    zoneAbbreviation === 'Mountain Daylight Time'
  ) {
    return 'Mountain Time';
  } else if (
    zoneAbbreviation === 'Pacific Standard Time' ||
    zoneAbbreviation === 'Pacific Daylight Time'
  ) {
    return 'Pacific Time';
  } else if (
    zoneAbbreviation === 'Alaska Standard Time' ||
    zoneAbbreviation === 'Alaska Daylight Time'
  ) {
    return 'Alaska Time';
  } else if (
    zoneAbbreviation === 'Honolulu Standard Time' ||
    zoneAbbreviation === 'Honolulu Daylight Time'
  ) {
    return 'Honolulu Time';
  } else if (
    zoneAbbreviation === 'Phoenix Standard Time' ||
    zoneAbbreviation === 'Phoenix Daylight Time'
  ) {
    return 'Phoenix Time';
  }
  return zoneAbbreviation;
};

export const getSimplifiedTimeZoneOptions = () => [
  'Eastern Time',
  'Central Time',
  'Mountain Time',
  'Pacific Time',
  'Alaska Time',
  'Honolulu Time',
  'Phoenix Time',
];

export const getTimeZoneIdFromSimplifiedTimeZone = simplifiedTimeZone => {
  if (simplifiedTimeZone === 'Pacific Time') {
    return 'America/Los_Angeles';
  } else if (simplifiedTimeZone === 'Mountain Time') {
    return 'America/Denver';
  } else if (simplifiedTimeZone === 'Central Time') {
    return 'America/Chicago';
  } else if (simplifiedTimeZone === 'Eastern Time') {
    return 'America/New_York';
  } else if (simplifiedTimeZone === 'Honolulu Time') {
    return 'Pacific/Honolulu';
  } else if (simplifiedTimeZone === 'Alaska Time') {
    return 'America/Anchorage';
  } else if (simplifiedTimeZone === 'Phoenix time') {
    return 'America/Phoenix';
  }
  return moment.tz.guess();
};
