import {
  SET_UPCOMING_SESSIONS,
  SET_UPCOMING_DROP_IN_SESSIONS,
  SET_SESSIONS_LOADING,
  SET_DROP_IN_SESSIONS_LOADING,
  SET_SESSIONS_FETCH_ERROR,
  SET_DROP_IN_SESSIONS_FETCH_ERROR,
  ADD_MORE_UPCOMING_SESSIONS,
  SET_FETCH_MORE_LOADING,
  SET_SESSIONS_PAGE_NUM,
  SET_HAS_MORE,
} from '../types';

const INITIAL_STATE = {
  isLoading: false,
  dropInIsLoading: false,
  fetchError: null,
  dropInFetchError: null,
  upcomingSessions: [],
  upcomingDropInSessions: [],
  upcomingSessionsByPerson: [],
  hasMore: true,
  isLoadingFetchMore: false,
  totalSessions: 0,
  pageNum: 1,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_UPCOMING_SESSIONS:
      return {
        ...state,
        isLoading: false,
        fetchError: false,
        upcomingSessions: action.payload.upcomingSessions,
        upcomingSessionsByPerson: action.payload.upcomingSessionsByPerson,
        hasMore: action.payload.hasMore,
        totalSessions: action.payload.totalSessions,
      };

    case ADD_MORE_UPCOMING_SESSIONS:
      return {
        ...state,
        isLoadingFetchMore: false,
        fetchError: false,
        upcomingSessions: [
          ...state.upcomingSessions,
          ...action.payload.upcomingSessions,
        ],
        upcomingSessionsByPerson: action.payload.upcomingSessionsByPerson,
        hasMore: action.payload.hasMore,
      };

    case SET_UPCOMING_DROP_IN_SESSIONS:
      return {
        ...state,
        dropInIsLoading: false,
        dropInFetchError: false,
        upcomingDropInSessions: action.payload.upcomingDropInSessions,
      };

    case SET_SESSIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };

    case SET_FETCH_MORE_LOADING:
      return {
        ...state,
        isLoadingFetchMore: action.payload.isLoadingFetchMore,
      };
    case SET_HAS_MORE:
      return {
        ...state,
        hasMore: action.payload.hasMore,
      };

    case SET_SESSIONS_PAGE_NUM:
      return {
        ...state,
        pageNum: action.payload.pageNum,
      };

    case SET_DROP_IN_SESSIONS_LOADING:
      return {
        ...state,
        dropInIsLoading: action.payload.dropInIsLoading,
      };

    case SET_SESSIONS_FETCH_ERROR:
      return {
        ...state,
        fetchError: action.payload.fetchError,
      };

    case SET_DROP_IN_SESSIONS_FETCH_ERROR:
      return {
        ...state,
        dropInFetchError: action.payload.dropInFetchError,
      };

    default:
      return state;
  }
};
