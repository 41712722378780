import {
  SET_CONVERSATION_OBJECT,
  SET_TUTORING_REQUEST,
  SET_FETCHING_CONVERSATION,
  SET_FETCHING_MESSAGES,
  SET_PAIR_FOR_CONVERSATION,
  FETCH_CONVERSATION_ERROR,
  FETCH_MESSAGES_ERROR,
  RESET_CONVERSATION_OBJECT,
  GET_MESSAGES_FIELDS,
  SET_MESSAGES,
  SEND_NEW_MESSAGE,
  LOAD_MORE_MESSAGES,
  SET_TAB_INDEX,
} from '../types';

const INITIAL_STATE = {
  conversation: null,

  // Data useful for rendering the profile dashboard when
  // the users are paired
  pair: null,

  // Data useful for rendering the profile dashboard for a
  // conversation with a pending tutoring request
  tutoringRequest: null,
  isRequester: false,

  // Store messages information to render on the dashboard
  messages: [],
  hasMoreMessages: false,

  // Flags for letting us know when we are in the process
  // of fetching data
  fetchingConversation: false,
  fetchingMessages: false,

  // Error state flags for fetching messages or conversations
  conversationFetchError: false,
  messagesFetchError: false,

  // The page size of messages we should fetch each time
  limit: 20,

  // The tab index for the dashboard
  tabIndex: 0,

  // Deprecated: Flag being used by the old messages
  // container
  isError: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONVERSATION_OBJECT:
      return {
        ...state,
        fetchingConversation: false,
        conversation: action.payload.conversation,
      };

    case SET_FETCHING_CONVERSATION:
      return {
        ...state,
        fetchingConversation: true,
      };

    case SET_FETCHING_MESSAGES:
      return {
        ...state,
        fetchingMessages: action.payload.fetchingMessages,
      };

    case SET_PAIR_FOR_CONVERSATION:
      return {
        ...state,
        pair: action.payload.pair,
      };

    case SET_TUTORING_REQUEST:
      return {
        ...state,
        fetchingConversation: false,
        tutoringRequest: action.payload.tutoringRequest,
      };

    case FETCH_CONVERSATION_ERROR:
      return {
        ...state,
        conversation: null,
        tutoringRequest: null,
        fetchingConversation: false,
        conversationFetchError: true,
      };

    case FETCH_MESSAGES_ERROR:
      return {
        ...state,
        fetchingMessages: false,
        messagesFetchError: true,
      };

    case SET_MESSAGES:
      return {
        ...state,
        messages: action.payload.messages,
        hasMoreMessages: action.payload.hasMoreMessages,
        fetchingMessages: false,
      };

    case GET_MESSAGES_FIELDS:
      return {
        ...state,
        messages: action.payload.messages,
        hasMoreMessages: action.payload.hasMoreMessages,
        fetchingMessages: false,
      };

    case SEND_NEW_MESSAGE:
      return {
        ...state,
        messages: state.messages.concat(action.payload.newMessage),
        // messages: [...state.messages, action.payload.newMessage],
        limit: action.payload.limit,
      };

    case LOAD_MORE_MESSAGES:
      return {
        ...state,
        messages: action.payload.messages,
        hasMoreMessages: action.payload.hasMoreMessages,
        fetchingMessages: action.payload.fetchingMessages,
        limit: action.payload.limit,
      };

    case SET_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.payload.tabIndex,
      };

    case RESET_CONVERSATION_OBJECT:
      return INITIAL_STATE;

    default:
      return state;
  }
};
