import phone from 'phone';
import { BROWSER_MAP } from 'bowser';
import {
  ProfileType,
  highSchoolYearOptions,
  collegeYearOptions,
  NULL_COORDINATE,
  STRIPE_CONNECT_OAUTH_CLIENT_ID,
  WEB_CLIENT_URL,
  SessionParticipantType,
  LanguageLocaleConstants,
} from 'shared/constants/index';
import { constructDate, validateDateString, addMonths } from './dateUtils';

/**
|--------------------------------------------------
| General Utility Functions.                      |
|--------------------------------------------------
*/

export const isProd = () => !__DEV__;

export const bootstrapValidation = bool => (bool ? null : 'error');

export const arrayRemove = (array, value) => {
  const index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
  }
};

export const formatPhoneNumber = phoneNumberString => {
  const match = phoneNumberString.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};
// Formats a date in the form
// mm/dd/yyyy
export const formatDateShort = date => {
  if (!date) {
    return '';
  }
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedMonth = `0${month}`.substr(-2);
  const formattedDay = `0${day}`.substr(-2);
  return `${formattedMonth}/${formattedDay}/${year}`;
};

// Formats a date in the form
// mm/dd/yyyy hh:mm [A.M./P.M.]
export const formatDateLong = date => {
  if (!date) {
    return '';
  }
  let hours = date.getHours();
  let indicator;
  if (hours > 12) {
    hours -= 12;
    indicator = 'P.M.';
  } else {
    indicator = 'A.M.';
    if (hours === 0) {
      hours = 12;
    }
  }
  const minutes = date.getMinutes();
  const formattedMinutes = `0${minutes}`.substr(-2);
  return `${formatDateShort(date)} ${hours}:${formattedMinutes} ${indicator}`;
};

export const isNameFieldValid = name => {
  if (name) {
    return name.length > 0;
  }
  return false;
};

export const isPasswordValid = password => {
  if (password) {
    return password.length > 7;
  }
  return false;
};

export const checkValidEmail = email => {
  if (email) {
    return /(.+)@(.+){2,}\.(.+){2,}/.test(email);
  }
  return false;
};

export const isPhoneValid = phoneNumber => {
  const checkPhoneNumber = phone(phoneNumber, 'USA')[0];
  if (!checkPhoneNumber) {
    return false;
  }
  return true;
};

export const isZipcodeValid = zipcode => {
  if (zipcode) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipcode.id) && zipcode.city;
  }
  return false;
};

function getDateComponents(date) {
  const dateSections = date.split('/');
  const month = Number(dateSections[0]);
  const day = Number(dateSections[1]);
  const year = Number(dateSections[2]);
  return { year, month, day };
}

export const isValidDob = birthday => {
  if (birthday) {
    const formatMatch = /^\d{2}\/\d{2}\/\d{4}$/.test(birthday);
    if (!formatMatch) {
      return false;
    }

    const { year, month, day } = getDateComponents(birthday);
    const date = new Date(year, month - 1, day);

    return (
      date.getFullYear() === year &&
      date.getMonth() + 1 === month &&
      date.getDate() === day
    );
  }
  return false;
};

export const removeNonNumbers = s => {
  if (!s) {
    return '';
  }
  return s.replace(/\D+/g, '');
};

export const getMonths = () =>
  'January|February|March|April|May|June|July|August|September|October|November|December'.split(
    '|',
  );

// creates a list of numbers with the specified
// beginning and end values
export const createList = (start, end) => {
  const list = [];
  if (start < end) {
    for (let i = start; i <= end; i += 1) {
      list.push(i);
    }
  } else {
    for (let i = start; i >= end; i -= 1) {
      list.push(i);
    }
  }
  return list;
};

/**
 * Return true if the tutor is at least 14 years old
 * and no older than 27 years old
 * @param {string} birthday
 */
export const dobInTutoringRange = birthday => {
  const { year, month, day } = getDateComponents(birthday);
  const dobDate = new Date(year, month - 1, day);

  const earliestDob = new Date();
  earliestDob.setFullYear(earliestDob.getFullYear() - 14);

  return dobDate < earliestDob;
};

export const isTutorMinor = (month, day, year) => {
  const dobDate = new Date(year, month - 1, day);
  const isMinorDob = new Date();
  isMinorDob.setFullYear(isMinorDob.getFullYear() - 18);
  return dobDate > isMinorDob;
};

export const isValidGpa = gpa => {
  if (gpa) {
    return /^\d.(\d|\d{2})$/.test(gpa);
  }
  return false;
};

export const isGpaInValidRange = (gpa, isHighSchool) => {
  if (gpa) {
    const gpaVal = parseFloat(gpa);

    if (gpaVal > 5.0) {
      return false;
    }

    if (isHighSchool) {
      return gpaVal >= 3.5;
    }
    return gpaVal >= 3.3;
  }
  return false;
};

export const isNotEmpty = str => {
  if (str) {
    return str.trim().length > 0;
  }
  return false;
};

export const isEmpty = str => !isNotEmpty(str);

export const displayHoursTutored = hoursTutored => {
  const hoursTutoredFloat = parseFloat(hoursTutored);
  if (!Number.isNaN(hoursTutoredFloat)) {
    if (hoursTutoredFloat > 0 && hoursTutoredFloat < 1) {
      return 1;
    }
    const displayHours = Math.round(hoursTutoredFloat);
    return displayHours;
  }
  return 0;
};

export const displayGPA = gpa => {
  const gpaFloat = parseFloat(gpa);
  if (Number.isNaN(gpaFloat)) {
    return '3.50+';
  }
  return gpaFloat.toFixed(2);
};

export const isSchoolInfoValid = (
  school,
  tutorGradeLevel,
  gpa,
  isHighSchoolTutor,
) => {
  let schoolNameValid = false;
  let schoolIdValid = false;

  if (school) {
    schoolNameValid = isNotEmpty(school.name);
    schoolIdValid = isNotEmpty(school.id);
  }

  const tutorSchoolValid = isNotEmpty(school.name);
  const tutorGadeLevelValid = isNotEmpty(tutorGradeLevel);
  const gpaValid = isValidGpa(gpa);
  const gpaInValidRange = isGpaInValidRange(gpa, isHighSchoolTutor);

  return (
    tutorSchoolValid &&
    schoolNameValid &&
    schoolIdValid &&
    tutorGadeLevelValid &&
    gpaValid &&
    gpaInValidRange
  );
};

// eslint-disable-next-line arrow-body-style
export const isValidClientType = clientType => {
  return (
    clientType === 'PARENT' ||
    clientType === 'STUDENT' ||
    clientType === 'OTHER'
  );
};

export const capitalizeFirstLetter = word => {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
  return '';
};

export const lowerCaseWord = word => {
  if (word) {
    return word.toLowerCase();
  }
  return '';
};

export const hasProfilePicture = profilePicture => {
  if (!profilePicture) {
    return false;
  } else if (profilePicture === {}) {
    return false;
  } else if (!profilePicture.src) {
    return false;
  } else if (profilePicture.src === '/images/user.jpg') {
    return false;
  }
  return true;
};

export const getDayOfWeek = date => {
  const dateToCheck = new Date(date);
  if (dateToCheck) {
    const dayOfWeek = dateToCheck.getDay();
    switch (dayOfWeek) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return '';
    }
  }
  return '';
};

export const formatGPAInput = input => {
  const formatted = input.replace(/[^\d.-]/g, '');
  const gpaDecimalCutoff = formatted.substring(0, 4);
  return gpaDecimalCutoff;
};

export const truncateSigFig = (value, places) => {
  if (value && places) {
    return value.toFixed(places);
  }

  return 0;
};

export const generateDefaultTutorBio = profile => {
  const { firstName, gradeLevel, school } = profile;

  let gradeString;
  if (Number.isNaN(gradeLevel)) {
    gradeString = `a ${gradeLevel}`;
  } else {
    gradeString = `in ${gradeLevel}th grade`;
  }

  return firstName && gradeLevel && school
    ? `Hello, my name is ${firstName}. I'm currently ${gradeString} at ${school} and I'm a Tutorfly Tutor. Feel free to contact me here so we can chat and hopefully set up a session in the near future!`
    : '(This user has not written a bio yet.)';
};

export const getAcronym = inputString => {
  const firstLetters = inputString.match(/\b(\w)/g);
  const acronym = firstLetters.join('');
  return acronym;
};

// TUTOR AGREEMENT VALIDATOR

export const tutorHasSignedAgreement = (currentUser, userType) => {
  if (userType === ProfileType.STUDENT_OR_PARENT) {
    return true;
  }
  if (currentUser === null || currentUser === undefined) {
    return true;
  }
  const { hasSignedAgreement, profileTypes } = currentUser.profile;
  const tutorSigned =
    hasSignedAgreement && profileTypes.includes(ProfileType.TUTOR);
  return tutorSigned;
};

// SESSION VALIDATOR UTIL FUNCTIONS

export const isDateSyntaxInvalid = date => !validateDateString(date);

export const isDateBeforeCurrent = date => {
  if (!validateDateString(date)) {
    return true;
  }
  const scheduledDate = constructDate(date, null);
  const currentDate = new Date();
  currentDate.setMilliseconds(0);
  currentDate.setSeconds(0);
  currentDate.setMinutes(0);
  currentDate.setHours(0);
  return !(!!date && scheduledDate.getTime() >= currentDate.getTime());
};

export const isDateAfterThreeMonths = date => {
  if (!validateDateString(date)) {
    return true;
  }
  const scheduledDate = constructDate(date, null);
  const currentDate = new Date();
  const threeMonths = addMonths(currentDate, 3);
  return !(scheduledDate.getTime() <= threeMonths.getTime());
};

// date for session should look like 08/19/2019
export const isChosenDateInvalid = (date, plannedStartTime) => {
  if (!validateDateString(date)) {
    return 'Please double check the inputted date. The correct date format is mm/dd/yyyy.';
  }
  const scheduledDate = constructDate(date, plannedStartTime);
  const currentDate = new Date();
  currentDate.setMilliseconds(0);
  currentDate.setSeconds(0);
  currentDate.setMinutes(0);
  currentDate.setHours(0);
  const isDateAfterCurrent =
    !!date && scheduledDate.getTime() >= currentDate.getTime();
  if (!isDateAfterCurrent) {
    return 'Please double check the inputted date. You cannot schedule a session in the past.';
  }

  return false;
};

export const isValidTimeInput = time => {
  let mutableStartTime = time;
  if (mutableStartTime) {
    mutableStartTime = mutableStartTime.replace(/ /g, '');
  }

  const militaryRegex = /^([01]\d|2[0-3]):?([0-5]\d)$/i;
  const amPmRegex = /^(1[0-2]|[1-9]):[0-5][0-9][ap]m$/i;

  const militaryTimeTest = militaryRegex.test(mutableStartTime);
  const amPmTimeTest = amPmRegex.test(mutableStartTime);

  return militaryTimeTest || amPmTimeTest;
};

export const validateTimeBeforeCurrent = (date, plannedTime) => {
  let mutableTime = plannedTime;
  let isValid = false;
  if (mutableTime) {
    mutableTime = mutableTime.replace(/ /g, '');
  }

  const militaryRegex = /^([01]\d|2[0-3]):?([0-5]\d)$/i;
  const amPmRegex = /^(1[0-2]|[1-9]):[0-5][0-9][ap]m$/i;

  const militaryTimeTest = militaryRegex.test(mutableTime);
  const amPmTimeTest = amPmRegex.test(mutableTime);

  if ((amPmTimeTest || militaryTimeTest) && mutableTime) {
    const scheduledDate = constructDate(date, mutableTime); // add T to support Safari (front-end only)
    const currentDate = new Date();
    const dateIsToday =
      scheduledDate.getFullYear() === currentDate.getFullYear() &&
      scheduledDate.getMonth() === currentDate.getMonth() &&
      scheduledDate.getDate() === currentDate.getDate();
    isValid = dateIsToday ? scheduledDate > currentDate : true;
  }
  return isValid;
};

export const validateStartTimeBeforeEndTime = (
  plannedStartTime,
  plannedEndTime,
) => {
  let validTime = false;
  let mutableStartTime = plannedStartTime;
  let mutableEndTime = plannedEndTime;

  if (mutableStartTime && mutableEndTime) {
    mutableEndTime = mutableEndTime.replace(/ /g, '');
    mutableStartTime = mutableStartTime.replace(/ /g, '');
  }

  const militaryRegex = /^([01]\d|2[0-3]):?([0-5]\d)$/i;
  const amPmRegex = /^(1[0-2]|[1-9]):[0-5][0-9][ap]m$/i;

  if (
    militaryRegex.test(mutableStartTime) &&
    militaryRegex.test(mutableEndTime)
  ) {
    const startData = mutableStartTime.split(':');
    const startHour = parseInt(startData[0], 10);
    const startMinute = parseInt(startData[1], 10);
    const endData = mutableEndTime.split(':');
    const endHour = parseInt(endData[0], 10);
    const endMinute = parseInt(endData[1], 10);

    validTime =
      startHour === endHour ? startMinute < endMinute : startHour < endHour;
    if (!validTime) {
      return 'Please double check the inputted start time. You cannot schedule a session in the past.';
    }
  } else if (
    amPmRegex.test(mutableStartTime) &&
    amPmRegex.test(mutableEndTime)
  ) {
    const startData = mutableStartTime.split(':');
    let startHour = parseInt(startData[0], 10);
    const startMinute = parseInt(startData[1], 10);
    const endData = mutableEndTime.split(':');
    let endHour = parseInt(endData[0], 10);
    const endMinute = parseInt(endData[1], 10);

    if (mutableStartTime.toLowerCase().indexOf('p') > -1 && startHour !== 12) {
      startHour += 12;
    } else if (
      mutableStartTime.toLowerCase().indexOf('a') > -1 &&
      startHour === 12
    ) {
      startHour -= 12;
    }
    if (mutableEndTime.toLowerCase().indexOf('p') > -1 && endHour !== 12) {
      endHour += 12;
    } else if (
      mutableEndTime.toLowerCase().indexOf('a') > -1 &&
      endHour === 12
    ) {
      endHour -= 12;
    }

    validTime =
      startHour === endHour ? startMinute < endMinute : startHour < endHour;
    if (!validTime) {
      return 'Please double check the inputted end time. The end time cannot be before the start time.';
    }
  } else {
    return 'Please re-enter the start or end time in the proper format. Examples are "4:00 PM" and "11:00 AM"';
  }
  return false;
};

export const validateSessionLength = (plannedStartTime, plannedEndTime) => {
  let mutableStartTime = plannedStartTime;
  let mutableEndTime = plannedEndTime;

  if (mutableStartTime && mutableEndTime) {
    mutableEndTime = mutableEndTime.replace(/ /g, '');
    mutableStartTime = mutableStartTime.replace(/ /g, '');
  }

  const militaryRegex = /^([01]\d|2[0-3]):?([0-5]\d)$/i;
  const amPmRegex = /^(1[0-2]|[1-9]):[0-5][0-9][ap]m$/i;

  if (
    militaryRegex.test(mutableStartTime) &&
    militaryRegex.test(mutableEndTime)
  ) {
    const startData = mutableStartTime.split(':');
    const startHour = parseInt(startData[0], 10);
    const startMinute = parseInt(startData[1], 10);
    const endData = mutableEndTime.split(':');
    const endHour = parseInt(endData[0], 10);
    const endMinute = parseInt(endData[1], 10);

    const hourDiff = endHour - startHour;
    const minuteDiff = endMinute - startMinute;

    const doubleCheckMessage =
      'Please double check the specified session duration as ' +
      'seen from your inputted start and end times.';
    if (hourDiff > 7) {
      return `${doubleCheckMessage} Sessions cannot be longer than 7 hours.`;
    } else if (hourDiff === 7 && minuteDiff > 0) {
      return `${doubleCheckMessage} Sessions cannot be longer than 7 hours.`;
    } else if (hourDiff === 0 && minuteDiff < 15) {
      return `${doubleCheckMessage} Sessions cannot be shorter than 15 minutes.`;
    } else if (hourDiff === 1 && endMinute + 60 - startMinute < 15) {
      return `${doubleCheckMessage} Sessions cannot be shorter than 15 minutes.`;
    }
    return false;
  } else if (
    amPmRegex.test(mutableStartTime) &&
    amPmRegex.test(mutableEndTime)
  ) {
    const startData = mutableStartTime.split(':');
    let startHour = parseInt(startData[0], 10);
    const startMinute = parseInt(startData[1], 10);
    const endData = mutableEndTime.split(':');
    let endHour = parseInt(endData[0], 10);
    const endMinute = parseInt(endData[1], 10);

    if (mutableStartTime.toLowerCase().indexOf('p') > -1 && startHour !== 12) {
      startHour += 12;
    } else if (
      mutableStartTime.toLowerCase().indexOf('a') > -1 &&
      startHour === 12
    ) {
      startHour -= 12;
    }
    if (mutableEndTime.toLowerCase().indexOf('p') > -1 && endHour !== 12) {
      endHour += 12;
    } else if (
      mutableEndTime.toLowerCase().indexOf('a') > -1 &&
      endHour === 12
    ) {
      endHour -= 12;
    }

    const hourDiff = endHour - startHour;
    const minuteDiff = endMinute - startMinute;

    const doubleCheckMessage =
      'Please double check the specified session duration as ' +
      'seen from your inputted start and end times.';
    if (hourDiff > 7) {
      return `${doubleCheckMessage} Sessions cannot be longer than 7 hours.`;
    } else if (hourDiff === 7 && minuteDiff > 0) {
      return `${doubleCheckMessage} Sessions cannot be longer than 7 hours.`;
    } else if (hourDiff === 0 && minuteDiff < 15) {
      return `${doubleCheckMessage} Sessions cannot be shorter than 15 minutes.`;
    } else if (hourDiff === 1 && endMinute + 60 - startMinute < 15) {
      return `${doubleCheckMessage} Sessions cannot be shorter than 15 minutes.`;
    }
    return false;
  }
  return (
    'Please re-enter the start or end time in the proper format. ' +
    'Examples are "4:00 PM" and "11:00 AM"'
  );
};

export const formatRecurringDays = recurringDays => {
  const formattedRecurringDays = recurringDays.map(day => {
    if (day === 0) {
      return 'Sunday';
    }
    if (day === 1) {
      return 'Monday';
    }
    if (day === 2) {
      return 'Tuesday';
    }
    if (day === 3) {
      return 'Wednesday';
    }
    if (day === 4) {
      return 'Thursday';
    }
    if (day === 5) {
      return 'Friday';
    }
    if (day === 6) {
      return 'Saturday';
    }
    return '';
  });
  return formattedRecurringDays;
};

export const limitSize = zipInput => {
  const filteredInput = zipInput.replace(/\D/g, '');
  return filteredInput.length <= 5
    ? filteredInput
    : filteredInput.substring(0, 5);
};

export function calculateSessionLength(timeBegan, timeEnded) {
  const timeBeganParts = timeBegan.split(':');
  const timeEndedParts = timeEnded.split(':');
  const timeEndedMinutes =
    Number(timeEndedParts[0]) * 60 + Number(timeEndedParts[1]);
  const timeBeganMinutes =
    Number(timeBeganParts[0]) * 60 + Number(timeBeganParts[1]);
  let sessionLengthInMinutes = timeEndedMinutes - timeBeganMinutes;

  // in the case that there's some weird session from like 11:00 PM to 2:00 AM
  if (sessionLengthInMinutes < 0) {
    sessionLengthInMinutes = timeEndedMinutes + 1440 - timeBeganMinutes;
  }
  return sessionLengthInMinutes;
}

/**
 * Takes to string arrays and merges them in a way that
 * removes any duplicate elements in both arrays.
 *
 * @param {[String]} arr1 An array of strings
 * @param {[String]} arr2 An array of strings
 */
export const deduplicateArrays = (arr1, arr2) => {
  if (!arr1 || !arr2) {
    return [];
  }
  const merged = arr1.concat(arr2);
  const mergedSet = new Set(merged);
  return [...mergedSet];
};

export const formattedGradeLevel = gradeLevel => {
  if (!gradeLevel) {
    return '';
  }

  if (
    gradeLevel === '9' ||
    gradeLevel === '10' ||
    gradeLevel === '11' ||
    gradeLevel === '12'
  ) {
    return gradeLevel.concat('th grade');
  } else if (gradeLevel.length > 0) {
    return gradeLevel;
  }
  return 'Grade Unavailable';
};

export const formatRequestSubjectDescription = subject => {
  if (isNotEmpty(subject)) {
    return subject;
  }
  return 'No subject provided';
};

export const formatPayoutOption = payoutOption => {
  if (payoutOption === 'paypal') {
    return 'PayPal';
  } else if (payoutOption === 'venmo') {
    return 'Venmo';
  }
  return '';
};

export const formatLocationDescription = (city, state, zip) => {
  if (isEmpty(city) && isEmpty(state) && isEmpty(zip)) {
    return 'No location preference provided';
  }

  let locationDescription = '';
  if (isNotEmpty(city)) {
    locationDescription += `${city}, `;
  }
  if (isNotEmpty(state)) {
    locationDescription += `${state}, `;
  }
  if (isNotEmpty(zip)) {
    locationDescription += `${zip}`;
  }

  return locationDescription;
};

export const formatSessionPreference = preference => {
  if (preference === 'no-preference') {
    return 'In-person or online';
  } else if (preference === 'in-person') {
    return 'In-person';
  } else if (preference === 'online') {
    return 'Online';
  }
  return 'No preference set for In-person or online sessions';
};

export const isValidSessionType = type => {
  if (type === 'no-preference') {
    return true;
  } else if (type === 'in-person') {
    return true;
  } else if (type === 'online') {
    return true;
  }
  return false;
};

export const getZipObject = (id, city, state) => {
  if (isNotEmpty(id) && isNotEmpty(city) && isNotEmpty(state)) {
    return {
      id,
      city,
      state,
    };
  }
  return {};
};

export const formatDailyAvailability = days => {
  if (days) {
    if (days.length > 0) {
      // let dailyDescription = '';
      const dailyDescription = days.map(day => `${day}s`);
      return dailyDescription.join(', ');
    }
  }
  return 'No daily preference for tutoring sessions provided';
};

export const formatAvailabilityDetails = availabilityDetails => {
  if (isNotEmpty(availabilityDetails)) {
    return availabilityDetails;
  }
  return 'No availability detail description was provided';
};

export const formatGradeLevelDescription = grdLvl => {
  if (isNotEmpty(grdLvl)) {
    if (grdLvl === 'middleSchool') {
      return 'Middle School';
    } else if (grdLvl === 'elementary') {
      return 'Elementary';
    } else if (grdLvl === 'highSchool') {
      return 'High School';
    } else if (grdLvl === 'college') {
      return 'College';
    }
    return grdLvl;
  }
  return 'No grade level provided';
};

export function formatNthNumber(num) {
  if (num === 1) {
    return `${1}st`;
  } else if (num === 2) {
    return `${2}nd`;
  } else if (num === 3) {
    return `${3}rd`;
  }
  return `${num}th`;
}

export const formatGradeLevelFromNumber = gradeNumber => {
  if (gradeNumber && !Number.isNaN(gradeNumber)) {
    return `${formatNthNumber(Number(gradeNumber))} Grade`;
  }
  return '';
};

export const sortDaysOfTheWeek = daysOfTheWeek => {
  const days = Object.assign([], daysOfTheWeek);
  const values = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  days.sort((a, b) => values[a.toLowerCase()] - values[b.toLowerCase()]);

  return days;
};

export const getClientInviteLink = profileId =>
  `${WEB_CLIENT_URL}/c/${profileId}`;

export const getTutorReferClientInviteLink = profileId =>
  `${WEB_CLIENT_URL}/trc/${profileId}`;

export const getTutorReferTutorInviteLink = profileId =>
  `${WEB_CLIENT_URL}/trt/${profileId}`;

export const getClientShareMessage = () =>
  `We'd like to share our great experience with Tutorfly Tutors! Use the link below and get a free credit of $20 towards your student's first tutoring session.`;

export const getClientLinkWithMessage = profileId => {
  const message = `We'd like to share our great experience with Tutorfly Tutors! Use the link below and get a free credit of $20 towards your student's first tutoring session.\n${`${WEB_CLIENT_URL}/c/${profileId}`}`;

  return message;
};

export const isChrome = browserName => browserName === BROWSER_MAP.chrome;

export const isIE = browserName => browserName === BROWSER_MAP.ie;

export const isEdge = browserName => browserName === BROWSER_MAP.edge;

export const isSafari = browserName => browserName === BROWSER_MAP.safari;

export const formatTutorflyCredit = bundleAmount => {
  if (bundleAmount > 0) {
    const formattedAmount = parseFloat(bundleAmount).toFixed(2);
    const dollarCents = formattedAmount.split('.');
    if (dollarCents[1] === '00') {
      return parseInt(formattedAmount, 10);
    }
    return formattedAmount;
  }
  return 0;
};

export const formatZipCodeHelperText = zipCode => {
  if (zipCode) {
    const zipCityState =
      isNotEmpty(zipCode.city) && isNotEmpty(zipCode.state)
        ? `${zipCode.city}, ${zipCode.state}`
        : 'Please enter a ZIP code';
    return zipCityState;
  }
  return 'Please enter a ZIP code';
};

export const getOauthFailureRedirect = (context, profileId) => {
  if (context === 'tutorConnect' && isNotEmpty(profileId)) {
    return `/u/${profileId}`;
  } else if (context === 'referral' && isNotEmpty(profileId)) {
    return `/c/${profileId}`;
  }
  return '/signup';
};

export const getTutorProfileUri = profileId =>
  `${WEB_CLIENT_URL}/u/${profileId}`;

export const emailIsValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const getProfileDescription = currentUser => {
  const isTutorProfile = currentUser.profile.profileTypes.includes(
    ProfileType.TUTOR,
  );
  if (isTutorProfile) {
    return 'Tutor on Tutorfly';
  }
  const { clientProfileType } = currentUser.profile;
  return `${capitalizeFirstLetter(
    isNotEmpty(clientProfileType) ? clientProfileType : 'Client',
  )} on Tutorfly`;
};

export const hasLocationForProfileDescription = currentLocation => {
  if (!currentLocation) {
    return false;
  }
  return isNotEmpty(currentLocation.city) && isNotEmpty(currentLocation.state);
};

export const formatCityName = cityName => {
  if (!cityName) {
    return '';
  }

  const cityNameParts = cityName.split(' ');
  const formattedCityNameParts = cityNameParts.map(cityNamePart =>
    capitalizeFirstLetter(cityNamePart),
  );
  const formattedName = formattedCityNameParts.join(' ');
  return formattedName;
};

export const getTutorProfileDescription = schoolName => {
  if (isNotEmpty(schoolName)) {
    return `Tutor from ${schoolName}`;
  }
  return `Tutor on Tutorfly`;
};

export const getClientProfileDescription = clientProfileType =>
  `${capitalizeFirstLetter(
    isNotEmpty(clientProfileType) ? clientProfileType : 'Client',
  )} on Tutorfly`;

export const getProfileLocationDescription = currentLocation => {
  if (!currentLocation) {
    return '';
  }

  return `${formatCityName(currentLocation.city)}, ${currentLocation.state}`;
};

export const getBioPlaceholder = (currentUser, userType) => {
  const isTutorProfile =
    currentUser.profile.profileTypes.includes(ProfileType.TUTOR) &&
    userType === ProfileType.TUTOR;
  if (isTutorProfile) {
    return 'I am a tutor at...';
  }
  const { clientProfileType } = currentUser.profile;
  return `I am a ${lowerCaseWord(
    isNotEmpty(clientProfileType) ? clientProfileType : 'client',
  )} looking for...`;
};

export const getEditProfileDesc = (currentUser, userType) => {
  const isTutorProfile =
    currentUser.profile.profileTypes.includes(ProfileType.TUTOR) &&
    userType === ProfileType.TUTOR;
  if (isTutorProfile) {
    return `You will always have the opportunity to accept or decline
    tutoring requests from new clients and to stick to messaging
    clients through your Tutorfly Dashboard through our messaging
    portal. We will not share your phone number and email address
    with any new users at any point unless you want us to do so.`;
  }
  return `Tutorfly only shares your name and bio details with tutors you
  have sent requests to and connected with. We never share your
  contact info with other users.`;
};

export const getYearOptions = currentUser => {
  const isTutorProfile = currentUser.profile.profileTypes.includes(
    ProfileType.TUTOR,
  );
  if (isTutorProfile) {
    if (currentUser.profile.isHighSchoolTutor) {
      return highSchoolYearOptions;
    }
    return collegeYearOptions;
  }
  return [];
};

export const getBirthDate = currentUser => {
  const { birthdayMonth, birthdayDay, birthdayYear } = currentUser.profile;

  if (!birthdayMonth || birthdayMonth === 0) {
    return null;
  } else if (!birthdayDay || birthdayDay === 0) {
    return null;
  } else if (!birthdayYear || birthdayYear === 0) {
    return null;
  }
  return new Date(birthdayYear, birthdayMonth - 1, birthdayDay);
};

export const getSchoolLocationDescription = currentSchool => {
  if (!currentSchool) {
    return '';
  }

  return `${currentSchool.name} - ${formatCityName(currentSchool.city)}, ${
    currentSchool.state
  }`;
};

/**
 * Location description meant for the account settings section to manage profile visibility.
 * @param {*} currentLocation Object from the currentUser profile object with details on
 * getting the home location.
 */
export const getHomeLocationDescription = currentLocation => {
  if (!currentLocation) {
    return '';
  }

  return `Current Location - ${currentLocation.zip} ${formatCityName(
    currentLocation.city,
  )}, ${currentLocation.state}`;
};

export const hasCurrentLocation = currentLocation => {
  if (!currentLocation) {
    return false;
  }
  return (
    isNotEmpty(currentLocation.zip) &&
    isNotEmpty(currentLocation.city) &&
    isNotEmpty(currentLocation.state)
  );
};

export const getVisibilityPreferences = locations => {
  if (!locations) {
    return { schoolLocationVisible: false, homeLocationVisible: false };
  }

  const schoolCoordinates = locations.coordinates[0];
  const homeCoordinates = locations.coordinates[1];

  let schoolLocationVisible = true;
  let homeLocationVisible = true;

  if (!schoolCoordinates) {
    schoolLocationVisible = false;
  } else if (
    schoolCoordinates[0] === NULL_COORDINATE &&
    schoolCoordinates[1] === NULL_COORDINATE
  ) {
    schoolLocationVisible = false;
  }

  if (!homeCoordinates) {
    homeLocationVisible = false;
  } else if (
    homeCoordinates[0] === NULL_COORDINATE &&
    homeCoordinates[1] === NULL_COORDINATE
  ) {
    homeLocationVisible = false;
  }

  return { schoolLocationVisible, homeLocationVisible };
};

export const getErrorMessage = error => {
  if (error) {
    if (error.graphQLErrors) {
      if (error.graphQLErrors[0]) {
        const errorMessage = error.graphQLErrors[0].message
          ? error.graphQLErrors[0].message
          : error.toString();
        return errorMessage;
      }
    }
    return error.toString();
  }
  return '';
};

export const formatFloatValue = amount => {
  if (!(typeof amount === 'number')) {
    return '0';
  }
  const stringifiedAmount = `${amount}`;
  if (stringifiedAmount.includes('.')) {
    return `${amount.toFixed(2)}`;
  }
  return `${Number(amount)}`;
};

export const formatDollarValue = amount => `$${formatFloatValue(amount)}`;

export const getTutorPayoutLabelForSession = session => {
  let amount = 0;
  const { participants } = session;
  if (!participants) {
    return 0;
  }

  try {
    participants.map(participant => {
      if (participant.userType === SessionParticipantType.STUDENT) {
        amount += participant.tutorBasePaymentAmount;
      }
      return participant;
    });
  } catch (err) {
    amount = 0;
  }

  return formatDollarValue(amount);
};

export const getStripeUri = (userEmail, firstName, lastName, tutorUri) => {
  const stripeClientId = STRIPE_CONNECT_OAUTH_CLIENT_ID;
  const stateValue = 'abc123';
  return `https://connect.stripe.com/express/oauth/authorize?client_id=${stripeClientId}&state=${stateValue}&stripe_user[business_type]=individual&stripe_user[email]=${userEmail}&stripe_user[first_name]=${firstName}&stripe_user[last_name]=${lastName}&stripe_user[url]=${tutorUri}&stripe_user[country]=US&suggested_capabilities[]=transfers`;
};

export const isMobile = width => width === 'sm' || width === 'xs';

export const isExtraSmallScreen = width => width === 'xs';

// because when File type is fetched from GraphQL, it attaches this annoying
// little field called __typename that messes up future graphql queries
export function stripFileMetadata(files) {
  if (!files) {
    return [];
  }
  if (Array.isArray(files)) {
    return files.map(({ filename, mimetype, url }) => ({
      filename,
      mimetype,
      url,
    }));
  }
  return [
    {
      filename: files.filename,
      mimetype: files.mimetype,
      url: files.url,
    },
  ];
}

export const getUsersForSession = session => {
  const tutees = session.participants.filter(
    participant => participant.userType === SessionParticipantType.STUDENT,
  );

  const tutors = session.participants.filter(
    participant => participant.userType === SessionParticipantType.TUTOR,
  );

  return {
    tutees,
    tutors,
  };
};

export const getTutorHourlyRateForSession = session => {
  let totalTutorHourlyRate = 0;
  session.participants
    .filter(
      participant => participant.userType === SessionParticipantType.STUDENT,
    )
    .map(participant => {
      if (!Number.isNaN(participant.hourlyRate)) {
        totalTutorHourlyRate += participant.hourlyRate;
      }
      return participant;
    });
  return totalTutorHourlyRate;
};

export const getWeekdayFromInt = dayOfWeek => {
  switch (dayOfWeek) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return '';
  }
};

export const selectedLanguageTagsValid = languageTags => {
  const validLanguageTags = Object.values(LanguageLocaleConstants);
  const validLanguageReducer = (accumulator, langTag) =>
    validLanguageTags.includes(langTag) && accumulator;
  const allTagsValid = Object.keys(languageTags).reduce(
    validLanguageReducer,
    true,
  );

  return allTagsValid;
};

export const getFormattedLangTags = (languageTypes, selectedLanguages) => {
  const finalLanguages = selectedLanguages.map(languageLabel => {
    return Object.keys(languageTypes).find(
      key => languageTypes[key] === languageLabel,
    );
  });
  return finalLanguages;
};

export const getMaxRoleTypeFromProfileTypes = (
  profileTypes,
  tutorTerm,
  tuteeTerm,
) => {
  if (!profileTypes) {
    return 'Member';
  }

  if (profileTypes.includes(ProfileType.TUTOR)) {
    return capitalizeFirstLetter(tutorTerm);
  } else if (profileTypes.includes(ProfileType.STUDENT_OR_PARENT)) {
    return capitalizeFirstLetter(tuteeTerm);
  }
  return 'Member';
};
