import {
  FETCH_TUTOR_PROFILE,
  RECEIVED_TUTOR_PROFILE,
  ERROR_FETCH_TUTOR_PROFILE,
  RESET_PROFILE_PAGE,
  TUTOR_NOT_FOUND,
  SET_REVIEWS,
  SET_PAIR_AND_HAS_REVIEW,
  LEAVE_REVIEW,
  EDIT_REVIEW,
} from '../types';

const INITIAL_STATE = {
  tutor: null,
  tutorNotFound: false,
  isFetching: false,
  isError: false,
  reviews: [],
  allReviews: [],
  seeMoreReviews: false,
  pair: null,
  hasReview: false,
  selectedReview: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TUTOR_PROFILE:
      return {
        ...state,
        isFetching: true,
      };

    case SET_REVIEWS:
      return {
        ...state,
        reviews: action.payload.reviews,
        allReviews: action.payload.allReviews,
        seeMoreReviews: action.payload.seeMoreReviews,
      };

    case SET_PAIR_AND_HAS_REVIEW:
      return {
        ...state,
        pair: action.payload.pair,
        hasReview: action.payload.hasReview,
        selectedReview: action.payload.selectedReview,
      };

    case RECEIVED_TUTOR_PROFILE:
      return {
        ...state,
        tutor: Object.assign({}, action.payload.tutor),
        isFetching: false,
        isError: false,
      };

    case ERROR_FETCH_TUTOR_PROFILE:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };

    case TUTOR_NOT_FOUND:
      return {
        ...state,
        tutorNotFound: true,
      };

    case LEAVE_REVIEW:
      return {
        ...state,
        reviews: action.payload.reviews,
        allReviews: action.payload.allReviews,
        selectedReview: action.payload.selectedReview,
        hasReview: action.payload.hasReview,
        tutor: action.payload.tutor,
      };

    case EDIT_REVIEW:
      return {
        ...state,
        reviews: action.payload.reviews,
        allReviews: action.payload.allReviews,
        selectedReview: action.payload.selectedReview,
        tutor: action.payload.tutor,
      };

    case RESET_PROFILE_PAGE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
