/**
 * Helper module for detecting browser information based on the userAgent
 * detected in the browser.
 * Source: https://medium.com/creative-technology-concepts-code/detect-device-browser-and-version-using-javascript-8b511906745
 */
import Bowser, { BROWSER_MAP, PLATFORMS_MAP } from 'bowser';

const isInvalidBrowser = win => {
  let inInvalidSafariBrowser = false;
  if (win) {
    if (win.navigator) {
      if (win.navigator.userAgent) {
        const browser = Bowser.parse(win.navigator.userAgent);
        const browserName = browser.browser.name;
        const browserVersion = browser.browser.version;
        const browserPlatform = browser.platform.type;
        if (
          browserName === BROWSER_MAP.safari &&
          parseInt(browserVersion, 10) < 12 &&
          browserPlatform === PLATFORMS_MAP.desktop
        ) {
          inInvalidSafariBrowser = true;
        }
      }
    }
  }
  return inInvalidSafariBrowser;
};

export const getBrowserName = win => {
  if (win) {
    if (win.navigator) {
      if (win.navigator.userAgent) {
        const browser = Bowser.parse(win.navigator.userAgent);
        const browserName = browser.browser.name;
        return browserName;
      }
    }
  }
  return '';
};

export default isInvalidBrowser;
