import gql from 'graphql-tag';

export const USER_QUERY = gql`
  query getUser {
    getCurrentUserV2 {
      id
      email {
        verified
        address
      }
      isOAuthAccount
      profile {
        firstName
        lastName
        profileTypes
        clientProfileType
        bio
        hourlyWage
        languages
        phoneNumber
        isPhoneNumberVerified
        manualPayoutId
        birthdayMonth
        birthdayDay
        birthdayYear
        timeZoneId
        address
        notificationPreference
        pushPreference
        paidPreference
        nonProfitName
        donationLink
        expoToken
        gradesForTutoring
        gpa
        school
        locations {
          coordinates
          type
        }
        currentSchool {
          name
          city
          state
        }
        currentLocation {
          zip
          city
          state
        }
        gradeLevel
        isReceivingNewsletters
        isVisible
        bundleAmt
        isHighSchoolTutor
        hasStripeConnectAccount
        stripeConnectVerified
        creditCardData {
          brand
          expMonth
          expYear
          isDefault
          last4
        }
        classes {
          school
          subject
          teacher
          course
          isHighSchoolClass
        }
        profilePicture {
          src
          rotation
        }
        tutorRequestPreferences {
          subject
          gradeLevel
          sessionType
          zipCode
          city
          state
          daysAvailable
          timeAvailable
          firstMessage
          files {
            filename
            mimetype
            url
          }
        }
        sessionPreferences {
          location
        }
        profilePageId
        profileId
        hasSignedAgreement
        dailyAvaliability
        avaliabilityDesc
        isOnboarded
        prefPaymentMethod
        primaryPayoutMethod
        tutorOnboardingSubmitTime
        onboardVideoLink
        tutorQuizSubmitted
      }
      zoomProfile {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const USER_HAS_CONNECTIONS = gql`
  query {
    hasConnections
    getPendingTutorRequestCount
  }
`;

export const SSR_SERIALIZED_USER_QUERY = gql`
  query getUser {
    getCurrentUserV2 {
      id
      email {
        verified
        address
      }
      profile {
        firstName
        lastName
        profileTypes
        phoneNumber
        profilePicture {
          src
          rotation
        }
      }
      zoomProfile {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const TUTORS_BY_COURSE_QUERY = gql`
  query searchTutorsByCourse(
    $input: String!
    $localPageNumber: Int!
    $onlinePageNumber: Int!
    $zip: String!
    $studentGradeLevel: String!
  ) {
    searchTutorsByCourse(
      input: $input
      localPageNumber: $localPageNumber
      onlinePageNumber: $onlinePageNumber
      zip: $zip
      studentGradeLevel: $studentGradeLevel
    ) {
      localTutors {
        id
        profile {
          profileTypes
          firstName
          lastName
          school
          classes {
            course
            subject
            teacher
            school
          }
          dailyAvaliability
          gradesForTutoring
          gradeLevel
          gpa
          bio
          profilePicture {
            src
            rotation
          }
          hourlyWage
          profilePageId
          profileId
          avgReviewScore
          isVisible
        }
      }
      onlineTutors {
        id
        profile {
          profileTypes
          firstName
          lastName
          school
          classes {
            course
            subject
            teacher
            school
          }
          dailyAvaliability
          gradesForTutoring
          gradeLevel
          gpa
          bio
          profilePicture {
            src
            rotation
          }
          hourlyWage
          profilePageId
          profileId
          avgReviewScore
          isVisible
        }
      }
      numLocalTutors
      numOnlineTutors
    }
  }
`;

export const TUTORS_BY_SUBJECT_QUERY = gql`
  query getTutorsBySubject($input: getTutorsBySubjectInput!) {
    getTutorsBySubject(input: $input) {
      localTutors {
        id
        profile {
          profileTypes
          firstName
          lastName
          school
          classes {
            course
            subject
            teacher
            school
          }
          dailyAvaliability
          gradesForTutoring
          gradeLevel
          gpa
          bio
          profilePicture {
            src
            rotation
          }
          hourlyWage
          profilePageId
          profileId
          avgReviewScore
          isVisible
        }
      }
      onlineTutors {
        id
        profile {
          profileTypes
          firstName
          lastName
          school
          classes {
            course
            subject
            teacher
            school
          }
          dailyAvaliability
          gradesForTutoring
          gradeLevel
          gpa
          bio
          profilePicture {
            src
            rotation
          }
          hourlyWage
          profilePageId
          profileId
          avgReviewScore
          isVisible
        }
      }
      numLocalTutors
      numLocalSchools
      numOnlineTutors
      numOnlineSchools
      schools
      courses
      teachers
      tutorLevels
    }
  }
`;

export const GET_INVITED_USER_BY_TOKEN_QUERY = gql`
  query getInvitedUserByToken($token: String!) {
    getInvitedUserByToken(token: $token) {
      id
      email {
        verified
        address
      }
      profile {
        profileTypes
        firstName
        lastName
      }
    }
  }
`;

export const GET_PROFILE_ID_FROM_PROFILE_PAGE_ID = gql`
  query getUserByProfilePageIdAndReviews($profilePageId: String!) {
    getUserByProfilePageIdAndReviews(profilePageId: $profilePageId) {
      user {
        id
        profile {
          profileId
          isVisible
        }
      }
    }
  }
`;

export const CHECK_EMAIL_IN_USE_QUERY = gql`
  query checkEmailPhoneExists($email: String!, $phone: String!) {
    checkEmailPhoneExists(email: $email, phone: $phone)
  }
`;

export const GET_HUBSPOT_SIGN_UP_INFO = gql`
  query getHubSpotEmailSignup($ticketId: String!) {
    getHubSpotEmailSignup(ticketId: $ticketId) {
      firstName
      lastName
      email
      phoneNumber
      clientProfileType
      subject
      tutorProfileId
      status
      leadSource
    }
  }
`;

export const GET_TUTOR_INFO_CUSTOM_SIGN_UP = gql`
  query getProfilePage($profileId: String!) {
    getProfilePage(profileId: $profileId) {
      user {
        id
        profile {
          firstName
          lastName
          school
          profilePicture {
            src
            rotation
          }
          isVisible
        }
      }
    }
  }
`;

export const GET_USER_BY_PROFILE_ID = gql`
  query getProfilePage($profileId: String!) {
    getProfilePage(profileId: $profileId) {
      user {
        id
        profile {
          firstName
          lastName
          school
          subjects
          clientProfileType
          classes {
            course
            subject
            school
            teacher
          }
          gradesForTutoring
          gradeLevel
          gpa
          bio
          profileTypes
          profilePicture {
            src
            rotation
          }
          hourlyWage
          hoursTutored
          dailyAvaliability
          avaliabilityDesc
          profileId
          avgReviewScore
          isVisible
        }
      }
      reviewPreview {
        id
        clientId
        clientFirstName
        clientLastName
        clientProfilePic {
          src
          rotation
        }
        score
        comments
      }
      allReviews {
        id
        clientId
        clientFirstName
        clientLastName
        clientProfilePic {
          src
          rotation
        }
        score
        comments
      }
      isPairedWithUser
      hasHadSessions
    }
  }
`;

export const GET_REVIEWS_BY_PROFILE_ID = gql`
  query getAllReviewsForTutorQuery($tutorId: String!) {
    getAllReviewsForTutorQuery(tutorId: $tutorId) {
      id
      clientId
      clientFirstName
      clientLastName
      score
      comments
    }
  }
`;

export const GET_TUTORS_AT_SCHOOL = gql`
  query getTutorsBySchool($school: String, $city: String, $state: String) {
    getTutorsBySchool(school: $school, city: $city, state: $state) {
      id
      profile {
        profileTypes
        firstName
        lastName
        school
        subjects
        clientProfileType
        classes {
          course
          subject
          school
          teacher
        }
        dailyAvaliability
        gradesForTutoring
        gradeLevel
        gpa
        bio
        profileId
        profileTypes
        profilePicture {
          src
          rotation
        }
        hourlyWage
        profilePageId
        avgReviewScore
        isVisible
      }
    }
  }
`;

export const GET_REFERRAL_INFO_BY_PROFILE_ID = gql`
  query getProfilePage($profileId: String!) {
    getProfilePage(profileId: $profileId) {
      user {
        id
        profile {
          firstName
          lastName
          profileTypes
          profilePicture {
            src
            rotation
          }
          isVisible
        }
      }
    }
  }
`;

export const GET_SPORTS_CLUB_REFERRAL_INFO_BY_PROFILE_ID = gql`
  query getProfilePage($profileId: String!) {
    getProfilePage(profileId: $profileId) {
      user {
        id
        profile {
          firstName
          lastName
          profileTypes
          profilePicture {
            src
            rotation
          }
          sportsTeam
          isVisible
        }
      }
    }
  }
`;

// V4 of fetching a user's session summary
// Jan 2021 - Primary upcoming sessions query we should rely on for showing user sessions on dashboard
export const GET_USER_UPCOMING_SESSIONS_SUMMARY = gql`
  query getUpcomingSessions($isTutorContext: Boolean!, $pageNum: Int) {
    getUpcomingSessions(isTutorContext: $isTutorContext, pageNum: $pageNum) {
      sessions {
        id
        subject
        status
        sessionType
        participants {
          user {
            id
            profile {
              firstName
              lastName
              profilePicture {
                src
              }
              profileId
            }
          }
          userType
          joinedTime
          leftTime
          sessionPayoutStatus
          tutorBasePaymentAmount
          remaining
          hourlyRate
        }
        date
        startTime
        endTime
        processingTime
        cancellationPolicyTime
        timeZoneId
        recurringDays
        recurringSessionId
        weeksRecurring
        tutoringAmount
        meetingSolution
        isInPerson
        joinUrl
        isPaid
        gradeLevel
        zoomOptions {
          tutorflyBookerId
          meetingId
          join_url
          host_id
          start_url
          password
        }
        lessonspaceOptions {
          roomId
        }
        physicalSessionOptions {
          locationName
          locationPoint {
            type
            coordinates
          }
        }
      }
      profileSummaries {
        user {
          id
          profile {
            firstName
            lastName
            profilePicture {
              src
            }
          }
        }
        sessions {
          id
          subject
          status
          sessionType
          participants {
            user {
              id
              profile {
                firstName
                lastName
                profilePicture {
                  src
                }
                profileId
              }
            }
            userType
            joinedTime
            leftTime
            sessionPayoutStatus
            tutorBasePaymentAmount
            remaining
            hourlyRate
          }
          date
          startTime
          endTime
          processingTime
          cancellationPolicyTime
          timeZoneId
          isPaid
          tutoringAmount
          meetingSolution
          recurringDays
          recurringSessionId
          weeksRecurring
          isInPerson
          joinUrl
          gradeLevel
          zoomOptions {
            tutorflyBookerId
            meetingId
            join_url
            host_id
            start_url
            password
          }
          lessonspaceOptions {
            roomId
          }
          physicalSessionOptions {
            locationName
            locationPoint {
              type
              coordinates
            }
          }
        }
        totalSessions
      }
      hasMore
      totalSessions
    }
  }
`;

export const GET_SESSION_SUMMARIES_BY_PAIR_ID = gql`
  query getSessionSummariesByPairId($pairId: String!) {
    getSessionSummariesByPairId(pairId: $pairId) {
      pairedUserProfile {
        firstName
        lastName
        profilePicture {
          src
        }
      }
      sessions {
        id
        date
        plannedStartTime
        subject
        tutorSummary
        clientFeedback
      }
    }
  }
`;

// The component that uses this should no longer be used. Remove once we've confirmed no one uses the old completed sessions dashboard
export const GET_COMPLETED_SESSIONS_MONTHLY_SUMMARY_V3 = gql`
  query getCompletedSessionSummaryV3($input: CompletedSessionSummaryInput!) {
    getCompletedSessionSummaryV3(input: $input) {
      unprocessedSessions {
        id
        subject
        status
        sessionType
        participants {
          user {
            id
            profile {
              firstName
              lastName
              profilePicture {
                src
              }
            }
            email {
              address
            }
          }
          userType
          joinedTime
          leftTime
          sessionPayoutStatus
          tutorBasePaymentAmount
          finalTutorPayoutAmount
          finalClientPaymentAmount
          remaining
          hourlyRate
        }
        date
        startTime
        endTime
        processingTime
        cancellationPolicyTime
        timeZoneId
        recurringDays
        recurringSessionId
        weeksRecurring
        tutoringAmount
        meetingSolution
        isInPerson
        isPaid
        joinUrl
        gradeLevel
        zoomOptions {
          tutorflyBookerId
          meetingId
          join_url
          host_id
          start_url
          password
        }
        lessonspaceOptions {
          roomId
        }
        physicalSessionOptions {
          locationName
          locationPoint {
            type
            coordinates
          }
        }
      }
      completedSessionSummaries {
        sessions {
          id
          subject
          status
          sessionType
          participants {
            user {
              id
              profile {
                firstName
                lastName
                profilePicture {
                  src
                }
              }
              email {
                address
              }
            }
            userType
            joinedTime
            leftTime
            sessionPayoutStatus
            tutorBasePaymentAmount
            finalTutorPayoutAmount
            finalClientPaymentAmount
            remaining
            hourlyRate
          }
          date
          startTime
          endTime
          processingTime
          cancellationPolicyTime
          timeZoneId
          recurringDays
          recurringSessionId
          weeksRecurring
          tutoringAmount
          meetingSolution
          isInPerson
          joinUrl
          isPaid
          gradeLevel
          zoomOptions {
            tutorflyBookerId
            meetingId
            join_url
            host_id
            start_url
            password
          }
          lessonspaceOptions {
            roomId
          }
          physicalSessionOptions {
            locationName
            locationPoint {
              type
              coordinates
            }
          }
        }
        id
        firstName
        lastName
        numSessions
        numMins
        sessionIdsWithoutFeedback
        rate
        total
        totalFee
        profilePicture {
          src
        }
        pairId
      }
      monthlyTotal
      monthlyTotalFee
    }
  }
`;

export const GET_ADMIN_PAYMENT_ERROR_SESSIONS = gql`
  query getAdminPaymentErrorSessions($month: String!, $year: String!) {
    getAdminPaymentErrorSessions(month: $month, year: $year) {
      id
      date
      subject
      startTime
      endTime
      timeZoneId
      participants {
        user {
          id
          profile {
            firstName
            lastName
            phoneNumber
          }
          email {
            address
          }
        }
        userType
        joinedTime
        leftTime
        sessionPayoutStatus
        tutorBasePaymentAmount
        finalTutorPayoutAmount
        finalClientPaymentAmount
        remaining
        hourlyRate
      }
    }
  }
`;

export const GET_PAYMENT_ERROR_SESSIONS_FOR_USER_V2 = gql`
  query getPaymentErrorSessionsForUserV2 {
    getPaymentErrorSessionsForUserV2 {
      id
      date
      startTime
      endTime
      participants {
        user {
          id
          profile {
            firstName
            lastName
            phoneNumber
          }
          email {
            address
          }
        }
        userType
        joinedTime
        leftTime
        sessionPayoutStatus
        tutorBasePaymentAmount
        finalTutorPayoutAmount
        finalClientPaymentAmount
        remaining
        hourlyRate
      }
    }
  }
`;

export const GET_ADMIN_PAGINATED_SESSION_SUMMARY = gql`
  query getAdminPaginatedSessionSummaryV2($input: SessionSummaryV2Input!) {
    getAdminPaginatedSessionSummaryV2(input: $input) {
      sessions {
        id
        subject
        status
        sessionType
        participants {
          user {
            id
            profile {
              firstName
              lastName
              profilePicture {
                src
              }
            }
            email {
              address
            }
          }
          userType
          joinedTime
          leftTime
          sessionPayoutStatus
          tutorBasePaymentAmount
          finalTutorPayoutAmount
          finalClientPaymentAmount
          remaining
          hourlyRate
        }
        date
        startTime
        endTime
        processingTime
        cancellationPolicyTime
        timeZoneId
        recurringDays
        recurringSessionId
        weeksRecurring
        tutoringAmount
        meetingSolution
        isInPerson
        joinUrl
        isPaid
        gradeLevel
        zoomOptions {
          tutorflyBookerId
          meetingId
          join_url
          host_id
          start_url
          password
        }
        lessonspaceOptions {
          roomId
        }
        physicalSessionOptions {
          locationName
          locationPoint {
            type
            coordinates
          }
        }
      }
      hasMore
    }
  }
`;

export const GET_CURRENT_WEEK_SESSIONS = gql`
  query getSessionsThisWeek($tutorId: String!, $dates: [String!]) {
    getSessionsThisWeek(tutorId: $tutorId, dates: $dates) {
      id
      date
    }
  }
`;

export const GET_PENDING_SESSION_COUNT = gql`
  query getPendingSessionCount($dateRegex: String!) {
    getPendingSessionCount(dateRegex: $dateRegex)
  }
`;

export const GET_CLIENT_REFERRAL_SUMMARY = gql`
  query getClientReferralSummary {
    getClientReferralSummary {
      id
      status
      referee {
        id
        profile {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_PAIR_FOR_USERS = gql`
  query getPairForUserIds($tutorId: String!, $tuteeId: String!) {
    getPairForUserIds(tutorId: $tutorId, tuteeId: $tuteeId) {
      id
      hourlyWage
      tutorHourlyWage
      sessionsTutored
    }
  }
`;

export const GET_PAIR_FOR_USER = gql`
  query getPairForUserId($paireeId: String!, $isTutorContext: Boolean!) {
    getPairForUserId(paireeId: $paireeId, isTutorContext: $isTutorContext) {
      id
      hourlyWage
      tutorHourlyWage
      sessionsTutored
    }
  }
`;

// Used when user is client
export const GET_PAIR_WITH_TUTOR = gql`
  query getPairWithTutorId($tutorId: String!) {
    getPairWithTutorId(tutorId: $tutorId) {
      id
      sessionsTutored
      hourlyWage
    }
  }
`;

// Query for searching for pairs for internal admins
export const ADMIN_SEARCH_PLATFORM_PAIRS = gql`
  query getAdminPaginatedPairsSearch($input: AdminSearchPairInput!) {
    getAdminPaginatedPairsSearch(input: $input) {
      pairs {
        id
        sessionsTutored
        hourlyWage
        tutorHourlyWage
        tutor {
          id
          profile {
            firstName
            lastName
            profilePicture {
              src
            }
            clientDefaultPairRate
            tutorDefaultPairRate
          }
          createdAt
        }
        tutee {
          id
          profile {
            firstName
            lastName
            profilePicture {
              src
            }
            clientDefaultPairRate
            tutorDefaultPairRate
          }
          createdAt
        }
        tutorPlatformFeePercentageRate
        tuteePlatformFeePercentageRate
      }
      hasMore
    }
  }
`;

// Admin pair save rate mutation
export const ADMIN_SAVE_PAIR_PLATFORM_RATES = gql`
  mutation savePairPlatformRates($input: AdminPairPlatformRateUpdateInput!) {
    savePairPlatformRates(input: $input)
  }
`;

// Used when user is tutor
export const GET_PAIRS_FOR_GROUP_SESSION = gql`
  query getPairsForGroupSession($tuteeIds: [String!]) {
    getPairsForGroupSession(tuteeIds: $tuteeIds) {
      id
      hourlyWage
      tutorHourlyWage
    }
  }
`;

/**
|--------------------------------------------------
| GRAPH QL MUTATIONS
|--------------------------------------------------
*/

// Updated Request Tutoring API

export const SEND_TUTOR_REQUEST_MUTATION = gql`
  mutation requestTutoringSession(
    $input: RequestTutorInput!
    $savePreferences: Boolean!
  ) {
    requestTutoringSession(input: $input, savePreferences: $savePreferences)
  }
`;

export const EDIT_TUTOR_REQUEST_MUTATION = gql`
  mutation editTutoringRequest(
    $requestId: String!
    $input: RequestTutorInput!
  ) {
    editTutoringRequest(requestId: $requestId, input: $input) {
      id
      from {
        id
      }
      subject
      gradeLevel
      sessionType
      zipCode
      city
      state
      daysAvailable
      timeAvailable
      note
      files {
        filename
        mimetype
        url
      }
    }
  }
`;

export const ACTIVATE_PAIR = gql`
  mutation activatePair($pairId: String!) {
    activatePair(pairId: $pairId) {
      id
      hourlyWage
      tutorHourlyWage
      tutor {
        id
        profile {
          school
          isHighSchoolTutor
        }
      }
      tutee {
        id
        profile {
          clientProfileType
        }
      }
      sessionsTutored
      type
      clientReviewId
      isActive
    }
  }
`;

export const ACCEPT_TUTOR_REQUEST_MUTATION = gql`
  mutation acceptTutoringRequest($requestId: String!) {
    acceptTutoringRequest(requestId: $requestId) {
      id
      hourlyWage
      tutor {
        id
        profile {
          school
          isHighSchoolTutor
        }
      }
      tutee {
        id
        profile {
          clientProfileType
        }
      }
      sessionsTutored
      type
    }
  }
`;

export const DECLINE_TUTOR_REQUEST_MUTATION = gql`
  mutation declineTutoringRequest($requestId: String!, $message: String!) {
    declineTutoringRequest(requestId: $requestId, message: $message)
  }
`;

export const CANCEL_TUTOR_REQUEST_MUTATION = gql`
  mutation cancelTutoringRequest($requestId: String!) {
    cancelTutoringRequest(requestId: $requestId)
  }
`;

// High School & College Course Mutations

export const NEW_CLASS_MUTATION = gql`
  mutation editProfileAddClass($input: addClassInput!) {
    addClass(input: $input) {
      school
      subject
      course
      teacher
    }
  }
`;

export const DELETE_CLASSES_MUTATION = gql`
  mutation editProfileDeleteClasses($input: [editClassesInput]!) {
    editClasses(input: $input) {
      school
      subject
      course
      teacher
    }
  }
`;

export const EDIT_CLASS_TEACHER_MUTATION = gql`
  mutation editClass($className: String!, $teacherName: String!) {
    editClass(className: $className, teacherName: $teacherName) {
      teacher
    }
  }
`;

export const ADD_HIGH_SCHOOL_CLASSES = gql`
  mutation addHighSchoolClasses($newClasses: [String]!) {
    addHighSchoolClasses(newClasses: $newClasses) {
      school
      subject
      course
      teacher
      isHighSchoolClass
    }
  }
`;

export const ADD_CLASSES = gql`
  mutation addClasses($newClasses: [String]!) {
    addClasses(newClasses: $newClasses) {
      school
      subject
      course
      teacher
      isHighSchoolClass
    }
  }
`;

export const ADD_CLASSES_TO_TUTOR_PROFILE = gql`
  mutation addClassesToTutorProfile($newClasses: [String]!, $tutorId: String!) {
    addClassesToTutorProfile(newClasses: $newClasses, tutorId: $tutorId)
  }
`;

export const SAVE_USER_CREDIT = gql`
  mutation saveTutorflyCredit($credit: Float!, $tuteeId: String!) {
    saveTutorflyCredit(credit: $credit, tuteeId: $tuteeId)
  }
`;

export const SAVE_CLIENT_DEFAULT_PAIR_RATE = gql`
  mutation saveClientDefaultPairRate(
    $defaultPairRate: Float!
    $userId: String!
  ) {
    saveClientDefaultPairRate(
      defaultPairRate: $defaultPairRate
      userId: $userId
    )
  }
`;

export const SAVE_TUTOR_DEFAULT_PAIR_RATE = gql`
  mutation saveTutorDefaultPairRate(
    $defaultPairRate: Float!
    $userId: String!
  ) {
    saveTutorDefaultPairRate(defaultPairRate: $defaultPairRate, userId: $userId)
  }
`;

export const SAVE_HOURLY_WAGE = gql`
  mutation saveHourlyWage($hourlyWage: Float!, $userId: String!) {
    saveHourlyWage(hourlyWage: $hourlyWage, userId: $userId)
  }
`;

export const SAVE_NEW_USER_PASSWORD = gql`
  mutation saveNewUserPassword($newPassword: String!, $userId: String!) {
    saveNewUserPassword(newPassword: $newPassword, userId: $userId)
  }
`;

export const SAVE_RATE_FOR_PAIR = gql`
  mutation saveHourlyRateForPair($pairId: String!, $rate: Float!) {
    saveHourlyRateForPair(pairId: $pairId, rate: $rate)
  }
`;

export const SEND_ERROR_SESSION_FOLLOW_UP_TEXT = gql`
  mutation sendErrorMessageFollowUpToUser(
    $errorTextExplanation: String!
    $errorTextFollowUp: String!
    $phoneNumber: String!
  ) {
    sendErrorMessageFollowUpToUser(
      errorTextExplanation: $errorTextExplanation
      errorTextFollowUp: $errorTextFollowUp
      phoneNumber: $phoneNumber
    )
  }
`;

export const ADD_COLLEGE_CLASSES = gql`
  mutation addCollegeClasses($newClasses: [String]!) {
    addCollegeClasses(newClasses: $newClasses) {
      school
      subject
      course
      teacher
      isHighSchoolClass
    }
  }
`;

export const CANCEL_SESSION_V3 = gql`
  mutation cancelSessionV3($input: CancelSessionInput!) {
    cancelSessionV3(input: $input) {
      id
    }
  }
`;

export const EDIT_SESSION_V3 = gql`
  mutation editSessionV3($input: EditSessionV2Input!) {
    editSessionV3(input: $input) {
      session {
        id
        subject
        status
        sessionType
        participants {
          user {
            id
            profile {
              firstName
              lastName
              profilePicture {
                src
              }
            }
          }
          userType
          joinedTime
          leftTime
          sessionPayoutStatus
          tutorBasePaymentAmount
          remaining
          hourlyRate
        }
        date
        startTime
        endTime
        processingTime
        cancellationPolicyTime
        timeZoneId
        tutoringAmount
        meetingSolution
        isInPerson
        joinUrl
        zoomOptions {
          tutorflyBookerId
          meetingId
          join_url
          host_id
          start_url
          password
        }
        lessonspaceOptions {
          roomId
        }
        physicalSessionOptions {
          locationName
          locationPoint {
            type
            coordinates
          }
        }
      }
    }
  }
`;

export const ADMIN_FIX_ERROR_SESSION_MUTATION_ADMIN_V2 = gql`
  mutation adminFixErrorSession($sessionId: ID!) {
    adminFixErrorSession(sessionId: $sessionId) {
      id
    }
  }
`;

export const FIX_ERROR_SESSION_MUTATION = gql`
  mutation fixErrorSession($sessionId: ID!) {
    fixErrorSession(sessionId: $sessionId) {
      id
    }
  }
`;

export const DROP_SESSION_MUTATION = gql`
  mutation dropSession($input: DropSessionInput!) {
    dropSession(input: $input) {
      id
      subject
      status
      sessionType
      participants {
        user {
          id
        }
        userType
        joinedTime
        leftTime
        sessionPayoutStatus
        tutorBasePaymentAmount
        remaining
        hourlyRate
      }
      startTime
      endTime
      processingTime
      cancellationPolicyTime
      timeZoneId
      tutoringAmount
      meetingSolution
      isInPerson
      joinUrl
      zoomOptions {
        tutorflyBookerId
        meetingId
        join_url
        host_id
        start_url
        password
      }
      lessonspaceOptions {
        roomId
      }
      physicalSessionOptions {
        locationName
        locationPoint {
          type
          coordinates
        }
      }
    }
  }
`;

export const EMAIL_LOGIN_MUTATION = gql`
  mutation emailLoginMutation($user: String!, $pass: String!) {
    emailLogin(username: $user, password: $pass)
  }
`;

export const EMAIL_LOGIN_V2 = gql`
  mutation emailLoginV2($user: String!, $pass: String!) {
    emailLoginV2(username: $user, password: $pass) {
      token
      status
    }
  }
`;

export const ACTIVATE_ACCOUNT_FROM_INVITE = gql`
  mutation activateAccountFromInvite($userId: String!, $password: String!) {
    activateAccountFromInvite(userId: $userId, password: $password)
  }
`;

export const RESEND_INVITE_EMAIL = gql`
  mutation resendInviteEmail($userId: String!) {
    resendInviteEmail(userId: $userId)
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($oldPass: String!, $newPass: String!) {
    changePassword(oldPass: $oldPass, newPass: $newPass)
  }
`;

export const EDIT_NOTIFICATIONS_MUTATION = gql`
  mutation editNotifications($input: editNotificationSettingsInput!) {
    editNotificationSettings(input: $input) {
      notificationPreference
    }
  }
`;

export const SIGN_TUTOR_AGREEMENT = gql`
  mutation signTutorAgreement($tutorId: ID!) {
    signTutorAgreement(tutorId: $tutorId)
  }
`;

export const ONBOARD_TUTOR = gql`
  mutation onBoardTutor($tutorId: ID!) {
    onBoardTutor(tutorId: $tutorId)
  }
`;

/**
 * Moving forward this mutation will handle all updates (including partial updates)
 * to the user/profile objects
 */
export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($userInput: UserInput!) {
    updateUser(updateUser: $userInput) {
      email {
        address
      }
      profile {
        phoneNumber
      }
    }
  }
`;

export const EDIT_BASIC_INFO_MUTATION = gql`
  mutation editBasicInfoMutation(
    $input: editPersonalInfoInput!
    $email: String
  ) {
    editPersonalInfo(input: $input, email: $email) {
      firstName
      lastName
    }
  }
`;

export const EDIT_PRIMARY_INFO_MUTATION = gql`
  mutation editPrimaryInfo($input: editPrimaryProfileInfoInput!) {
    editPrimaryInfo(input: $input) {
      firstName
      lastName
      birthdayMonth
      birthdayDay
      birthdayYear
    }
  }
`;

export const EDIT_CONTACT_INFO_MUTATION = gql`
  mutation editContactInfo($input: editContactInfoInput!) {
    editContactInfo(input: $input) {
      phoneNumber
    }
  }
`;

export const SAVE_TUTOR_QUIZ_SUBMITTED_MUTATION = gql`
  mutation tutorHasCompletedQuiz {
    tutorHasCompletedQuiz
  }
`;

export const EDIT_SCHOOL_INFO_MUTATION = gql`
  mutation editSchoolInfo($input: editSchoolInfoInput!) {
    editSchoolInfo(input: $input) {
      phoneNumber
    }
  }
`;

export const EDIT_VISIBILITY_INFO_MUTATION = gql`
  mutation editVisibility($isVisible: Boolean!) {
    editVisibility(isVisible: $isVisible) {
      isVisible
    }
  }
`;

export const EDIT_TUTOR_VISIBILITY_MUTATION = gql`
  mutation editVisibilityV2(
    $isVisible: Boolean!
    $schoolVisible: Boolean!
    $homeVisible: Boolean!
  ) {
    editVisibilityV2(
      isVisible: $isVisible
      schoolVisible: $schoolVisible
      homeVisible: $homeVisible
    ) {
      isVisible
      locations {
        coordinates
        type
      }
    }
  }
`;

export const EDIT_PAYOUT_PHONE_AND_METHOD = gql`
  mutation($payoutPhoneNumber: String!, $prefPaymentMethod: String!) {
    editPayoutPhoneNumber(payoutPhoneNumber: $payoutPhoneNumber)
    editPreferredPaymentMethod(prefPaymentMethod: $prefPaymentMethod)
  }
`;

export const EDIT_PAYOUT_PHONE = gql`
  mutation($payoutPhoneNumber: String!) {
    editPayoutPhoneNumber(payoutPhoneNumber: $payoutPhoneNumber)
  }
`;

export const EDIT_PRIMARY_DEFAULT_PAYOUT_OPTION = gql`
  mutation editPrimaryPayoutOption($primaryPayoutMethod: String!) {
    editPrimaryPayoutOption(primaryPayoutMethod: $primaryPayoutMethod)
  }
`;

export const EDIT_TIME_ZONE_AND_LOCATION_MUTATION = gql`
  mutation($timeZoneId: String!, $zipCode: String!) {
    editTimeZoneId(timeZoneId: $timeZoneId) {
      timeZoneId
    }
    editLocationV2(zipCode: $zipCode) {
      currentLocation {
        zip
        city
        state
      }
    }
  }
`;

export const EDIT_TIME_ZONE_MUTATION = gql`
  mutation editTimeZoneId($timeZoneId: String!) {
    editTimeZoneId(timeZoneId: $timeZoneId) {
      timeZoneId
    }
  }
`;

export const EDIT_LOCATION_MUTATION = gql`
  mutation editLocationV2($zipCode: String!) {
    editLocationV2(zipCode: $zipCode) {
      currentLocation {
        zip
        city
        state
      }
    }
  }
`;

export const CREATE_ACTIVITY_POST = gql`
  mutation createProfileActivity($input: ProfileActivityInput!) {
    createProfileActivity(input: $input) {
      id
    }
  }
`;

// This is the default session booking API
// as of Jan 2021
export const CREATE_SESSION_V2 = gql`
  mutation createSession($input: CreateSessionInput!) {
    createSession(input: $input) {
      session {
        id
        subject
        status
        sessionType
        participants {
          user {
            id
          }
          userType
          joinedTime
          leftTime
          sessionPayoutStatus
          tutorBasePaymentAmount
          remaining
          hourlyRate
        }
        startTime
        endTime
        processingTime
        cancellationPolicyTime
        timeZoneId
        tutoringAmount
        meetingSolution
        isInPerson
        joinUrl
        zoomOptions {
          tutorflyBookerId
          meetingId
          join_url
          host_id
          start_url
          password
        }
        lessonspaceOptions {
          roomId
        }
        physicalSessionOptions {
          locationName
          locationPoint {
            type
            coordinates
          }
        }
      }
    }
  }
`;

// RequestTutor.jsx

export const REQUEST_A_TUTOR_QUERY = gql`
  query requestATutor($input: requestATutorInput!) {
    requestATutor(input: $input)
  }
`;

export const GET_TUTOR_LIST_QUERY_ONBOARDING_COMPLETE = gql`
  query getTutorList {
    getTutorList {
      user {
        profile {
          firstName
          lastName
          school
          profilePicture {
            src
            rotation
          }
        }
      }
    }
  }
`;

export const GET_TUTEE_LIST_QUERY = gql`
  query bookSessionTuteeQuery {
    getTuteeList {
      user {
        id
        profile {
          firstName
          lastName
          creditCardData {
            brand
            last4
            expMonth
            expYear
            isDefault
          }
        }
      }
      type
    }
  }
`;

export const GET_TUTOR_LIST_QUERY = gql`
  query bookSessionTutorQuery {
    getTutorList {
      user {
        id
        profile {
          firstName
          lastName
        }
      }
      type
    }
  }
`;

export const GET_TUTEE_PAIR_LIST = gql`
  query getPairedTuteeList {
    getPairedTuteeList {
      id
      hourlyWage
      user {
        id
        profile {
          firstName
          lastName
          profilePicture {
            src
            rotation
          }
          creditCardData {
            isDefault
          }
        }
      }
      hourlyWage
    }
  }
`;

export const GET_TUTOR_PAIR_LIST = gql`
  query getPairedTutorList {
    getPairedTutorList {
      id
      hourlyWage
      user {
        id
        profile {
          firstName
          lastName
          profilePicture {
            src
            rotation
          }
        }
      }
      hourlyWage
    }
  }
`;

// AddPaymentMethod.jsx

export const ADD_PAYMENT_METHOD_MUTATION = gql`
  mutation addPaymentMethodEditProfile($input: addPaymentMethodInput!) {
    addPaymentMethod(input: $input) {
      brand
      last4
      expMonth
      expYear
      isDefault
    }
  }
`;

export const GET_CREDIT_CARD_QUERY = gql`
  query loadCreditCardInfo {
    getCreditCardData {
      brand
      last4
      expMonth
      expYear
      isDefault
    }
  }
`;

export const GET_STRIPE_CONNECT_LOGIN_LINK = gql`
  query getStripeConnectLoginLink {
    getStripeConnectLoginLink
  }
`;

export const CREATE_STRIPE_CONNECT_ACCOUNT = gql`
  mutation associateStripeConnect($authCode: String!) {
    associateStripeConnect(authCode: $authCode)
  }
`;

export const INTEGRATE_ZOOM_OAUTH = gql`
  mutation associateZoom($authCode: String!) {
    associateZoom(authCode: $authCode)
  }
`;

export const CHECK_STRIPE_CONNECT_ACCOUNT_VERIFIED = gql`
  mutation checkStripeConnectAccountVerified {
    checkStripeConnectAccountVerified
  }
`;

export const EDIT_PAYOUT_PREFERENCE_MUTATION = gql`
  mutation editPrefPaymentMethod($prefPaymentMethodInput: String!) {
    editPrefPaymentMethod(prefPaymentMethodInput: $prefPaymentMethodInput)
  }
`;

export const EDIT_PAID_PREFERENCE_MUTATION = gql`
  mutation editPaidPreference($paidPreferenceInput: paidPreferenceInput!) {
    editPaidPreference(paidPreferenceInput: $paidPreferenceInput)
  }
`;

export const GET_USER_CONVERSATIONS = gql`
  query getUser {
    getCurrentUserV2 {
      id
      tuteePairs {
        tutee {
          id
        }
      }
      tutorPairs {
        tutor {
          id
        }
      }
      conversations {
        conversation {
          id
          lastMessage {
            id
            body
            date
            isRead
            type
            filename
            fromUser {
              id
            }
          }
          participants {
            id
            profile {
              firstName
              lastName
              profilePicture {
                src
              }
              bio
              school
              currentSchool {
                name
                city
                state
              }
              currentLocation {
                city
                state
              }
              dailyAvaliability
              avaliabilityDesc
              profileId
              avgReviewScore
              gradesForTutoring
              gradeLevel
              gpa
              profileTypes
              clientProfileType
              isHighSchoolTutor
              gradeLevel
              gpa
              creditCardData {
                isDefault
              }
            }
          }
          tutoringRequest {
            id
            from {
              id
            }
            subject
            gradeLevel
            sessionType
            zipCode
            city
            state
            daysAvailable
            timeAvailable
            note
            createdAt
            files {
              filename
              mimetype
              url
            }
          }
          pair {
            id
            hourlyWage
            tutorHourlyWage
            tutor {
              id
              profile {
                school
                isHighSchoolTutor
              }
            }
            tutee {
              id
              profile {
                clientProfileType
              }
            }
            sessionsTutored
            type
            clientReviewId
          }
        }
        conversant {
          id
          profile {
            firstName
            lastName
            profilePicture {
              src
            }
            profileTypes
            bio
            school
            currentSchool {
              name
              city
              state
            }
            currentLocation {
              city
              state
            }
            dailyAvaliability
            avaliabilityDesc
            profileId
            avgReviewScore
            gradesForTutoring
            gradeLevel
            gpa
            profileTypes
            clientProfileType
            isHighSchoolTutor
          }
        }
      }
    }
  }
`;

export const GET_PAGINATED_CONVERSATION_LIST = gql`
  query getPendingTutorRequestConversations(
    $pageNumber: Int!
    $isActiveContext: Boolean!
    $isTutorContext: Boolean!
  ) {
    getPendingTutorRequestConversations(
      pageNumber: $pageNumber
      isActiveContext: $isActiveContext
      isTutorContext: $isTutorContext
    ) {
      conversations {
        conversation {
          id
          lastMessage {
            id
            body
            date
            isRead
            type
            filename
            fromUser {
              id
            }
          }
          participants {
            id
            profile {
              firstName
              lastName
              profilePicture {
                src
              }
              bio
              school
              currentSchool {
                name
                city
                state
              }
              currentLocation {
                city
                state
              }
              dailyAvaliability
              avaliabilityDesc
              profileId
              avgReviewScore
              gradesForTutoring
              gradeLevel
              gpa
              profileTypes
              clientProfileType
              isHighSchoolTutor
              gradeLevel
              gpa
              creditCardData {
                isDefault
              }
            }
          }
          tutoringRequest {
            id
            from {
              id
            }
            subject
            gradeLevel
            sessionType
            zipCode
            city
            state
            daysAvailable
            timeAvailable
            note
            createdAt
            files {
              filename
              mimetype
              url
            }
          }
          pair {
            id
            hourlyWage
            tutorHourlyWage
            tutor {
              id
              profile {
                school
                isHighSchoolTutor
              }
            }
            tutee {
              id
              profile {
                clientProfileType
                creditCardData {
                  isDefault
                }
              }
            }
            sessionsTutored
            type
            clientReviewId
            isActive
          }
        }
        conversant {
          id
          profile {
            firstName
            lastName
            profilePicture {
              src
            }
            profileTypes
            bio
            school
            currentSchool {
              name
              city
              state
            }
            currentLocation {
              city
              state
            }
            dailyAvaliability
            avaliabilityDesc
            profileId
            avgReviewScore
            gradesForTutoring
            gradeLevel
            gpa
            profileTypes
            clientProfileType
            isHighSchoolTutor
          }
        }
      }
    }
    getPaginatedConversationList(
      pageNumber: $pageNumber
      isActiveContext: $isActiveContext
      isTutorContext: $isTutorContext
    ) {
      exhaustedConversations
      conversations {
        conversation {
          id
          lastMessage {
            id
            body
            date
            isRead
            type
            filename
            fromUser {
              id
            }
          }
          participants {
            id
            profile {
              firstName
              lastName
              profilePicture {
                src
              }
              bio
              school
              currentSchool {
                name
                city
                state
              }
              currentLocation {
                city
                state
              }
              dailyAvaliability
              avaliabilityDesc
              profileId
              avgReviewScore
              gradesForTutoring
              gradeLevel
              gpa
              profileTypes
              clientProfileType
              isHighSchoolTutor
              gradeLevel
              gpa
              creditCardData {
                isDefault
              }
            }
          }
          tutoringRequest {
            id
            from {
              id
            }
            subject
            gradeLevel
            sessionType
            zipCode
            city
            state
            daysAvailable
            timeAvailable
            note
            createdAt
            files {
              filename
              mimetype
              url
            }
          }
          pair {
            id
            hourlyWage
            tutorHourlyWage
            tutor {
              id
              profile {
                school
                isHighSchoolTutor
              }
            }
            tutee {
              id
              profile {
                clientProfileType
              }
            }
            sessionsTutored
            type
            clientReviewId
            isActive
          }
        }
        conversant {
          id
          profile {
            firstName
            lastName
            profilePicture {
              src
            }
            profileTypes
            bio
            school
            currentSchool {
              name
              city
              state
            }
            currentLocation {
              city
              state
            }
            dailyAvaliability
            avaliabilityDesc
            profileId
            avgReviewScore
            gradesForTutoring
            gradeLevel
            gpa
            profileTypes
            clientProfileType
            isHighSchoolTutor
          }
        }
      }
    }
  }
`;

export const UPDATE_CONVERSATION_ARCHIVE = gql`
  mutation updateArchiveState($conversationId: String!, $archived: Boolean!) {
    updateArchiveState(conversationId: $conversationId, archived: $archived) {
      id
    }
  }
`;

export const UNPAIR_WITH_USER = gql`
  mutation unPairWithUser($tutorId: String!, $tuteeId: String!) {
    unPairWithUser(tutorId: $tutorId, tuteeId: $tuteeId)
  }
`;

export const GET_CONVERSATION_LIST = gql`
  query getConversations {
    getConversations {
      id
    }
  }
`;

export const GET_MESSAGES = gql`
  query getConversation($users: [ID]!, $limit: Int!) {
    getMessagesForConversation(users: $users, limit: $limit) {
      messages {
        id
        body
        date
        isRead
        type
        filename
        fromUser {
          id
          profile {
            firstName
            lastName
            profilePicture {
              src
            }
          }
        }
      }
      hasMoreMessages
    }
  }
`;

// MessagesList.jsx, MessagesDashboard.js

export const SEND_MESSAGE = gql`
  mutation sendMessage($body: String!, $targetId: ID!) {
    sendMessageMutation(body: $body, targetId: $targetId) {
      id
      body
      date
      isRead
      type
      filename
      fromUser {
        id
        profile {
          firstName
          lastName
          profilePicture {
            src
          }
        }
      }
    }
  }
`;

export const READ_MESSAGE = gql`
  mutation readMessage($messageId: ID!) {
    readMessageMutation(messageId: $messageId) {
      id
      isRead
    }
  }
`;

export const SEND_ATTACHMENT_MESSAGE = gql`
  mutation sendAttachmentMessage($file: Upload!, $targetId: ID!) {
    sendAttachmentMessage(file: $file, targetId: $targetId) {
      id
      body
      date
      isRead
      type
      filename
      fromUser {
        id
        profile {
          firstName
          lastName
          profilePicture {
            src
          }
        }
      }
    }
  }
`;

// VideoButton.jsx

export const OPEN_LESSONSPACE_SESSION = gql`
  query openLessonspaceSession($tuteeId: ID!, $tutorId: ID!, $sessionId: ID) {
    openLessonspaceSession(
      tuteeId: $tuteeId
      tutorId: $tutorId
      sessionId: $sessionId
    )
  }
`;

export const OPEN_GROUP_LESSONSPACE_SESSION = gql`
  query openGroupLessonspaceSession(
    $subject: String!
    $sessionId: String!
    $isTutorContext: Boolean!
    $isAdminContext: Boolean
  ) {
    openGroupLessonspaceSession(
      subject: $subject
      sessionId: $sessionId
      isTutorContext: $isTutorContext
      isAdminContext: $isAdminContext
    )
  }
`;

export const REMOVE_USER = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(userId: $userId)
  }
`;

// Reporting endpoints

export const REPORT_USER_V2 = gql`
  query reportUserV2($input: reportUserInputV2!) {
    reportUserV2(input: $input)
  }
`;

export const REPORT_ONLINE_LESSON_ISSUE = gql`
  query reportOnlineLessonIssue($input: reportOnlineLessonIssueInput!) {
    reportOnlineLessonIssue(input: $input)
  }
`;

export const ADMIN_REPORT_ONLINE_LESSON_ISSUE = gql`
  mutation adminReportOnlineLessonIssue($input: reportOnlineLessonIssueInput!) {
    adminReportOnlineLessonIssue(input: $input)
  }
`;

// ClientReferrals.jsx

export const SEND_EMAIL_REFERRALS = gql`
  mutation sendEmailReferrals($emailReferrals: [String]!) {
    sendEmailReferrals(emailReferrals: $emailReferrals)
  }
`;

export const SEND_TEXT_REFERRALS = gql`
  mutation sendTextReferrals($textReferrals: [String]!) {
    sendTextReferrals(textReferrals: $textReferrals)
  }
`;

export const SEND_TUTOR_TO_TUTOR_EMAIL_REFERRALS = gql`
  mutation sendTutorToTutorEmailReferrals($emailReferrals: [String]!) {
    sendTutorToTutorEmailReferrals(emailReferrals: $emailReferrals)
  }
`;

export const SEND_TUTOR_TO_TUTOR_TEXT_REFERRALS = gql`
  mutation sendTutorToTutorTextReferrals($textReferrals: [String]!) {
    sendTutorToTutorTextReferrals(textReferrals: $textReferrals)
  }
`;

export const SEND_TUTOR_TO_CLIENT_EMAIL_REFERRALS = gql`
  mutation sendTutorToClientEmailReferrals($emailReferrals: [String]!) {
    sendTutorToClientEmailReferrals(emailReferrals: $emailReferrals)
  }
`;

export const SEND_TUTOR_TO_CLIENT_TEXT_REFERRALS = gql`
  mutation sendTutorToClientTextReferrals($textReferrals: [String]!) {
    sendTutorToClientTextReferrals(textReferrals: $textReferrals)
  }
`;

export const GET_CONVERSATION_BY_ID = gql`
  query getConversationByID($conversationID: ID!) {
    getConversationByID(conversationID: $conversationID) {
      id
      lastMessage {
        id
        body
        date
        isRead
        type
        filename
        fromUser {
          id
        }
      }
      participants {
        id
        profile {
          firstName
          lastName
          profilePicture {
            src
          }
          bio
          school
          currentSchool {
            name
            city
            state
          }
          currentLocation {
            city
            state
          }
          dailyAvaliability
          avaliabilityDesc
          profileId
          avgReviewScore
          gradesForTutoring
          gradeLevel
          gpa
          profileTypes
          clientProfileType
          isHighSchoolTutor
          gradeLevel
          gpa
          creditCardData {
            isDefault
          }
        }
      }
      tutoringRequest {
        id
        from {
          id
        }
        subject
        gradeLevel
        sessionType
        zipCode
        city
        state
        daysAvailable
        timeAvailable
        note
        createdAt
        files {
          filename
          mimetype
          url
        }
      }
      pair {
        id
        hourlyWage
        tutorHourlyWage
        tutor {
          id
          profile {
            school
            isHighSchoolTutor
          }
        }
        tutee {
          id
          profile {
            clientProfileType
          }
        }
        sessionsTutored
        type
      }
    }
  }
`;

export const SET_TUTOR_SESSION_SUMMARY = gql`
  mutation setTutorSessionSummary(
    $summary: String!
    $sessionId: ID!
    $isInitialSummaryPost: Boolean!
  ) {
    setTutorSessionSummary(
      summary: $summary
      sessionId: $sessionId
      isInitialSummaryPost: $isInitialSummaryPost
    )
  }
`;

export const SET_CLIENT_SESSION_FEEDBACK = gql`
  mutation setClientSessionFeedback($feedback: String!, $sessionId: ID!) {
    setClientSessionFeedback(feedback: $feedback, sessionId: $sessionId)
  }
`;

export const USER_NOTIFICATIONS_QUERY = gql`
  query getNotifications {
    getNotificationsQuery {
      id
      type
      details
      isRead
      link
    }
  }
`;

// NotificationItem.jsx

export const READ_NOTIFICATION_MUTATION = gql`
  mutation readNotification($notificationId: ID!) {
    readNotification(notificationId: $notificationId)
  }
`;

// ClearNotifications.jsx

export const CLEAR_NOTIFICATIONS = gql`
  mutation clearNotifications {
    clearNotifications
  }
`;

// CreditCard.jsx

export const REMOVE_PAYMENT_METHOD_MUTATION = gql`
  mutation creditCardRemoval($input: generalPaymentMethodInput!) {
    removePaymentMethod(input: $input) {
      brand
      last4
      expMonth
      expYear
      isDefault
    }
  }
`;

export const SET_DEFAULT_PAYMENT_METHOD_MUTATION = gql`
  mutation creditCardDefault($input: generalPaymentMethodInput!) {
    setDefaultPaymentMethod(input: $input) {
      brand
      last4
      expMonth
      expYear
      isDefault
    }
  }
`;

export const ZIP_CODE_SOFT_SEARCH = gql`
  query softSearchZipCode($zipInput: String!) {
    softSearchZipCode(zipInput: $zipInput) {
      id
      city
      state
    }
  }
`;

export const ZIP_CODE_SOFT_SEARCH_V2 = gql`
  query softSearchZipCodeV2($zipInput: String!, $limit: Int!) {
    softSearchZipCodeV2(zipInput: $zipInput, limit: $limit) {
      id
      city
      state
    }
  }
`;

export const CITY_NAME_SOFT_SEARCH = gql`
  query softSearchCityName($cityName: String!, $limit: Int!) {
    softSearchCityName(cityName: $cityName, limit: $limit) {
      id
      city
      state
    }
  }
`;

export const USER_NAME_SOFT_SEARCH = gql`
  query getUserSuggestionsByName($name: String!, $searchTutors: Boolean!) {
    getUserSuggestionsByName(name: $name, searchTutors: $searchTutors) {
      id
      email {
        address
      }
      profile {
        firstName
        lastName
        currentSchool {
          name
        }
      }
    }
  }
`;

export const TUTOR_NAME_SOFT_SEARCH = gql`
  query getTutorsByName($name: String!, $pageNumber: Int) {
    getTutorsByName(name: $name, pageNumber: $pageNumber) {
      id
      profile {
        profileTypes
        firstName
        lastName
        school
        classes {
          course
          subject
          teacher
          school
        }
        dailyAvaliability
        gradesForTutoring
        gradeLevel
        gpa
        bio
        profilePicture {
          src
          rotation
        }
        hourlyWage
        profilePageId
        profileId
        avgReviewScore
        isVisible
      }
    }
  }
`;

export const GET_TOP_TUTORS = gql`
  query getTopActiveTutors($numberTutors: Int!) {
    getTopActiveTutors(numberTutors: $numberTutors) {
      id
      profile {
        profileTypes
        firstName
        lastName
        school
        classes {
          course
          subject
          teacher
          school
        }
        dailyAvaliability
        gradesForTutoring
        gradeLevel
        gpa
        bio
        profilePicture {
          src
          rotation
        }
        hourlyWage
        profilePageId
        profileId
        avgReviewScore
        isVisible
      }
    }
  }
`;

export const COURSE_NAME_SOFT_SEARCH = gql`
  query softSearchCourses($name: String!) {
    softSearchCourses(name: $name) {
      id
      fullCourseName
      atSchools
      schoolId
      coreSubjects
      isHighSchoolCourse
    }
  }
`;

export const GET_TUTORS_TO_ONBOARD = gql`
  query getTutorsToOnboard($numTutors: Int!) {
    getTutorsToOnboard(numTutors: $numTutors) {
      id
      createdAt
      email {
        address
      }
      profile {
        firstName
        lastName
        phoneNumber
        profileId
        timeZoneId
        profilePicture {
          src
          rotation
        }
        hasSignedAgreement
        primaryPayoutMethod
        onboardVideoLink
        tutorQuizSubmitted
        tutorOnboardingSubmitTime
      }
    }
  }
`;

export const UN_ONBOARDED_TUTOR_NAME_SOFT_SEARCH = gql`
  query getUnOnboardedTutorsByName($name: String!) {
    getUnOnboardedTutorsByName(name: $name) {
      id
      email {
        address
      }
      profile {
        firstName
        lastName
        profileId
        profilePicture {
          src
          rotation
        }
        currentSchool {
          name
        }
        hasSignedAgreement
        hasStripeConnectAccount
        stripeConnectVerified
        onboardVideoLink
        tutorQuizSubmitted
      }
    }
  }
`;

export const SEARCH_ACTIVE_TUTOR_TEXT = gql`
  query searchActiveTutorText(
    $input: String!
    $sortByType: String!
    $pageNumber: Int!
    $zip: String
    $gradeFilter: String
    $tutorGradeFilter: [String]
  ) {
    searchActiveTutorText(
      input: $input
      sortByType: $sortByType
      pageNumber: $pageNumber
      zip: $zip
      gradeFilter: $gradeFilter
      tutorGradeFilter: $tutorGradeFilter
    ) {
      exhaustedTutors
      users {
        id
        createdAt
        email {
          address
        }
        profile {
          firstName
          lastName
          bio
          phoneNumber
          profileId
          timeZoneId
          school
          gradeLevel
          hoursTutored
          profilePicture {
            src
            rotation
          }
          hourlyWage
          hasSignedAgreement
          primaryPayoutMethod
          onboardVideoLink
          tutorQuizSubmitted
          tutorOnboardingSubmitTime
        }
      }
    }
  }
`;

export const SEARCH_ACTIVE_TUTOR_TEXT_BY_LOCATION = gql`
  query searchActiveTutorTextByLocation(
    $input: String!
    $sortByType: String!
    $pageNumber: Int!
    $zip: String!
    $gradeFilter: String
    $tutorGradeFilter: [String]
  ) {
    searchActiveTutorTextByLocation(
      input: $input
      sortByType: $sortByType
      pageNumber: $pageNumber
      zip: $zip
      gradeFilter: $gradeFilter
      tutorGradeFilter: $tutorGradeFilter
    ) {
      exhaustedTutors
      users {
        id
        createdAt
        email {
          address
        }
        profile {
          firstName
          lastName
          bio
          phoneNumber
          profileId
          timeZoneId
          school
          gradeLevel
          hoursTutored
          profilePicture {
            src
            rotation
          }
          hourlyWage
          hasSignedAgreement
          primaryPayoutMethod
          onboardVideoLink
          tutorQuizSubmitted
          tutorOnboardingSubmitTime
        }
      }
    }
  }
`;

export const CLIENT_NAME_SOFT_SEARCH = gql`
  query clientNameSoftSearch($name: String!) {
    clientNameSoftSearch(name: $name) {
      id
      email {
        address
      }
      profile {
        firstName
        lastName
        profilePicture {
          src
          rotation
        }
        bundleAmt
      }
    }
  }
`;

export const INSTITUTION_NAME_SOFT_SEARCH = gql`
  query softSearchInstitutions($name: String!) {
    softSearchInstitutions(name: $name) {
      id
      name
      alias
      city
      state
    }
  }
`;

export const ONBOARDED_TUTOR_NAME_SOFT_SEARCH = gql`
  query getOnboardedTutorsByName($name: String!) {
    getOnboardedTutorsByName(name: $name) {
      id
      email {
        address
      }
      profile {
        firstName
        lastName
        profileId
        profilePicture {
          src
          rotation
        }
        currentSchool {
          name
        }
        hasSignedAgreement
        hasStripeConnectAccount
        stripeConnectVerified
        gradeLevel
        timeZoneId
        classes {
          subject
          course
        }
      }
    }
  }
`;

export const ADMIN_PAIR_DEFAULT_USERS = gql`
  mutation adminPairDefaultUsers(
    $defaultPairClientId: String!
    $defaultPairTutorId: String!
  ) {
    adminPairDefaultUsers(
      defaultPairClientId: $defaultPairClientId
      defaultPairTutorId: $defaultPairTutorId
    )
  }
`;

export const ADMIN_CORE_USER_ANY_SEARCH = gql`
  query coreUserAnySearch($search: String!, $isTutorContext: Boolean) {
    coreUserAnySearch(search: $search, isTutorContext: $isTutorContext) {
      id
      email {
        address
      }
      profile {
        firstName
        lastName
        profileTypes
        profilePicture {
          src
          rotation
        }
        bundleAmt
        hourlyWage
        phoneNumber
        profileId
        clientDefaultPairRate
        tutorDefaultPairRate
      }
      createdAt
    }
  }
`;

// Default Sign Up API

export const EMAIL_SIGNUP_MUTATION = gql`
  mutation emailSignup($input: emailSignupInput!) {
    emailSignup(input: $input) {
      token
    }
  }
`;

export const GOOGLE_SIGNUP_MUTATION = gql`
  mutation googleSignup($input: providerSignupInput!) {
    googleSignup(input: $input) {
      token
    }
  }
`;

export const FACEBOOK_SIGNUP_MUTATION = gql`
  mutation facebookSignup($input: providerSignupInput!) {
    facebookSignup(input: $input) {
      token
    }
  }
`;

// Referral Sign Up API

export const EMAIL_SIGNUP_REFERRAL_MUTATION = gql`
  mutation emailSignupReferral($input: emailSignupInput!, $profileId: String!) {
    emailSignupReferral(input: $input, profileId: $profileId) {
      token
    }
  }
`;

export const EMAIL_SIGNUP_PERSONALIZED_MUTATION = gql`
  mutation emailSignupClientPersonalized(
    $input: PersonalizedEmailSignupInput!
  ) {
    emailSignupClientPersonalized(input: $input) {
      token
    }
  }
`;

export const EMAIL_SIGNUP_TUTOR_CLIENT_REFERRAL_MUTATION = gql`
  mutation emailSignupTutorToClientReferral(
    $input: emailSignupInput!
    $profileId: String!
  ) {
    emailSignupTutorToClientReferral(input: $input, profileId: $profileId) {
      token
    }
  }
`;

// For referrals from company partnerships, such as 2020 Cprime x Tutorfly
export const CORPORATE_REFERRAL_SIGNUP_MUTATION = gql`
  mutation corporateReferralSignup(
    $input: emailSignupInput!
    $subject: String!
  ) {
    corporateReferralSignup(input: $input, subject: $subject) {
      token
    }
  }
`;

export const GOOGLE_SIGNUP_REFERRAL_MUTATION = gql`
  mutation googleSignupReferral(
    $input: providerSignupInput!
    $profileId: String!
  ) {
    googleSignupReferral(input: $input, profileId: $profileId) {
      token
    }
  }
`;

export const GOOGLE_SIGNUP_TUTOR_CLIENT_REFERRAL_MUTATION = gql`
  mutation googleSignupTutorToClientReferral(
    $input: providerSignupInput!
    $profileId: String!
  ) {
    googleSignupTutorToClientReferral(input: $input, profileId: $profileId) {
      token
    }
  }
`;

export const FACEBOOK_SIGNUP_REFERRAL_MUTATION = gql`
  mutation facebookSignupReferral(
    $input: providerSignupInput!
    $profileId: String!
  ) {
    facebookSignupReferral(input: $input, profileId: $profileId) {
      token
    }
  }
`;

export const FACEBOOK_SIGNUP_TUTOR_CLIENT_REFERRAL_MUTATION = gql`
  mutation facebookSignupTutorToClientReferral(
    $input: providerSignupInput!
    $profileId: String!
  ) {
    facebookSignupTutorToClientReferral(input: $input, profileId: $profileId) {
      token
    }
  }
`;

export const GOOGLE_LOGIN_MUTATION = gql`
  mutation googleLogin($accessToken: String!) {
    googleLogin(accessToken: $accessToken)
  }
`;

export const FACEBOOK_LOGIN_MUTATION = gql`
  mutation facebookLogin($accessToken: String!) {
    facebookLogin(accessToken: $accessToken)
  }
`;

// Availability.jsx

export const EDIT_AVAILABILITY_MUTATION = gql`
  mutation editAvailabilityMutation($input: editAvailabilityInput!) {
    editAvailability(input: $input) {
      avaliabilityDesc
      dailyAvaliability
    }
  }
`;

// SchoolInfo/index

export const SEARCH_COLLEGE_NAME = gql`
  query searchCollegeByName($name: String!, $city: String!, $state: String!) {
    searchCollegeByName(name: $name, city: $city, state: $state) {
      _id
      name
      city
      state
    }
  }
`;

export const SEARCH_HIGH_SCHOOL_NAME = gql`
  query searchHighSchoolByName(
    $name: String!
    $city: String!
    $state: String!
  ) {
    searchHighSchoolByName(name: $name, city: $city, state: $state) {
      _id
      name
      city
      state
    }
  }
`;

// HighSchoolCourses/index

export const HIGH_SCHOOL_CLASSES_SEARCH = gql`
  query searchHighSchoolCourses($courseName: String!) {
    searchHighSchoolCourses(courseName: $courseName) {
      name
    }
  }
`;

// CollegeCourses/index

export const COLLEGE_CLASSES_SEARCH = gql`
  query searchCollegeCoursesByID($collegeId: String!, $courseName: String!) {
    searchCollegeCoursesByID(collegeId: $collegeId, courseName: $courseName) {
      courseTitle
      courseCode
    }
  }
`;

// AddCollegeClasses.jsk
// TODO: THIS IS TEMP TIL WE ADD ID TO USER.PROFILE.SCHOOL THEN IT WILL BE REPLACED WITH THE FUNC ABOVE

export const COLLEGE_CLASSES_SEARCH_NAME = gql`
  query searchCollegeCoursesByName(
    $collegeName: String!
    $courseName: String!
  ) {
    searchCollegeCoursesByName(
      collegeName: $collegeName
      courseName: $courseName
    ) {
      courseTitle
      courseCode
    }
  }
`;

// CourseRecommendation.jsx

export const SUBMIT_CLASS_ADD_REQUEST = gql`
  mutation submitClassAddRequest($input: courseRecommendationInput!) {
    submitClassAddRequest(input: $input)
  }
`;

export const SUBMIT_SCHOOL_EDIT = gql`
  mutation editSchool(
    $newSchool: String!
    $newSchoolId: String!
    $isHighSchool: Boolean!
  ) {
    editSchool(
      newSchool: $newSchool
      newSchoolId: $newSchoolId
      isHighSchool: $isHighSchool
    ) {
      currentSchool {
        name
        city
        state
      }
    }
  }
`;

// ApplyToTutorForm.jsx

export const TUTOR_APPLICATION_QUERY = gql`
  query applyToBeTutor($input: sendTutoringAppInput!) {
    sendTutoringAppQuery(input: $input)
  }
`;

export const TUTOR_APPLICATION_V2 = gql`
  mutation signUpTutor($input: sendTutoringAppInput!) {
    signUpTutor(input: $input) {
      token
    }
  }
`;

export const TUTOR_APPLICATION_TUTOR_REFERRAL = gql`
  mutation signUpTutorByTutorReferral(
    $input: sendTutoringAppInput!
    $profileId: String!
  ) {
    signUpTutorByTutorReferral(input: $input, profileId: $profileId) {
      token
    }
  }
`;

export const EXISTING_STUDENT_SUBMIT_TUTOR_APPLICATION = gql`
  mutation studentApplyToTutor($input: sendTutoringAppInput!) {
    studentApplyToTutor(input: $input) {
      id
    }
  }
`;

export const TUTOR_APPLICATION_ONBOARDING_COMPLETE = gql`
  mutation submitTutorOnboardingComplete {
    submitTutorOnboardingComplete
  }
`;

// LeaveEditReviewDialog.jsx

export const LEAVE_REVIEW = gql`
  mutation addReviewMutation($input: addReviewInput!) {
    addReviewMutation(input: $input) {
      review {
        id
        clientFirstName
        clientLastName
        clientProfilePic {
          src
          rotation
        }
        score
        comments
      }
      updatedAvgScore
    }
  }
`;

export const EDIT_REVIEW = gql`
  mutation editReviewMutation($input: editReviewInput!) {
    editReviewMutation(input: $input) {
      review {
        id
        clientFirstName
        clientLastName
        clientProfilePic {
          src
          rotation
        }
        score
        comments
      }
      updatedAvgScore
    }
  }
`;

// Dashboard/actinos/index.js

export const GET_TUTORING_REQUESTS_OF_TUTOR_QUERY = gql`
  query loadRequestsOfTutorRedux {
    getRequestsOfTutorQuery {
      id
      from {
        id
        profile {
          firstName
          lastName
          profilePicture {
            src
          }
        }
      }
      subject
      gradeLevel
      sessionType
      zipCode
      city
      state
      daysAvailable
      timeAvailable
      note
      createdAt
      files {
        filename
        mimetype
        url
      }
    }
  }
`;

export const GET_TUTORING_REQUESTS_OF_TUTEE_QUERY = gql`
  query loadRequestsOfTuteeRedux {
    getRequestsOfTuteeQuery {
      id
      note
      createdAt
      to {
        id
        profile {
          firstName
          lastName
          profilePicture {
            src
          }
        }
      }
      subject
      gradeLevel
      sessionType
      zipCode
      city
      state
      daysAvailable
      timeAvailable
    }
  }
`;

export const ACCEPT_SESSION_TUTORING_REQUEST = gql`
  mutation acceptSessionTutoringRequest($requestId: String!) {
    acceptSessionTutoringRequest(requestId: $requestId)
  }
`;

export const ACCEPT_PAIR_TUTORING_REQUEST = gql`
  mutation acceptPairTutoringRequest($requestId: String!) {
    acceptPairTutoringRequest(requestId: $requestId)
  }
`;

export const ADD_ACTIVE_TUTOR_MUTATION = gql`
  mutation addTutorToActiveList(
    $selections: ActiveTutorListSelections!
    $entryInfo: ActiveTutorListEntryInfo!
  ) {
    addTutorToActiveList(selections: $selections, entryInfo: $entryInfo)
  }
`;

// AboutMe.jsx

export const EDIT_BIO_MUTATION = gql`
  mutation editBioMutation($bio: String) {
    editDescription(description: $bio)
  }
`;

export const EDIT_HOURLY_WAGE_MUTATION = gql`
  mutation editHourlyWage($hourlyWage: Float!) {
    editHourlyWage(hourlyWage: $hourlyWage)
  }
`;

// EditProfilePicture.js

export const UPLOAD_PROFILE_PICTURE = gql`
  mutation uploadProfilePictureV2($file: Upload!, $rotation: String!) {
    uploadProfilePictureV2(file: $file, rotation: $rotation) {
      rotation
      src
    }
  }
`;

// RequestAttachments.js

export const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file) {
      filename
      mimetype
      url
    }
  }
`;

export const FLEX_INTAKE_MUTATION = gql`
  mutation submitFlexIntake($input: FLEXIntakeInput!) {
    submitFlexIntake(input: $input)
  }
`;

export const POST_SESSION_FEEDBACK = gql`
  mutation createPostSessionFeedback(
    $tutorId: String!
    $tuteeId: String!
    $q1Score: Int!
    $q2Score: Int!
    $q3Score: Int!
    $sessionId: String!
    $extraComments: String!
  ) {
    createPostSessionFeedback(
      tutorId: $tutorId
      tuteeId: $tuteeId
      q1Score: $q1Score
      q2Score: $q2Score
      q3Score: $q3Score
      sessionId: $sessionId
      extraComments: $extraComments
    )
  }
`;

export const ADD_FEEDBACK_TO_SESSION = gql`
  mutation addFeedbackToSessionV2($feedbackId: String!, $sessionId: String!) {
    addFeedbackToSessionV2(feedbackId: $feedbackId, sessionId: $sessionId)
  }
`;

export const CHECK_FOR_SESSION_FEEDBACK = gql`
  query checkForSessionFeedback($sessionId: String!) {
    checkForSessionFeedback(sessionId: $sessionId) {
      id
    }
  }
`;

export const GET_FORM_ASSESSMENT_FEEDBACK = gql`
  query getFormAssessmentResponses($sessionId: String!) {
    getFormAssessmentResponses(sessionId: $sessionId) {
      id
      userId
      responses {
        question
        response
      }
      receivingUserId
      sessionId
      formName
    }
  }
`;

export const SAVE_USER_LANGUAGE_PREFERENCE = gql`
  mutation saveUserLanguagePreference($languageTag: String!) {
    saveUserLanguagePreference(languageTag: $languageTag)
  }
`;

export const SEND_VERIFICATION_TOKEN = gql`
  mutation sendVerificationToken($sendTokenInput: SendTokenInput!) {
    sendVerificationToken(sendTokenInput: $sendTokenInput)
  }
`;

export const VERIFY_TOKEN = gql`
  mutation verifyToken($verifyTokenInput: VerifyTokenInput!) {
    verifyToken(verifyTokenInput: $verifyTokenInput)
  }
`;

export const GET_SCHOOLOGY_LOGIN_URL = gql`
  query getSchoologyLoginUrl($redirectUrl: String!) {
    getSchoologyLoginUrl(redirectUrl: $redirectUrl)
  }
`;
