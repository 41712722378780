import { ProfileType } from 'shared/constants/index';
import moment from 'moment-timezone';
import {
  USER_QUERY,
  USER_HAS_CONNECTIONS,
  EDIT_TIME_ZONE_MUTATION,
} from 'shared/constants/gql-constants';
import { switchToClient, switchToTutor } from 'shared/actions/dashboard';
import {
  UPDATE_SUBSCRIPTION,
  UPDATE_CURRENT_USER,
  UPDATE_PARTIAL_USER,
  REMOVE_PAYMENT_METHOD,
  UPDATE_PAIRED_TUTORS,
  UPDATE_PAIRED_TUTEES,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  SET_BADGE_NOTIFICATION_AMOUNT,
  UPDATE_USER_CONNECTION_INFO,
  UPDATE_USER_CONNECTION_INFO_LOADING,
  UPDATE_USER_CONNECTION_ERROR,
  SET_URL_PATH,
} from '../types';

export const updateTutors = tutors => ({
  type: UPDATE_SUBSCRIPTION,
  payload: {
    subscription: 'tutors',
    data: tutors,
  },
});

export const setMessageBadgeCount = badgeCount => ({
  type: SET_BADGE_NOTIFICATION_AMOUNT,
  payload: { badgeCount },
});

export const updateCurrentUser = user => ({
  type: UPDATE_CURRENT_USER,
  payload: { currentUser: user },
});

export const removePaymentMethod = partialUser => (dispatch, getState) => {
  const { subscriptions: { currentUser } } = getState();
  currentUser.profile.creditCardData = null;
  dispatch({
    type: REMOVE_PAYMENT_METHOD,
    payload: {
      currentUser,
      partialUser,
    },
  });
};

export const updatePartialUser = partialUser => dispatch => {
  dispatch({
    type: UPDATE_PARTIAL_USER,
    payload: {
      partialUser,
    },
  });
};

export const setClassSet = newClassSet => dispatch => {
  dispatch(
    updatePartialUser({
      profile: {
        classes: newClassSet,
      },
    }),
  );
};

export const setSchool = newSchool => dispatch => {
  dispatch(
    updatePartialUser({
      profile: {
        school: newSchool,
      },
    }),
  );
};

export const setTutorRequestPreferences = preferences => dispatch => {
  dispatch(
    updatePartialUser({
      profile: {
        tutorRequestPreferences: preferences,
      },
    }),
  );
};

export const setUrlPath = urlPath => dispatch => {
  dispatch({
    type: SET_URL_PATH,
    payload: {
      urlPath,
    },
  });
};

// thunk for querying GraphQL for user object
// TODO: Eventually re-write this thunk out of existence. Prefer fetchUserDataAsync.
export const fetchUserData = client => dispatch => {
  client
    .query({
      fetchPolicy: 'network-only',
      query: USER_QUERY,
    })
    .then(({ data }) => {
      dispatch(updateCurrentUser(data.getCurrentUserV2));
      if (
        data.getCurrentUserV2.profile.profileTypes.includes(ProfileType.TUTOR)
      ) {
        dispatch(switchToTutor());
      } else {
        dispatch(switchToClient());
      }
    })
    .catch(err => {
      console.error(err);
    });
};

export const fetchConnectionSummary = client => async (dispatch, getState) => {
  const { dashboard: { userType } } = getState();
  try {
    dispatch({
      type: UPDATE_USER_CONNECTION_INFO_LOADING,
      payload: {
        hasUserConnectionsLoading: true,
      },
    });
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: USER_HAS_CONNECTIONS,
    });

    const hasUserConnections = data.hasConnections;
    const badgeCount = data.getPendingTutorRequestCount;

    dispatch({
      type: UPDATE_USER_CONNECTION_INFO,
      payload: {
        hasUserConnections,
      },
    });

    const isTutorContext = userType === ProfileType.TUTOR;
    if (isTutorContext) {
      // Add the messages badge count so tutors know how many open requests
      // they need to reply to
      dispatch(setMessageBadgeCount(badgeCount));
    }
  } catch (err) {
    dispatch({
      type: UPDATE_USER_CONNECTION_ERROR,
      payload: {
        connectionSummaryError: true,
      },
    });
  } finally {
    dispatch({
      type: UPDATE_USER_CONNECTION_INFO_LOADING,
      payload: {
        hasUserConnectionsLoading: false,
      },
    });
  }
};

export const fetchUserDataAsync = client => async dispatch => {
  try {
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: USER_QUERY,
    });

    const currentUser = data.getCurrentUserV2;
    const isTutor = currentUser.profile.profileTypes.includes(
      ProfileType.TUTOR,
    );
    dispatch(updateCurrentUser(currentUser));
    if (isTutor) {
      dispatch(switchToTutor());
    } else {
      dispatch(switchToClient());
    }
  } catch (err) {
    console.error(err);
  }
};

export const setUserTimeZone = (client, timeZoneId) => async dispatch => {
  client
    .mutate({
      mutation: EDIT_TIME_ZONE_MUTATION,
      variables: { timeZoneId },
    })
    .then(({ data }) => {
      dispatch(
        updatePartialUser({
          profile: {
            timeZoneId: data.editTimeZoneId.timeZoneId,
          },
        }),
      );
      moment.tz.setDefault(data.editTimeZoneId.timeZoneId);
    });
};

// thunk for setting user object to null
export const logoutUser = () => dispatch => {
  dispatch(updateCurrentUser(null));
};

export const updatePairedTutors = tutors => ({
  type: UPDATE_PAIRED_TUTORS,
  payload: { pairedTutors: tutors },
});

export const updatePairedTutees = tutees => ({
  type: UPDATE_PAIRED_TUTEES,
  payload: { pairedTutees: tutees },
});

export const addNotification = notification => ({
  type: ADD_NOTIFICATION,
  payload: { notification },
});

export const removeNotification = notificationId => ({
  type: REMOVE_NOTIFICATION,
  payload: { notificationId },
});

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
});
