// TUTOR SEARCH REDUX ACTION TYPES

export const SET_CORE_SEARCH_PARAMS = 'SET_CORE_SEARCH_PARAMS';
export const SET_CORE_SUBJECT = 'SET_CORE_SUBJECT';
export const SET_ZIP_CODE = 'SET_ZIP_CODE';
export const FETCH_TUTORS = 'FETCH_TUTORS';
export const RECEIVED_TUTORS = 'RECEIVED_TUTORS';
export const ERROR_LOCAL_TUTORS = 'ERROR_LOCAL_TUTORS';
export const SET_SEARCH_CONTEXT = 'SET_SEARCH_CONTEXT';
export const SET_LOCAL_TUTORS_RESULTS_HEADER =
  'SET_LOCAL_TUTORS_RESULTS_HEADER';
export const SET_ONLINE_TUTORS_RESULTS_HEADER =
  'SET_ONLINE_TUTORS_RESULTS_HEADER';

export const SET_URL_PATH = 'SET_URL_PATH';

export const SET_CHOSEN_TUTOR_LEVEL = 'SET_CHOSEN_TUTOR_LEVEL';
export const SET_CHOSEN_CLIENT_LEVEL = 'SET_CHOSEN_CLIENT_LEVEL';
export const SET_CHOSEN_COURSE = 'SET_CHOSEN_COURSE';
export const SET_CHOSEN_SCHOOLS = 'SET_CHOSEN_SCHOOLS';
export const SET_CHOSEN_TEACHER = 'SET_CHOSEN_TEACHER';

export const UPDATE_FILTERED_TUTOR_LIST = 'UPDATE_FILTERED_TUTOR_LIST';
export const SET_AVAILABLE_TUTOR_LEVELS = 'SET_AVAILABLE_TUTOR_LEVELS';
export const SET_AVAILABLE_CLIENT_LEVELS = 'SET_AVAILABLE_CLIENT_LEVELS';
export const SET_AVAILABLE_SCHOOLS = 'SET_AVAILABLE_SCHOOLS';
export const SET_AVAILABLE_COURSES = 'SET_AVAILABLE_COURSES';
export const SET_AVAILABLE_TEACHERS = 'SET_AVAILABLE_TEACHERS';
export const SET_AVAILABILITY = 'SET_AVAILABILITY';
export const BULK_SET_FILTER_OPTIONS = 'BULK_SET_FILTER_OPTIONS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_FILTERED_TUTORS = 'RESET_FILTERED_TUTORS';

export const SET_CONVERSATION_OBJECT = 'SET_CONVERSATION_OBJECT';
export const SET_TUTORING_REQUEST = 'SET_TUTORING_REQUEST';
export const FETCH_CONVERSATION_ERROR = 'FETCH_CONVERSATION_ERROR';
export const FETCH_MESSAGES_ERROR = 'FETCH_MESSAGES_ERROR';
export const SET_FETCHING_CONVERSATION = 'SET_FETCHING_CONVERSATION';
export const SET_FETCHING_MESSAGES = 'SET_FETCHING_MESSAGES';
export const SET_PAIR_FOR_CONVERSATION = 'SET_PAIR_FOR_CONVERSATION';
export const RESET_CONVERSATION_OBJECT = 'RESET_CONVERSATION_OBJECT';
export const GET_MESSAGES_FIELDS = 'GET_MESSAGES_FIELDS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SEND_NEW_MESSAGE = 'SEND_NEW_MESSAGE';
export const LOAD_MORE_MESSAGES = 'LOAD_MORE_MESSAGES';
export const SET_TAB_INDEX = 'SET_TAB_INDEX';

export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_USER_CONNECTION_INFO = 'UPDATE_USER_CONNECTION_INFO';
export const UPDATE_USER_CONNECTION_ERROR = 'UPDATE_USER_CONNECTION_ERROR';
export const UPDATE_USER_CONNECTION_INFO_LOADING =
  'UPDATE_USER_CONNECTION_INFO_LOADING';
export const UPDATE_PARTIAL_USER = 'UPDATE_PARTIAL_USER';
export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD';
export const UPDATE_PAIRED_TUTORS = 'UPDATE_PAIRED_TUTORS';
export const UPDATE_PAIRED_TUTEES = 'UPDATE_PAIRED_TUTEES';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const SET_BADGE_NOTIFICATION_AMOUNT = 'SET_BADGE_NOTIFICATION_AMOUNT';

export const ADD_SESSION = 'ADD_SESSION';
export const SET_SESSIONS = 'SET_SESSIONS';
export const REMOVE_SESSION = 'REMOVE_SESSION';
export const CLEAR_SESSIONS = 'CLEAR_SESSIONS';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const SWITCH_TO_TUTOR = 'SWITCH_TO_TUTOR';
export const SWITCH_TO_CLIENT = 'SWITCH_TO_CLIENT';
export const ADD_TUTORING_REQUESTS = 'ADD_TUTORING_REQUESTS';
export const REMOVE_TUTORING_REQUEST = 'REMOVE_TUTORING_REQUEST';
export const EDIT_CONTACT_REQUEST_MESSAGE = 'EDIT_CONTACT_REQUEST_MESSAGE';

export const FETCH_TUTOR_PROFILE = 'FETCH_TUTOR_PROFILE';
export const RECEIVED_TUTOR_PROFILE = 'RECEIVED_TUTOR_PROFILE';
export const TUTOR_NOT_FOUND = 'TUTOR_NOT_FOUND';
export const ERROR_FETCH_TUTOR_PROFILE = 'ERROR_FETCH_TUTOR_PROFILE';
export const RESET_PROFILE_PAGE = 'RESET_PROFILE_PAGE';
export const SET_REVIEWS = 'SET_REVIEWS';
export const SET_PAIR_AND_HAS_REVIEW = 'SET_PAIR_AND_HAS_REVIEW';
export const LEAVE_REVIEW = 'LEAVE_REVIEW';
export const EDIT_REVIEW = 'EDIT_REVIEW';

export const PRIMARY_INFO_PAGE = 'PRIMARY_INFO_PAGE';
export const CORE_SUBJECTS_PAGE = 'CORE_SUBJECTS_PAGE';
export const EDUCATION_LEVEL_PAGE = 'EDUCATION_LEVEL_PAGE';
export const ZIPCODE_PAGE = 'ZIPCODE_PAGE';
export const SCHOOL_INFO = 'SCHOOL_INFO';
export const HIGH_SCHOOL_COURSES = 'HIGH_SCHOOL_COURSES';
export const COLLEGE_COURSES = 'COLLEGE_COURSES';
export const TUTOR_PREFS = 'TUTOR_PREFS';
export const AVAILABILITY = 'AVAILABILITY';
export const ABOUT_YOURSELF = 'ABOUT_YOURSELF';
export const COMPLETE = 'COMPLETE';

export const UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO';
export const UPDATE_ACADEMIC_INFO = 'UPDATE_ACADEMIC_INFO';
export const CLEAR_PROSPECTIVE_TUTOR = 'CLEAR_PROSPECTIVE_TUTOR';

export const UPDATE_CLIENT_SIGN_UP_STATE = 'UPDATE_CLIENT_SIGN_UP_STATE';
export const SET_SIGN_UP_INFO = 'SET_SIGN_UP_INFO';

export const SET_VIEW_CONTEXT = 'SET_VIEW_CONTEXT';

export const RESET_SEARCH = 'RESET_SEARCH';
export const UPDATE_SEARCH_PAGE_STATE = 'UPDATE_SEARCH_PAGE_STATE';
export const SET_COURSE = 'SET_COURSE';
export const SET_TUTORS = 'SET_TUTORS';
export const SET_LOCATION_FILTER = 'SET_LOCATION_FILTER';
export const SET_LOCATION_DESCRIPTION = 'SET_LOCATION_DESCRIPTION';
export const SET_STUDENT_GRADE_FILTER = 'SET_STUDENT_GRADE_FILTER';
export const SET_IS_LOCAL_SEARCH = 'SET_IS_LOCAL_SEARCH';
export const SEARCH_LOADING = 'SEARCH_LOADING';
export const SEARCH_NEXT_PAGE_LOADING = 'SEARCH_NEXT_PAGE_LOADING';
export const SET_UPCOMING_SESSIONS = 'SET_UPCOMING_SESSIONS';
export const SET_UPCOMING_DROP_IN_SESSIONS = 'SET_UPCOMING_DROP_IN_SESSIONS';
export const SET_SESSIONS_LOADING = 'SET_SESSIONS_LOADING';
export const SET_DROP_IN_SESSIONS_LOADING = 'SET_DROP_IN_SESSIONS_LOADING';
export const SET_SESSIONS_FETCH_ERROR = 'SET_SESSIONS_FETCH_ERROR';
export const SET_DROP_IN_SESSIONS_FETCH_ERROR = 'SET_SESSIONS_FETCH_ERROR';
export const ADD_MORE_UPCOMING_SESSIONS = 'ADD_MORE_UPCOMING_SESSIONS';
export const SET_FETCH_MORE_LOADING = 'SET_FETCH_MORE_LOADING';
export const SET_HAS_MORE = 'SET_HAS_MORE';

export const SET_TUTOR_BY_COURSE_ERROR = 'SET_TUTOR_BY_COURSE_ERROR';
export const SET_SESSIONS_PAGE_NUM = 'SET_SESSIONS_PAGE_NUM';
