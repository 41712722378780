import {
  SET_CORE_SEARCH_PARAMS,
  FETCH_TUTORS,
  RECEIVED_TUTORS,
  ERROR_LOCAL_TUTORS,
  RESET_SEARCH,
  SET_SEARCH_CONTEXT,
  SET_LOCAL_TUTORS_RESULTS_HEADER,
  SET_ONLINE_TUTORS_RESULTS_HEADER,
  UPDATE_SEARCH_PAGE_STATE,
  SET_CHOSEN_TUTOR_LEVEL,
  SET_CHOSEN_CLIENT_LEVEL,
  SET_CHOSEN_COURSE,
  SET_CHOSEN_SCHOOLS,
  SET_CHOSEN_TEACHER,
  SET_AVAILABLE_CLIENT_LEVELS,
  SET_AVAILABLE_SCHOOLS,
  SET_AVAILABLE_COURSES,
  SET_AVAILABLE_TEACHERS,
  SET_AVAILABILITY,
  BULK_SET_FILTER_OPTIONS,
  RESET_FILTERS,
  SET_CORE_SUBJECT,
  SET_ZIP_CODE,
  SET_AVAILABLE_TUTOR_LEVELS,
} from '../types';

const INITIAL_STATE = {
  coreSubject: 'Math',
  zipCode: '',
  isLocalSearchContext: true,

  localTutors: [],
  onlineTutors: [],

  localTutorsPage: 1,
  onlineTutorsPage: 1,

  searchMore: false,
  initialSearch: false,
  isFetching: false,
  fetchError: false,
  exhaustedLocalTutors: false,
  exhaustedOnlineTutors: false,

  searchStateSwitched: false,

  numLocalTutors: -1,
  numLocalSchools: -1,
  numOnlineTutors: -1,
  numOnlineSchools: -1,
  monSelected: false,
  tueSelected: false,
  wedSelected: false,
  thuSelected: false,
  friSelected: false,
  satSelected: false,
  sunSelected: false,

  // Filter value options populated from the resulting tutors
  availableTutorLevels: ['No Selection'],
  availableClientLevels: ['No Selection'],
  availableCourses: ['No Selection'],
  availableSchools: ['No Selection'],
  availableTeachers: ['No Selection'],
  // The options chosen by the user from the available options
  chosenTutorLevel: '',
  chosenClientLevel: '',
  chosenCourse: '',
  chosenSchools: [],
  chosenTeacher: '',
  tutorLevelIndex: 0,
  clientLevelIndex: 0,
  schoolIndex: 0,
  courseIndex: 0,
  instructorIndex: 0,
  // filtered list as a result of filters above
  filteredLocalTutorList: [],
  filteredOnlineTutorList: [],
  // flag on whether location should be taken into account during updateFilter
  isFiltered: false,
  madeSearchContextSwitch: false,
};

/* eslint-disable no-case-declarations */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TUTORS:
      return {
        ...state,
        isFetching: true,
        searchMore: action.payload.searchMore,
        searchStateSwitched: false,
        fetchError: false,
      };

    case RECEIVED_TUTORS:
      return {
        ...state,
        isFetching: false,
        localTutors: action.payload.localTutors,
        onlineTutors: action.payload.onlineTutors,
        exhaustedLocalTutors: action.payload.exhaustedLocalTutors,
        exhaustedOnlineTutors: action.payload.exhaustedOnlineTutors,
      };

    case UPDATE_SEARCH_PAGE_STATE:
      return {
        ...state,
        localTutorsPage: action.payload.localTutorsPage,
        onlineTutorsPage: action.payload.onlineTutorsPage,
      };

    case ERROR_LOCAL_TUTORS:
      return {
        ...state,
        isFetching: false,
        fetchError: true,
      };

    case SET_CORE_SUBJECT:
      return {
        ...state,
        coreSubject: action.payload.coreSubject,
      };

    case SET_ZIP_CODE:
      return {
        ...state,
        zipCode: action.payload.zipCode,
      };

    case SET_CORE_SEARCH_PARAMS:
      return {
        ...state,
        coreSubject: action.payload.coreSubject,
        zipCode: action.payload.zipCode,
      };

    case SET_SEARCH_CONTEXT:
      return {
        ...state,
        isLocalSearchContext: action.payload.isLocalSearchContext,
        localTutorsPage: action.payload.isLocalSearchContext ? 1 : -1,
        onlineTutorsPage: 1,
      };

    case RESET_SEARCH:
      return {
        ...state,
        localTutors: [],
        onlineTutors: [],
        searchStateSwitched: false,
        localTutorsPage: state.isLocalSearchContext ? 1 : -1,
        onlineTutorsPage: 1,
        searchMore: false,
        initialSearch: false,
        isFetching: false,
        fetchError: false,
        exhaustedLocalTutors: false,
        exhaustedOnlineTutors: false,
        numLocalTutors: INITIAL_STATE.numLocalTutors,
        numLocalSchools: INITIAL_STATE.numLocalSchools,
        numOnlineTutors: INITIAL_STATE.numOnlineTutors,
        numOnlineSchools: INITIAL_STATE.numOnlineSchools,
      };

    case SET_LOCAL_TUTORS_RESULTS_HEADER:
      return {
        ...state,
        numLocalTutors: action.payload.numLocalTutors,
        numLocalSchools: action.payload.numLocalSchools,
      };

    case SET_ONLINE_TUTORS_RESULTS_HEADER:
      return {
        ...state,
        numOnlineTutors: action.payload.numOnlineTutors,
        numOnlineSchools: action.payload.numOnlineSchools,
      };

    // User updates to what individual filter options are chosen
    case SET_CHOSEN_TUTOR_LEVEL:
      return {
        ...state,
        tutorLevelIndex: action.payload.tutorLevelIndex,
        chosenTutorLevel: action.payload.chosenTutorLevel,
      };
    case SET_CHOSEN_CLIENT_LEVEL:
      return {
        ...state,
        clientLevelIndex: action.payload.clientLevelIndex,
        chosenClientLevel: action.payload.chosenClientLevel,
      };
    case SET_CHOSEN_COURSE:
      return {
        ...state,
        courseIndex: action.payload.courseIndex,
        chosenCourse: action.payload.chosenCourse,
      };
    case SET_AVAILABILITY:
      const { value } = action.payload;
      const field = action.payload;
      return {
        ...state,
        [field]: value,
      };

    case SET_CHOSEN_SCHOOLS:
      return {
        ...state,
        schoolIndex: action.payload.schoolIndex,
        chosenSchools: [...action.payload.chosenSchools],
      };
    case SET_CHOSEN_TEACHER:
      return {
        ...state,
        instructorIndex: action.payload.instructorIndex,
        chosenTeacher: action.payload.chosenTeacher,
      };
    case SET_AVAILABLE_TUTOR_LEVELS:
      return {
        ...state,
        availableTutorLevels: [...action.payload.availableTutorLevels],
      };
    case SET_AVAILABLE_CLIENT_LEVELS:
      return {
        ...state,
        availableClientLevels: [...action.payload.availableClientLevels],
      };
    case SET_AVAILABLE_SCHOOLS:
      return {
        ...state,
        availableSchools: [...action.payload.availableSchools],
      };
    case SET_AVAILABLE_COURSES:
      return {
        ...state,
        availableCourses: [...action.payload.availableCourses],
      };
    case SET_AVAILABLE_TEACHERS:
      return {
        ...state,
        availableTeachers: [...action.payload.availableTeachers],
      };
    case BULK_SET_FILTER_OPTIONS:
      return {
        ...state,
        chosenClientLevel: action.payload.chosenClientLevel,
        chosenTutorLevel: action.payload.chosenTutorLevel,
        chosenCourse: action.payload.chosenCourse,
        chosenSchools: [...action.payload.chosenSchools],
        chosenTeacher: action.payload.chosenTeacher,
      };
    case RESET_FILTERS:
      return {
        ...state,
        availableTutorLevels: ['No Selection'],
        availableClientLevels: ['No Selection'],
        availableCourses: ['No Selection'],
        availableSchools: ['No Selection'],
        availableTeachers: ['No Selection'],
        chosenClientLevel: '',
        chosenTutorLevel: '',
        chosenCourse: '',
        chosenSchools: [],
        chosenTeacher: '',
        tutorLevelIndex: 0,
        clientLevelIndex: 0,
        schoolIndex: 0,
        courseIndex: 0,
        instructorIndex: 0,
        monSelected: false,
        tueSelected: false,
        wedSelected: false,
        thuSelected: false,
        friSelected: false,
        satSelected: false,
        sunSelected: false,
      };
    default:
      return state;
  }
};
