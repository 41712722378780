/* GLOBAL VARIABLES */

// Non Color Values

// export const dashboardRightHalfHeight = 750px

// Application Colors
export const tutorflyBlue = '#56B0E4';
export const tutorflyRed = '#f44336';
export const tutorflyDarkBlue = '#00558B';
export const tutorflyOrange = '#fb8c00';

/*
Light Blue
Primary color used throughout the website
*/
export const primaryColor = 'rgb(96,190,247)';
export const primaryColorLight = `rgba(${primaryColor}, .7)`;
export const primaryColorDark = '#00346c';

// used on FAQ, about us pages, etc.
export const headerBlue = '#549BC6';

export const footerBlue = '#2B495C';

/*
  White
  All white used throughtout the website
  Split into $, text, and border
*/
export const backgroundWhite = 'rgb(255,255,255)';
export const backgroundOffWhite = 'rgb(245, 245, 245)';
export const backgroundLightGray = 'rgb(220,220,230)';
export const textWhite = 'rgb(255,255,255)';
export const borderWhite = 'rgb(255,255,255)';

/*
  Black
  All black used throughout the website
  Split into background and text
*/
export const backgroundBlack = 'rgb(0,0,0)';
export const textBlack = 'rgb(0,0,0)';
// export const textGray = 'rgb(48,48,48)'

/*
  Yellow
  Used on the tutorcard glyphs
*/
export const glyphYellow = 'rgb(255,255,0)';

/*
  Green
  Used on the profile glyphs
*/
export const glyphGreen = 'rgb(0,255,0)';

/*
  Red
  Used on the profile glyphs
*/
export const glyphRed = 'rgb(255,0,0)';

/*
  Dark Gray
  Used for the landing page descriptions
*/
export const textLightgray = 'rgb(224,224,224)';

/*
  Grayish Blue
  Used for the links and groups on the home page
*/
export const textGrayBlue = 'rgb(119,136,153)';
export const backgroundGrayBlue = 'rgb(84, 155, 198)';
export const transparentBackgroundGrayBlue = `rgba(${backgroundGrayBlue}, .3)`;
export const buttonBlue = '#56B0E4';
export const buttonBlueFocus = 'rgb(60,140,190)';
export const buttonOrange = '#fb8c00';
export const buttonOrangeFocus = '#f57c00';
export const buttonRed = '#f44336';
export const buttonRedHover = '#fdefee';
export const buttonRedFocus = '#e53935';

/*
  Dark blue
  Used for Footer
*/

export const backgroundDarkBlue = 'rgb(43,73,92)';

/*
  Light Blue
  Used on the credit card form page
*/
export const creditcardBlue = 'rgb(128, 180, 209)';

export const linkBlue = 'rgb(85,149,180)';

export const mainAccentColor = 'rgb(120,120,120)';
export const mainAccentColorLight = 'rgb(200,200,200)';

export const accentColor = 'rgb(255,168,30)';

export const accentBackgroundBlue = 'rgb(205, 226, 236)';
export const accentTextDarkBlue = 'rgb(84,155,198)';

export const landingBlue = 'rgb(144, 202, 249)';
export const landingBlueTransparent = `rgba(${landingBlue}, .85)`;

export const borderGray = 'rgb(169,169,169)';
export const panelGray = 'rgb(211,211,211)';
export const textGray = 'rgb(60,60,60)';

export const dangerRed = 'rgb(217,83,79)';

export const lightBlue = 'rgb(205,225,237)';

export const transparentLandingPageOverlay = 'rgba(85,178,237, .7)';
export const accentBackgroundGray = 'rgba(30,30,30, .95)';
export const accentBackgroundWhite = 'rgb(255,255,255)';

/*
  Font settings
*/
// export const profilePictureDimension = 100px
export const font = 'Archivo, Avenir, Gadugi, Calibri, sans-serif';
export const regularTextSize = '18px';

// The width at which the header switches to its mobile optimized version
// export const mobile-header-width = 830px
// Background color of mobile dropdown
export const mobileHeaderBackground = 'rgba(0,0,0,0.8)';
export const mobileWidth = '768px';
export const mobileMediaQuery = `@media (max-width: ${mobileWidth})`;
// Width used to contain most elements in the profile page
// This is the width of the availability calender, location map, and other components
// export const profile-info-width = 540px

// stuff imported from another file
export const infoTextBlack = 'rgb(50,55,56)';
export const transparentBlack = 'rgba(0 ,0 ,0, 0.5)';
export const backgroundOrange = 'rgb(238,148,89)';
export const triangleBorderColor = 'rgb(34,122,122)';
export const starYellow = 'rgb(255,215,0)';

// Company colors
export const facebookBlue = '#4267B2';
export const facebookBlueHover = '#32508c';
export const googleOrange = '#DD4B39';
export const googleOrangeHover = '#C23321';
export const nextdoorGreen = '#01B246';
export const nextdoorGreenHover = '#009e3d';
export const linkedInBlue = '#0966C2';
export const linkedInBlueHover = '#0854a1';

// Profile picture background colors. These are the official
// material ui secondary colors
export const initialsBackgroundColors = [
  '#D50000', // red
  '#880E4F', // pink
  '#673AB7', // purple
  '#3F51B5', // indigo
  '#2196F3', // blue
  '#0097A7', // cyan
  '#009688', // teal
  '#33691E', // green
  '#E65100', // orange
  '#5D4037', // brown
  '#78909C', // blueGrey
  '#827717', // lime
  '#C2185B', // pink
];

export const hiddenProfileGrey = '#9e9e9e';
export const warningYellow = '#fff176';

// used for file chips to size and space them better
export const reusableChipStyles = {
  padding: '10px',
  margin: '5px',
  marginTop: '15px',
  fontSize: '15px',
};
